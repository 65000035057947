import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router';
import { createGetWorkshopReport } from '../../api/api';
import { MAX_SCORE_CHAPTERS } from '../../constants';
import AdminPage from '../common/AdminPage'
import LearningOutcomeChapterRow from '../common/LearningOutcomeChapterRow';
import { Loading } from '../common/Loading'
import PageBox from '../common/PageBox';
import RangeBar from '../common/RangeBar';

const WorkshopReport = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [scoreData, setScoreData] = useState(null);
    const [firstSession, setFirstSession] = useState(true);
    const [hasMultipleSessions, setHasMultipleSessions] = useState(false);
    const { id } = useParams();
    const dataReq = useRef();

    useEffect(() => {
        let isMounted = true;
        async function loadData() {
            setIsLoading(true);
            dataReq?.current?.cancel();
            const { request, f } = createGetWorkshopReport();
            dataReq.current = request;
            if (!isMounted) {
                return;
            }
            const r = await f(id);
            setIsLoading(false);
            if (r instanceof Error) {
                setError(r);
            } else if (r && r.status) {
                setData(r.data);
            }
        }
        loadData();
        return () => {
            isMounted = false;
            dataReq?.current?.cancel();
        }
    }, [id])

    useEffect(() => {
        const getScores = (attempts, chapterId) => {
            let cha = attempts.filter(a => parseInt(chapterId) === parseInt(a.chapter_id));
            const studentIds = [...new Set(cha.map(c => c.student_id))];
            let scores = [];
            studentIds.forEach(sid => {
                let sorted;
                if (firstSession) {
                    sorted = cha.filter(c => parseInt(c.student_id) === parseInt(sid)).sort((a, b) => a.updated_at - b.updated_at);
                } else {
                    sorted = cha.filter(c => parseInt(c.student_id) === parseInt(sid)).sort((a, b) => b.updated_at - a.updated_at);
                }
                scores.push(sorted[0].healthy > 0 ? sorted[0].healthy : 0);
                if (sorted.length > 1) {
                    setHasMultipleSessions(h => h || true);
                }
            });
            return scores;
        }

        const scoresToRanges = (scores, ranges, maxScore) => {
            let totalStudents = parseInt(data.playing_students);
            let counts = ranges.map(r => {
                const min = maxScore * r[0] / 100;
                const max = maxScore * r[1] / 100;
                const count = scores.filter(s => s >= min && s < max).length;
                return { range: r, total: totalStudents, count };
            });
            return counts.filter(c => c.count !== 0);
        }

        const getLearningOutcomes = (attempts, chapterId) => {
            let cha = attempts.filter(a => parseInt(chapterId) === parseInt(a.chapter_id));
            const studentIds = [...new Set(cha.map(c => c.student_id))];
            let lo = [];
            studentIds.forEach(sid => {
                let sorted;
                if (firstSession) {
                    sorted = cha.filter(c => parseInt(c.student_id) === parseInt(sid)).sort((a, b) => a.updated_at - b.updated_at);
                } else {
                    sorted = cha.filter(c => parseInt(c.student_id) === parseInt(sid)).sort((a, b) => b.updated_at - a.updated_at);
                }
                lo.push(sorted[0].learning_outcomes);
            });
            lo = lo.filter(l => l.length);
            lo = [].concat.apply([], lo);
            let clo = data.tournament_data.find(t => parseInt(t.chapter_id) === parseInt(chapterId))?.learning_outcomes;
            clo = clo?.map(c => ({ max: data.playing_students, count: lo.filter(l => l === c).length, title: data.learning_outcomes_all[c] }));
            return clo;
        }

        if (data) {
            let d = data.workshop_chapter_ids.map(k => {
                let scores = getScores(data.attempts, k);
                let lo = getLearningOutcomes(data.attempts, k);
                let ranges = scoresToRanges(scores, [[0, 50], [50, 70], [70, Infinity]], MAX_SCORE_CHAPTERS.find(c => parseInt(c.chapterId) === parseInt(k)).score);
                return { chapterId: k, title: data.cms_chapters[k].title, display_order: data.cms_chapters[k].display_order, scores, ranges, learningOutcomes: lo };
            });
            setScoreData(d.sort((a, b) => a.display_order - b.display_order));
        }
    }, [data, firstSession]);



    return (
        <AdminPage error={error} breadcrumbs={[
            { title: "Home", path: "/" },
            { title: "Workshops", path: "/workshops" },
            { title: (data?.workshop?.name || ""), path: data?.workshop ? `/workshop/${data.workshop.id}` : null },
            { title: (data?.workshop?.name || "") + " Report" }
        ]}>
            <PageBox className="p-3">
                {isLoading && <div className="d-flex align-items-center justify-content-center p-4"><Loading></Loading></div>}
                {!isLoading && (data !== null) && <>
                    <h4><strong>{data.school.name} - {data.workshop.name}</strong> - Report</h4>
                    <div><small><strong><span className="text-muted">Group Size: </span><span>{data.total_students} Students</span></strong></small></div>
                    <div><small><strong><span className="text-muted">Playing Students: </span><span>{data.playing_students} Students</span></strong></small></div>
                    <div><small><strong><span className="text-muted">Students Logged Today: </span><span>{data.students_logged_today} Students</span></strong></small></div>
                    <div className="row mt-5">
                        <div className="col-12 col-md-6 d-flex align-items-center">
                            <h5>{data.workshop.name}</h5>
                        </div>
                        {hasMultipleSessions && <div className="col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-end">
                            <button disabled={firstSession} onClick={() => setFirstSession(true)} className={`btn mr-1 ${firstSession ? "btn-secondary" : "btn-primary"}`}>1st Session</button>
                            <button disabled={!firstSession} onClick={() => setFirstSession(false)} className={`btn ${!firstSession ? "btn-secondary" : "btn-primary"}`}>Latest Session</button>
                        </div>}
                    </div>
                    {scoreData && scoreData.map((r, i) =>
                        <div key={i} className={`py-3 row border-bottom border-row-light small flex-column flex-lg-row ${i % 0 ? "background-row-light" : "background-row-lighter"}`}>
                            <div className="col-12 col-lg-2"><strong>{r.title}</strong></div>
                            <div className="col-12 col-lg-10">
                                <RangeBar ranges={r.ranges}></RangeBar>
                            </div>
                        </div>)
                    }

                    <h6 className="my-4">Group Learning Outcomes</h6>
                    <div className="row d-none d-lg-flex">
                        <div className="col-6 text-center"><small className="text-muted">Learning Outcomes</small></div>
                        <div className="col-6 text-center"><small className="text-muted">Student Attainment</small></div>
                    </div>
                    {scoreData && scoreData.map((r, i) =>
                        <LearningOutcomeChapterRow
                            key={i}
                            chapterTitle={r.title}
                            learningOutcomes={r.learningOutcomes}
                        ></LearningOutcomeChapterRow>
                    )}
                </>}
            </PageBox>
        </AdminPage>
    )
}

export default WorkshopReport


