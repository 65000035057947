import React, { useContext, useEffect, useRef, useState } from 'react'
import { FaDownload, FaEnvelope, FaKey, FaTimes } from 'react-icons/fa'
import { useHistory, useParams } from 'react-router'
import { createDeleteUserRequest, createGetSchools, createGetUserRequest, createUpdateUserRequest, createGetUserLinkedWorkshopsRequest, createArchiveUserRequest, createInviteAgainUserRequest } from '../../api/api'
import AdminPage from '../common/AdminPage'
import Badge from '../common/Badge'
import Icon from '../common/Icon'
import { Loading } from '../common/Loading'
import ModalDialog from '../common/ModalDialog'
import PageBox from '../common/PageBox'
import { UserContext } from '../providers/UserProvider'
import { Row } from "../common/Row";
import Pagination from "../common/Pagination";
import { Link } from 'react-router-dom'
import { USER_ROLE_ADMIN, USER_ROLE_SCHOOL_ADMIN, USER_ROLE_TEACHER, USER_ROLE_TENDER_ADMIN, USER_ROLE_TENDER_FACILITATOR } from '../../constants'
import { MdArchive, MdDeleteForever, MdUnarchive } from 'react-icons/md'

const UserProfile = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingSchools, setIsLoadingSchools] = useState(false);
    const [isLoadingWorkshops, setIsLoadingWorkshops] = useState(false);
    const [error, setError] = useState(null);
    const [user, setUser] = useState(null);
    const [workshops, setWorkshops] = useState(null);
    const [page, setPage] = useState(0);
    const [countPerPage, setCountPerPage] = useState();
    const [totalWorkshopCount, setTotalWorkshopCount] = useState(0);
    const [schoolSearchString, setSchoolSearchString] = useState("");
    const [message, setMessage] = useState({ class: "", message: "" });
    const [userSchools, setUserSchools] = useState(null);
    const [schools, setSchools] = useState(null);
    const [askDelete, setAskDelete] = useState(false);
    const [askArchive, setAskArchive] = useState(false);
    const [askRestore, setAskRestore] = useState(false);
    const [askPassword, setAskPassword] = useState(false);
    const [invited, setInvited] = useState(false);
    const { role, id: loggedUserId, permissions } = useContext(UserContext);
    const userReq = useRef();
    const schoolsReq = useRef();
    const workshopReq = useRef();
    const { id } = useParams();
    const history = useHistory();

    const clearMessage = () => {
        setMessage({ class: "", message: "" });
    }

    const updateUserData = (key, value) => {
        const nu = { ...user, [key]: value };
        setUser(nu);
    }
    const roleName = (role) => {
        const o = {
            [USER_ROLE_ADMIN]: "Administrator",
            [USER_ROLE_TENDER_ADMIN]: "Tender Admin",
            [USER_ROLE_TENDER_FACILITATOR]: "Facilitator",
            [USER_ROLE_TEACHER]: "Teacher",
            [USER_ROLE_SCHOOL_ADMIN]: "School Admin"
        }
        return o[role];
    }
    const getUpdateableRolesFromPermissions = () => {
        const o = {
            "pm_users_update_admin": USER_ROLE_ADMIN,
            "pm_users_update_tender_admin": USER_ROLE_TENDER_ADMIN,
            "pm_users_update_school_admin": USER_ROLE_SCHOOL_ADMIN,
            "pm_users_update_facilitator": USER_ROLE_TENDER_FACILITATOR,
            "pm_users_update_teacher": USER_ROLE_TEACHER
        };
        let p = [];
        for (const key in o) {
            const v = o[key];
            if (permissions[key]) {
                p.push(v);
            }
        };
        return p;
    }

    const isThisMe = () => {
        return parseInt(loggedUserId) === parseInt(user.id);
    }

    const canUpdate = () => {
        return role === USER_ROLE_ADMIN || role === USER_ROLE_TENDER_ADMIN || isThisMe();
    }

    const canUpdateSchool = () => {
        return role === USER_ROLE_ADMIN || role === USER_ROLE_TENDER_ADMIN;
    }

    const canArchive = () => {
        return role === USER_ROLE_ADMIN || role === USER_ROLE_TENDER_ADMIN;
    }

    const canDelete = () => {
        return role === USER_ROLE_ADMIN || role === USER_ROLE_TENDER_ADMIN || role === USER_ROLE_SCHOOL_ADMIN;
    }

    const canShowUsersOnBreadcrumb = () => {
        return role === USER_ROLE_ADMIN || role === USER_ROLE_TENDER_ADMIN || role === USER_ROLE_SCHOOL_ADMIN;
    }

    const canShowEmail = () => {
        if (user.role === USER_ROLE_TENDER_FACILITATOR) {
            return !(role === USER_ROLE_SCHOOL_ADMIN || role === USER_ROLE_TEACHER);
        } else {
            return true;
        }
    }

    const isAllSchoolsArchived = () => {
        return userSchools.every(s => s.archived);
    }

    const isSchoolSelected = (id) => {
        return userSchools.some(s => s.id === id);
    }
    const toggleSchool = (school) => {
        let s = [...userSchools];
        if (isSchoolSelected(school.id)) {
            s = s.filter(e => e.id !== school.id);
        } else {
            s = [school];
        }
        setUserSchools(s);
        setSchoolSearchString("");
    }

    useEffect(() => {
        let isMounted = true;
        async function getUser() {
            setIsLoading(true);
            userReq?.current?.cancel();

            const { request, f } = createGetUserRequest();
            userReq.current = request;
            const r = await f(id);
            if (!isMounted) {
                return;
            }
            if (r instanceof Error) {
                setError(r);
            } else if (r && r.status) {
                setUser(r.data.user);
                setUserSchools(r.data.schools);
            }
            setIsLoading(false);
        }
        getUser();
        return () => {
            isMounted = false;
            userReq?.current?.cancel();
        }
    }, [id, role]);

    useEffect(() => {
        let isMounted = true;
        const showOnlyCommonSchoolsAndWorkshops = () => {
            return role === USER_ROLE_SCHOOL_ADMIN || role === USER_ROLE_TENDER_FACILITATOR || role === USER_ROLE_TEACHER;
        }

        async function getWorkshops() {
            setIsLoadingWorkshops(true);
            workshopReq?.current?.cancel();

            const { request, f } = createGetUserLinkedWorkshopsRequest();
            workshopReq.current = request;
            const r = await f(id, null, null, null, showOnlyCommonSchoolsAndWorkshops());
            if (!isMounted) {
                return;
            }
            if (r instanceof Error) {
                setError(r);
            } else if (r && r.status) {
                let sw = [];
                r.data.workshops.forEach(w => {
                    let i = sw.findIndex(s => parseInt(s.school_id) === parseInt(w.school_id));
                    if (i === -1) {
                        sw.push({
                            school_name: w.school_name,
                            school_id: w.school_id,
                            workshops: []
                        });
                        i = sw.length - 1;
                    }
                    sw[i].workshops.push({
                        name: w.name, id: w.id
                    });
                });
                setWorkshops(sw);
                setTotalWorkshopCount(r.data.total);
                setCountPerPage(r.data.count_per_page);
            }
            setIsLoadingWorkshops(false);
        }
        getWorkshops();
        return () => {
            isMounted = false;
            workshopReq?.current?.cancel();
        }
    }, [id, page, role])

    useEffect(() => {
        let isMounted = true;
        if (schoolSearchString.trim().length === 0) {
            setSchools([]);
            return;
        }
        async function getSchools() {
            setIsLoadingSchools(true);
            if (schoolsReq.current) {
                schoolsReq.current.cancel();
                schoolsReq.current = null;
            }
            const { request, f } = createGetSchools(role);
            schoolsReq.current = request;

            const r = await f(schoolSearchString, 0, 0);
            if (!isMounted) {
                return;
            }
            if (r instanceof Error) {
                setError(r);
            } else if (r && r.status) {
                setSchools(r.data.schools.slice(0, 5));
            }
            setIsLoadingSchools(false);
        }
        getSchools();
        return () => {
            isMounted = false;
            schoolsReq?.current?.cancel();
        }
    }, [schoolSearchString, role]);

    const save = async () => {
        clearMessage();
        userReq?.current?.cancel();

        if (user.fname.trim().length === 0) {
            setMessage({ class: "alert-danger", message: "First name is required" });
            return;
        } else if (user.lname.trim().length === 0) {
            setMessage({ class: "alert-danger", message: "Last name is required" });
            return;
        } else if (user.email.trim().length === 0 || user.email.indexOf("@") === -1 || user.email.indexOf(".") === -1) {
            setMessage({ class: "alert-danger", message: "Email is invalid" });
            return;
        } else if ([USER_ROLE_SCHOOL_ADMIN, USER_ROLE_TEACHER].indexOf(user.role) >= 0 && userSchools.length === 0) {
            setMessage({ class: "alert-danger", message: `School is required for ${roleName(user.role)}` });
            return;
        }

        setIsLoading(true);
        const { request, f } = createUpdateUserRequest();
        userReq.current = request;

        let schoolIds = userSchools.map(u => u.id) || [];

        if ([USER_ROLE_SCHOOL_ADMIN, USER_ROLE_TEACHER].indexOf(user.role) === -1) {
            schoolIds = [];
        }
        const r = await f(user.id, user.fname, user.lname, user.role, user.email, schoolIds);
        setIsLoading(false);
        if (r instanceof Error) {
            setMessage({ class: "alert-danger", message: "User not updated. Some error occured" });
        } else {
            if (r.status) {
                setMessage({ class: "alert-primary", message: "User updated" });
            } else if (r.messages) {
                setMessage({ class: "alert-danger", message: r.messages.shift() });
            }
        }
    }

    const archiveUser = async () => {
        setAskArchive(false);
        setIsLoading(true);
        const { f } = createArchiveUserRequest();
        const r = await f(user.id, 1);
        setIsLoading(false);
        if (r && r.status) {
            updateUserData("archived", 1);
            setMessage({ class: "alert-success", message: "User account has been archived." });
        } else {
            setMessage({ class: "alert-danger", message: "User not archived. Some error occured" });
        }
    }
    const deleteUser = async () => {
        setAskDelete(false);
        setIsLoading(true);
        const { f } = createDeleteUserRequest();
        const r = await f(user.id);
        setIsLoading(false);
        if (r && r.status) {
            history.push("/users");
        } else {
            setMessage({ class: "alert-danger", message: "User not deleted. Some error occured" });
        }
    }

    const restoreUser = async () => {
        setAskRestore(false);
        setIsLoading(true);
        const { f } = createArchiveUserRequest();
        const r = await f(user.id, 0);
        setIsLoading(false);
        if (r && r.status) {
            updateUserData("archived", 0);
            setMessage({ class: "alert-primary", message: "User account has been restored." });
        } else {
            setMessage({ class: "alert-danger", message: "User not restored. Some error occured" });
        }
    }

    const cancelModals = () => {
        setAskDelete(false);
        setAskArchive(false);
        setAskRestore(false);
        setAskPassword(false);
    }

    const resendInvite = async (userId) => {
        setInvited(true);
        const { f } = createInviteAgainUserRequest();
        await f(id);
    }

    return (
        <AdminPage error={error} breadcrumbs={[
            { title: "Home", path: "/" },
            { title: "Users", path: canShowUsersOnBreadcrumb() ? "/users" : null },
            { title: `${user?.fname || ""} ${user?.lname || ""}` }
        ]}>
            <PageBox className="p-4">
                {isLoading && <div className="p-4"><Loading></Loading></div>}
                {!isLoading && user &&
                    <>
                        <div className="d-flex align-items-center">
                            <h4><strong>{user.fname} {user.lname}</strong></h4>
                            {isThisMe() &&
                                <button onClick={() => setAskPassword(true)} className="btn btn-transparent">
                                    <small><Icon fill="--icon-primary-color"><FaKey></FaKey></Icon><span className="text-primary">Change Password</span></small>
                                </button>
                            }</div>
                        <div className="container-fluid px-0">
                            <div className="row">
                                <div className="col-12 col-lg-4 ">
                                    <div className="form-group">
                                        <label className="text-muted small">First Name</label>
                                        <input className="form-control" type="text" value={user.fname} onChange={e => updateUserData("fname", e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 ">
                                    <div className="form-group">
                                        <label className="text-muted small">Last Name</label>
                                        <input className="form-control" type="text" value={user.lname} onChange={e => updateUserData("lname", e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 ">
                                    {canShowEmail() &&
                                        <div className="form-group">
                                            <label className="text-muted small">Email</label>
                                            <input className="form-control" type="email" value={user.email} onChange={e => updateUserData("email", e.target.value)} />
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-4 ">
                                    <div className="form-group">
                                        <label className="text-muted small">Role</label>
                                        {!isThisMe() && canUpdate() && < select name="role" className="small custom-select" value={user.role} onChange={e => updateUserData("role", e.target.value)}>
                                            {getUpdateableRolesFromPermissions().map(r => <option key={r} value={r}>{roleName(r)}</option>)}
                                        </select>
                                        }
                                        {(isThisMe() || !canUpdate()) && <input name="role" disabled className="form-control" type="text" defaultValue={roleName(user.role)}></input>}
                                    </div>
                                </div>
                                {(["school_admin", "teacher"].indexOf(user.role) >= 0) && <div className="col-12 col-lg-8">
                                    <div className="form-group border p-2 background-row-light border-row-light">
                                        <label className="text-muted small">Schools</label>
                                        <div className="d-flex flex-wrap">
                                            {userSchools.map(s => <Badge className={s.archived ? "badge-secondary" : "badge-primary"} key={s.id} hideCloseButton={!canUpdateSchool()} onClose={() => { toggleSchool(s) }}><Link className="text-white" to={`/school/${s.id}`}>{s.name}</Link></Badge>)}
                                            {!canUpdateSchool() && userSchools && userSchools.length === 0 && <span className="small text-muted">No schools linked</span>}
                                        </div>
                                        {canUpdateSchool() && <>
                                            <input className="form-control" type="text" value={schoolSearchString} onChange={e => setSchoolSearchString(e.target.value)} placeholder="Type a school name" />
                                            <div>
                                                {isLoadingSchools && <div className="p-3"><Loading /></div>}
                                                {schools && schools.map((s, i) =>
                                                    <div
                                                        key={s.id}
                                                        className={`d-flex align-items-center justify-content-start p-0 m-0 pl-2 ${i % 2 ? "background-row-light" : "background-row-lighter"} background-row-hover`}
                                                    >
                                                        <input onChange={() => { toggleSchool(s) }} checked={isSchoolSelected(s.id)} id={`s${s.id}`} type="checkbox" className="mr-2" />
                                                        <label className="m-0 py-2 flex-grow-1 cursor-pointer" htmlFor={`s${s.id}`}>{s.name}</label>
                                                    </div>
                                                )}
                                            </div>
                                        </>}
                                    </div>
                                </div>}
                            </div>
                            {message.message &&
                                <div className={"alert " + message.class}>
                                    {message.message}
                                    <button onClick={clearMessage} className="btn close"><FaTimes /></button>
                                </div>
                            }
                            <div className="d-flex border-light border-top pt-2 flex-wrap">
                                {canUpdate() && <>
                                    {!user.active && <button disabled={invited || isAllSchoolsArchived()} onClick={() => resendInvite()} className="btn btn-apple btn-has-icon mr-2"><Icon><FaEnvelope /></Icon>{invited ? "Invited" : "Resend Invite"}</button>}
                                    <button onClick={() => save()} className="btn btn-apple btn-has-icon mr-2"><Icon><FaDownload /></Icon>Save</button></>}
                                <div className="flex-grow-1"></div>
                                {canArchive() && !user.archived && <button onClick={() => setAskArchive(true)} className="btn btn-transparent btn-has-icon"><Icon noMargin fill="--icon-primary-color"><MdArchive /></Icon><small className="text-primary">Archive</small></button>}
                                {canArchive() && !!user.archived && <button onClick={() => setAskRestore(true)} className="btn btn-transparent btn-has-icon"><Icon noMargin fill="--icon-primary-color"><MdUnarchive /></Icon><small className="text-primary">Restore</small></button>}
                                {canDelete() && <button onClick={() => setAskDelete(true)} className="btn btn-transparent btn-has-icon"><Icon noMargin fill="--icon-danger-color"><MdDeleteForever /></Icon><small className="text-danger">Delete Entry</small></button>}
                            </div>
                        </div>
                    </>
                }
            </PageBox>
            {isLoadingWorkshops && <div className="p-3 d-flex align-items-center justify-content-center"><Loading></Loading></div>}
            {
                !isLoadingWorkshops && workshops && workshops.length > 0 && <PageBox className="mt-4">
                    {workshops.map(s => <Row className="p-4" key={s.school_id}>
                        <h5 className="text-primary"><Link to={`/school/${s.school_id}`}>{s.school_name}</Link></h5>
                        <div className="d-flex">
                            {s.workshops.map(w => <Link to={`/workshop/${w.id}`} key={w.id} className="d-flex align-items-center justify-content-center border border-primary rounded mr-2 p-2">{w.name}</Link>)}
                        </div>
                    </Row>)}
                    <Row className="p-3 d-flex align-items-center justify-content-end">
                        <Pagination page={page} totalCount={totalWorkshopCount} countPerPage={countPerPage} setPage={setPage}></Pagination>
                    </Row>
                </PageBox>
            }

            {
                askDelete && user && <ModalDialog
                    title="Confirm Delete?"
                    callbacks={[
                        { text: "No", className: "btn-secondary px-5", onClick: cancelModals },
                        { text: "Yes", className: "btn-danger px-5", onClick: deleteUser },
                    ]}
                >
                    <div>This will delete the user <div className="alert alert-info my-2">{user.fname} {user.lname}</div> Delete?</div>
                </ModalDialog>
            }

            {
                askArchive && user && <ModalDialog
                    title="Confirm Archive?"
                    callbacks={[
                        { text: "No", className: "btn-secondary px-5", onClick: cancelModals },
                        { text: "Yes", className: "btn-danger px-5", onClick: archiveUser },
                    ]}
                >
                    <div>This will archive the user <div className="alert alert-info my-2">{user.fname} {user.lname}</div> Archive?</div>
                </ModalDialog>
            }

            {
                askRestore && user && <ModalDialog
                    title="Confirm Restore?"
                    callbacks={[
                        { text: "No", className: "btn-secondary px-5", onClick: cancelModals },
                        { text: "Yes", className: "btn-danger px-5", onClick: restoreUser },
                    ]}
                >
                    <div>This will restore the user <div className="alert alert-info my-2">{user.fname} {user.lname}</div> Restore?</div>
                </ModalDialog>
            }


            {
                askPassword && user && <ModalDialog
                    title="Password Change"
                    callbacks={[
                        { text: "OK", className: "btn-secondary px-5", onClick: cancelModals },
                    ]}
                >
                    <div>To change your password, logout and click on the Forgot Password link in the login page.</div>
                </ModalDialog>
            }
        </AdminPage >
    )
}

export default UserProfile
