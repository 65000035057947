import React from 'react'
import { useContext } from 'react'
import { Redirect, Route } from 'react-router'
import { UserContext } from '../providers/UserProvider'

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const { id } = useContext(UserContext);
    return (
        <Route {...rest} render={props => id ? <Component {...props} /> : <Redirect to={`/login?next=${props.location.pathname}`} />}></Route>
    )
}

export default ProtectedRoute
