import React, { useContext, useEffect, useRef, useState } from 'react'
import AdminPage from '../common/AdminPage';
import PageBox from '../common/PageBox';
import Badge from '../common/Badge'
import ModalDialog from '../common/ModalDialog';

import { UserContext } from '../providers/UserProvider';
import { Loading } from '../common/Loading';
import { createGetChaptersRequest, createAddWorkshopRequest, createGetSchools, createGetUsersRequest, createGetGlobalSettingsRequest, createCheckStudentNamesRequest } from '../../api/api';
import styled from "styled-components";
import { FaEdit, FaPlusCircle, FaTrash } from 'react-icons/fa';
import Icon from '../common/Icon';
import Thumbnail from '../common/Thumbnail';
import { useHistory } from 'react-router';
import { USER_ROLE_ADMIN, USER_ROLE_SCHOOL_ADMIN, USER_ROLE_TEACHER, USER_ROLE_TENDER_ADMIN, USER_ROLE_TENDER_FACILITATOR } from '../../constants';
import MultiSelectDropdown from '../common/MultiSelectDropdown';
import { Link } from 'react-router-dom';
import { MdSave } from 'react-icons/md';

const YEAR_GROUPS = [
    { label: "Yr 4", value: 4, selected: false, primary: true },
    { label: "Yr 5", value: 5, selected: false, primary: true },
    { label: "Yr 6", value: 6, selected: false, primary: true },
    { label: "Yr 7", value: 7, selected: false, primary: false },
    { label: "Yr 8", value: 8, selected: false, primary: false },
    { label: "Yr 9", value: 9, selected: false, primary: false },
    { label: "Yr 10", value: 10, selected: false, primary: false },
    { label: "Yr 11", value: 11, selected: false, primary: false },
    { label: "Post 16", value: 16, selected: false, primary: false }
];

const AddWorkshop = () => {
    const [name, setName] = useState("");
    const [yearGroups, setYearGroups] = useState([...YEAR_GROUPS]);
    const [send, setSend] = useState("no");
    const [managedByTender, setManagedByTender] = useState(false);
    const [tenderStaff, setTenderStaff] = useState([]);
    const [selectedTenderStaff, setSelectedTenderStaff] = useState([]);
    const [isDating, setIsDating] = useState(false);
    const [allowDating, setAllowDating] = useState(false);
    const [isCheckingStudentNames, setIsCheckingStudentNames] = useState(true);
    const [unlockMode, setUnlockMode] = useState(1);
    const [allChapters, setAllChapters] = useState([]);
    const [students, setStudents] = useState([]);
    const [similarStudents, setSimilarStudents] = useState([]);
    const [studentName, setStudentName] = useState("");
    const [studentPass, setStudentPass] = useState("");
    const [studentCount, setStudentCount] = useState(10);
    const [studentStart, setStudentStart] = useState(1);
    const [schools, setSchools] = useState([]);
    const [askChapter, setAskChapter] = useState(false);
    const [globalSettings, setGlobalSettings] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [isLoadingChapters, setIsLoadingChapters] = useState(true);
    const [isLoadingSchools, setIsLoadingSchools] = useState(false);
    const [isLoadingTenderStaff, setIsLoadingTenderStaff] = useState(false);
    const [schoolSearchString, setSchoolSearchString] = useState("");
    const [tenderSearchString, setTenderSearchString] = useState("");
    const [workshopSchools, setWorkshopSchools] = useState([]);
    const [error, setError] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const schoolsReq = useRef();
    const tenderStaffReq = useRef();
    const globalSettingsReq = useRef();
    const chaptersReq = useRef();
    const studentNameCheckReq = useRef();
    const history = useHistory();
    const { role, permissions } = useContext(UserContext);


    const canModifyTenderStaff = () => {
        return role === USER_ROLE_ADMIN || role === USER_ROLE_TENDER_ADMIN;
    }

    const hasPermissionToAdd = () => {
        return permissions["pm_workshops_add"] || permissions["pm_workshops_add_school_self"];
    }
    const isSchoolSelected = (id) => {
        return workshopSchools.some(s => s.id === id);
    }
    const toggleSchool = (school) => {
        let s = [...workshopSchools];
        if (isSchoolSelected(school.id)) {
            s = s.filter(e => e.id !== school.id);
        } else {
            s = [school];
        }
        setSchoolSearchString("");
        setWorkshopSchools(s);
    }

    const isTenderStaffSelected = (id) => {
        return selectedTenderStaff.some(s => s.id === id);
    }
    const toggleTenderStaff = (tenderStaff) => {
        let s = [...selectedTenderStaff];
        if (isTenderStaffSelected(tenderStaff.id)) {
            s = s.filter(e => e.id !== tenderStaff.id);
        } else {
            s.push(tenderStaff);
        }
        setTenderSearchString("");
        setSelectedTenderStaff(s);
    }


    const enableChapter = (c, isEnabled) => {
        let all = [...allChapters];
        let fd = false;
        for (let i = 0; i < all.length; i++) {
            const a = all[i];
            if (fd) {
                a.disabled = true;
                continue;
            }
            if (parseInt(a.id) === parseInt(c.id)) {
                a.disabled = !isEnabled;
                fd = a.disabled;
            }
            if (parseInt(a.linked_to) === parseInt(c.id)) {
                a.disabled = !isEnabled;
            }
        }
        setAllChapters(all);
    }

    const canEnableChapter = (c) => {
        if (c.is_alternate) {
            return false;
        };
        let chs = !isDating ? [...allChapters].filter(c => !c.is_alternate && !c.is_dating) : [...allChapters].filter(c => !c.is_alternate);
        const index = chs.findIndex(q => parseInt(q.id) === parseInt(c.id));

        const r = index < 1 ? false : !chs[index - 1].disabled;
        return r;
    }

    const lockChapter = (c, isLocked) => {
        let all = [...allChapters];
        for (let i = 0; i < all.length; i++) {
            const a = all[i];
            if (parseInt(a.id) === parseInt(c.id)) {
                a.locked = isLocked;
            }
            if (parseInt(a.linked_to) === parseInt(c.id)) {
                a.locked = isLocked;
            }
        }
        setAllChapters(all);
    }



    const addStudents = () => {
        if (studentName.trim().length === 0 || studentPass.trim().length === 0) {
            return;
        }
        let d = new Date().getTime();
        let m = parseInt(studentStart);
        let c = parseInt(studentCount);
        let ns = new Array(c).fill(null).map((e, i) => ({ id: `_${d}_${i}`, name: `${studentName}${(m + i)}`, pass: `${studentPass}${(m + i)}`, initials: '' }));
        let existingNames = students.map(s => s.name);
        ns = ns.filter(s => existingNames.indexOf(s.name) === -1);
        const alls = [...students, ...ns];
        setStudents(alls);

        let isMounted = true;

        async function getSimilarStudentNames() {
            setIsCheckingStudentNames(true);
            studentNameCheckReq?.current?.cancel();
            const { request, f } = createCheckStudentNamesRequest();
            studentNameCheckReq.current = request;
            const r = await f(alls.map(s => s.name));
            setIsCheckingStudentNames(false);
            if (!isMounted) {
                return;
            }
            if (r instanceof Error) {
                setError(r);
                return;
            } else if (r && r.status) {
                setSimilarStudents(r.data || []);
            }
        }

        getSimilarStudentNames();

    }
    const removeStudent = (id) => {
        let s = students.filter(s => s.id !== id);
        setStudents([...s]);
    }

    const isStudentExisting = (studentName) => {
        return similarStudents.some(s => s === studentName);
    }

    const anySimilarStudentsExist = () => {
        for (let i = 0; i < students.length; i++) {
            const s = students[i];
            if (isStudentExisting(s.name)) {
                return true;
            }
        }
        return false;
    }

    const validate = () => {
        return name.trim().length > 0 &&
            workshopSchools.length === 1 &&
            allChapters.some(c => !c.disabled) &&
            students.length > 0;
    }


    useEffect(() => {
        let yg = [...YEAR_GROUPS];
        if (workshopSchools.some(w => w.is_primary)) {
            yg = yg.filter(y => y.primary);
        } else {
            yg = yg.filter(y => !y.primary);
        }
        setYearGroups(yg);
    }, [workshopSchools]);

    useEffect(() => {
        if (!globalSettings || !workshopSchools || workshopSchools.length === 0) {
            return;
        }
        if (workshopSchools.some(w => w.is_primary)) {
            setAllowDating(globalSettings.primary_school.allow_dating);
            setIsDating(globalSettings.primary_school.allow_dating);
            setUnlockMode(globalSettings.primary_school.chapter_unlocking);
            let a = [...allChapters];
            for (let i = 0; i < a.length; i++) {
                const ch = a[i];
                ch.disabled = globalSettings.primary_school.chapter_ids.indexOf(ch.id) === -1;
                if (i > 0 && globalSettings.primary_school.chapter_unlocking === 0) {
                    ch.locked = true;
                }
            }
            setAllChapters(a);
        } else {
            setAllowDating(globalSettings.secondary_school.allow_dating);
            setIsDating(globalSettings.secondary_school.allow_dating);
            setUnlockMode(globalSettings.secondary_school.chapter_unlocking);
            let a = [...allChapters];
            for (let i = 0; i < a.length; i++) {
                const ch = a[i];
                ch.disabled = globalSettings.secondary_school.chapter_ids.indexOf(ch.id) === -1;
                if (i > 0 && globalSettings.secondary_school.chapter_unlocking === 0) {
                    ch.locked = true;
                }
            }
            setAllChapters(a);
        }
        //eslint-disable-next-line 
    }, [workshopSchools, globalSettings]);



    useEffect(() => {
        let isMounted = true;

        async function getSchool() {
            setIsLoadingSchools(true);
            schoolsReq?.current?.cancel();
            const { request, f } = createGetSchools(role);
            schoolsReq.current = request;
            const r = await f();
            setIsLoadingSchools(false);
            if (!isMounted) {
                return;
            }
            if (r instanceof Error) {
                setError(r);
                return;
            } else if (r && r.status) {
                setWorkshopSchools(r.data.schools);
            }
        }

        if ((role === USER_ROLE_SCHOOL_ADMIN || role === USER_ROLE_TEACHER)) {
            getSchool();
        }
        return () => {
            schoolsReq?.current?.cancel();
            isMounted = false;
        }
    }, [role])



    useEffect(() => {
        let isMounted = true;

        async function loadChapters() {
            setIsLoadingChapters(true);
            chaptersReq?.current?.cancel();
            chaptersReq.current = null;

            const { request, f } = createGetChaptersRequest();
            chaptersReq.current = request;
            const r = await f();
            setIsLoadingChapters(false);
            if (!isMounted) {
                return;
            }
            if (r instanceof Error) {
                setError(r);
            } else if (r && r.status) {
                const d = r.data.map(c => ({ ...c, disabled: true, locked: false })).sort((a, b) => a.display_order - b.display_order);
                d[0].disabled = false;
                d[0].locked = false;
                setAllChapters(d);
            }
        }

        loadChapters();

        return () => {
            isMounted = false;
            chaptersReq?.current?.cancel();
        }
    }, [])

    useEffect(() => {
        let isMounted = true;
        async function loadSettings() {
            globalSettingsReq?.current?.cancel();

            const { request, f } = createGetGlobalSettingsRequest();
            globalSettingsReq.current = request;
            const r = await f();
            if (!isMounted) {
                return;
            }
            if (r && r.status) {
                setGlobalSettings(r.data.settings);
            }
        }
        loadSettings();

        return () => {
            isMounted = false;
            globalSettingsReq?.current?.cancel();
        }
    }, []);

    useEffect(() => {
        let isMounted = true;
        if (schoolSearchString.trim().length === 0) {
            setSchools([]);
            return;
        }
        async function getSchools() {
            setIsLoadingSchools(true);
            if (schoolsReq.current) {
                schoolsReq.current.cancel();
                schoolsReq.current = null;
            }

            const { request, f } = createGetSchools(role);
            schoolsReq.current = request;

            const r = await f(schoolSearchString, 0, 0);
            setIsLoadingSchools(false);
            if (!isMounted) {
                return;
            }
            if (r instanceof Error) {
                setError(r);
            } else if (r && r.status) {
                setSchools(r.data.schools.filter(s => !s.archived).slice(0, 5));
            }
        }
        getSchools();
        return () => {
            isMounted = false;
            schoolsReq?.current?.cancel();
        }
    }, [schoolSearchString, role]);


    useEffect(() => {
        let isMounted = true;
        if (tenderSearchString.trim().length === 0) {
            setTenderStaff([]);
            return;
        }
        async function getTenderStaff() {
            setIsLoadingTenderStaff(true);
            tenderStaffReq?.current?.cancel();

            const { request, f } = createGetUsersRequest();
            tenderStaffReq.current = request;

            const r = await f(tenderSearchString, 0, 0);
            setIsLoadingTenderStaff(false);
            if (!isMounted) {
                return;
            }
            if (r instanceof Error) {
                setError(r);
            } else if (r && r.status) {
                setTenderStaff(r.data.users.filter(u => (u.role === USER_ROLE_TENDER_FACILITATOR || u.role === USER_ROLE_TENDER_ADMIN) && !u.archived).slice(0, 5));
            }
        }
        getTenderStaff();
        return () => {
            isMounted = false;
            tenderStaffReq?.current?.cancel();
        }
    }, [tenderSearchString, role]);


    const isChapterEnabledInSettings = (chapterId) => {
        if (workshopSchools.some(w => w.is_primary)) {
            return globalSettings.primary_school.chapter_ids.indexOf(chapterId) >= 0;
        } else {
            return globalSettings.secondary_school.chapter_ids.indexOf(chapterId) >= 0;
        }
    }

    const removeAllDatingChapters = () => {
        if (isDating) {
            let cs = [...allChapters];
            for (let i = 0; i < cs.length; i++) {
                const c = cs[i];
                if (c.is_dating) {
                    c.disabled = true;
                }
            }
            setAllChapters(cs);
        }
    };

    const setUnlockModeFromRadioClick = (mode) => {
        setUnlockMode(mode);
        let cs = [...allChapters];
        if (mode === 1) {
            cs.forEach(c => {
                c.locked = false;
            });
        }
        if (mode === 0) {
            cs.filter((v, i) => i > 0).forEach(c => {
                c.locked = true;
            });
        }
        setAllChapters(cs);
    }

    const updateStudentInitials = (id, initials) => {
        const index = students.findIndex(st => st.id.toString() === id.toString());
        if (index >= 0) {
            students[index].initials = initials.substr(0, 3);
            setStudents([...students]);
        }
    }

    const save = () => {
        setErrorMessage(null);
        async function addWorkshop() {
            setIsSaving(true);
            const { f } = createAddWorkshopRequest();
            const data = {
                name,
                school_id: workshopSchools[0]?.id,
                is_dating: isDating,
                year_groups: yearGroups.filter(y => y.selected).map(y => y.value),
                chapters: allChapters.filter(c => !c.disabled).map(c => ({ id: c.id, locked: c.locked })),
                students,
                send,
                manager_ids: selectedTenderStaff.map(t => t.id),
                auto_unlock: unlockMode === 1
            };
            const r = await f(data.name, data.school_id, data.is_dating, data.send, data.year_groups, data.chapters, data.students, managedByTender ? data.manager_ids : [], data.auto_unlock);
            setIsSaving(false);
            if (r instanceof Error) {
                setError(r);
            } else if (r) {
                if (r.status) {
                    history.push(`/workshop/${r.data.wid}`);
                } else {
                    setErrorMessage(r.messages?.shift());
                }
            }
        }
        if (managedByTender && selectedTenderStaff.length === 0) {
            setErrorMessage("The field 'Managed by Tender' is set but no one is assigned.");
            return;
        }
        addWorkshop();
    }

    return (
        <AdminPage error={error} breadcrumbs={[
            { title: "Home", path: "/" },
            { title: "Add Workshop" }
        ]}>
            <PageBox className="p-3">
                <h4><strong>Create Workshop</strong></h4>
                <div className="container-fluid px-0">
                    <div className="row">
                        <div className="col-12 col-lg-4 ">
                            <div className="form-group">
                                <label className="text-muted small">Workshop Name</label>
                                <input data-test-id='workshopnameinput' className="form-control" type="text" value={name} onChange={e => setName(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="form-group border p-2 background-row-light border-row-light">
                                <label className="text-muted small">School</label>
                                <div className="d-flex flex-wrap">
                                    {workshopSchools.map(s => <Badge data-test-id='addedschool' className={s.archived ? "badge-secondary" : "badge-primary"} key={s.id} onClose={() => { toggleSchool(s) }}><Link className="text-white" to={`/school/${s.id}`}>{s.name}</Link></Badge>)}
                                </div>
                                <input data-test-id='workshopschoolinput' className="form-control" type="text" value={schoolSearchString} onChange={e => setSchoolSearchString(e.target.value)} placeholder="Type a school name" />
                                <div>
                                    {isLoadingSchools && <div className="p-3"><Loading /></div>}
                                    {schools && schools.map((s, i) =>
                                        <div
                                            key={s.id}
                                            className={`d-flex align-items-center justify-content-start p-0 m-0 pl-2 ${i % 2 ? "background-row-light" : "background-row-lighter"} background-row-hover`}
                                        >
                                            <input onChange={() => { toggleSchool(s) }} checked={isSchoolSelected(s.id)} id={`s${s.id}`} type="checkbox" className="mr-2" />
                                            <label className="m-0 py-2 flex-grow-1 cursor-pointer" htmlFor={`s${s.id}`}>{s.name}</label>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="form-group mt-4">
                                <div className="form-check d-flex align-items-center">
                                    <input data-test-id='workshopmanagedbytenderinput' disabled={!canModifyTenderStaff()} id="managedByTender" className="form-check-input" type="checkbox" checked={managedByTender} onChange={e => setManagedByTender(e.target.checked)} />
                                    <label htmlFor="managedByTender" className="form-check-label text-muted small pt-1">Managed By Tender</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-12 col-lg-4">
                            <div className="form-group">
                                <label className="text-muted small">Year Group</label>
                                <MultiSelectDropdown onChange={(d) => { setYearGroups(d) }} className="form-control" options={yearGroups}></MultiSelectDropdown>
                            </div>
                        </div>

                        <div className="col-12 col-lg-4">
                            <label className="text-muted small mt-1">SEND</label>
                            <div className="form-group mt-2 mb-0">
                                <div className="form-check form-check-inline small">
                                    <input id="sendYes" type="radio" className="form-check-input" checked={send === "yes"} onChange={e => setSend("yes")} />
                                    <label htmlFor="sendYes" className="form-check-label" >Yes</label>
                                </div>
                                <div className="form-check form-check-inline small">
                                    <input data-test-id='sendno' id="sendNo" type="radio" className="form-check-input" checked={send === "no"} onChange={e => setSend("no")} />
                                    <label htmlFor="sendNo" className="form-check-label" >No</label>
                                </div>
                                <div className="form-check form-check-inline small">
                                    <input id="sendMixed" type="radio" className="form-check-input" checked={send === "mixed"} onChange={e => setSend("mixed")} />
                                    <label htmlFor="sendMixed" className="form-check-label" >Mixed</label>
                                </div>
                            </div>
                        </div>

                        {managedByTender && <div className="col-12 col-lg-4">
                            <div className="form-group border p-2 background-row-light border-row-light">
                                <label className="text-muted small">Tender Staff</label>
                                <div className="d-flex flex-wrap">
                                    {selectedTenderStaff.map(s => <Badge className={s.archived ? "badge-secondary" : "badge-primary"} key={s.id} onClose={() => { toggleTenderStaff(s) }}><Link className="text-white" to={`/user/${s.id}`}>{s.fname} {s.lname}</Link></Badge>)}
                                </div>
                                <input data-test-id='workshoptendernameinput' className="form-control" type="text" value={tenderSearchString} onChange={e => setTenderSearchString(e.target.value)} placeholder="Type a name" />
                                <div>
                                    {isLoadingTenderStaff && <div className="p-3"><Loading /></div>}
                                    {tenderStaff && tenderStaff.map((s, i) =>
                                        <div
                                            key={s.id}
                                            className={`d-flex align-items-center justify-content-start p-0 m-0 pl-2 ${i % 2 ? "background-row-light" : "background-row-lighter"} background-row-hover`}
                                        >
                                            <input onChange={() => { toggleTenderStaff(s) }} checked={isTenderStaffSelected(s.id)} id={`s${s.id}`} type="checkbox" className="mr-2" />
                                            <label className="m-0 py-2 flex-grow-1 cursor-pointer" htmlFor={`s${s.id}`}>{s.fname} {s.lname}</label>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>}


                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-3">
                            <div className="form-group">
                                <label className="text-muted small">Dating Levels</label>
                                <RadioGroup>
                                    <div className="form-check form-check-inline small">
                                        <input data-test-id='workshopdatingyes' disabled={!allowDating} className="form-check-input" id="datingYes" type="radio" checked={isDating} onChange={e => setIsDating(true)} />
                                        <label className="form-check-label" htmlFor="datingYes">Yes</label>
                                    </div>
                                    <div className="form-check form-check-inline small">
                                        <input data-test-id='workshopdatingno' disabled={!allowDating} className="form-check-input" id="datingNo" type="radio" checked={!isDating} onChange={e => { setIsDating(false); removeAllDatingChapters(); }} />
                                        <label className="form-check-label" htmlFor="datingNo">No</label>
                                    </div>
                                </RadioGroup>
                            </div>
                        </div>
                        <div className="col-12 col-lg-5">
                            <div className="form-group">
                                <label className="text-muted small">Chapter Unlocking</label>
                                <RadioGroup>
                                    <div className="form-check form-check-inline small">
                                        <input data-test-id='workshopunlockmanual' className="form-check-input" id="unlockManual" type="radio" checked={unlockMode === 0} onChange={e => setUnlockModeFromRadioClick(0)} />
                                        <label className="form-check-label" htmlFor="unlockManual">Manually Unlock</label>
                                    </div>
                                    <div className="form-check form-check-inline small">
                                        <input data-test-id='workshopunlockauto' className="form-check-input" id="unlockAuto" type="radio" checked={unlockMode === 1} onChange={e => setUnlockModeFromRadioClick(1)} />
                                        <label className="form-check-label" htmlFor="unlockAuto">Automatically Unlock</label>
                                    </div>
                                </RadioGroup>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="form-group">
                                <label className="text-muted small">&nbsp;</label>
                                {isLoadingChapters && <Loading />}
                                {!isLoadingChapters && <button onClick={() => setAskChapter(true)} className="btn btn-outline-primary btn-has-icon form-control">
                                    <Icon fill="--icon-dull-color"><FaEdit></FaEdit></Icon>
                                    <span>Change Chapters</span>
                                </button>}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-column alert alert-warning border-warning">
                    <div className="d-flex align-items-center">
                        <span>{unlockMode === 0 ? "Manually Unlock" : "Automatic Unlocking"}</span>
                    </div>
                    <span className="small text-dark">{unlockMode === 0 ? "Manual Unlock requires that an adult unlocks the next chapter in the sequence for the student." : "Automatically Unlock enables students to progress independantly."}</span>
                </div>

                <h5 className="mt-3">Manage the Game</h5>
                <div className="d-flex flex-column border-top border-left border-right">
                    {isLoadingChapters &&
                        <div className="border-bottom p-3">
                            <Loading />
                        </div>
                    }
                    {!isLoadingChapters &&
                        <>
                            {allChapters.filter(c => !c.disabled && !c.is_alternate && !c.is_dating).sort((a, b) => a.display_order - b.display_order).map((c, index) =>
                                <div data-test-id='addedchapteritem' key={c.id} className="border-bottom d-flex align-items-center">
                                    <Thumbnail title={c.title} src={c.image} width={150} height={100}></Thumbnail>
                                    <RadioGroup className="ml-2">
                                        <div className="form-check form-check-inline small">
                                            <input data-test-id='addedchapterlocked' disabled={index === 0 || unlockMode === 1} checked={c.locked} onChange={(e) => lockChapter(c, true)} name={`locked-${c.id}`} className="form-check-input" id={`locked-${c.id}`} type="radio" />
                                            <label className="form-check-label" htmlFor={`locked-${c.id}`}> Locked</label>
                                        </div>
                                        <div className="form-check form-check-inline small">
                                            <input data-test-id='addedchapterunlocked' disabled={index === 0 || unlockMode === 1} checked={!c.locked} onChange={(e) => lockChapter(c, false)} name={`locked-${c.id}`} className="form-check-input" id={`unlocked-${c.id}`} type="radio" />
                                            <label className="form-check-label" htmlFor={`unlocked-${c.id}`}> Unlocked</label>
                                        </div>
                                    </RadioGroup>
                                </div>
                            )}

                        </>}
                </div>
                <h5 className="mt-5">Create Student Credentials</h5>
                <div className="d-flex flex-column">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-lg-3">
                                <div className="form-group">
                                    <label htmlFor="stName" className="small text-muted">Username</label>
                                    <input data-test-id='workshopstudentname' type="text" className="form-control" value={studentName} onChange={(e) => setStudentName(e.currentTarget.value)} />
                                </div>
                            </div>
                            <div className="col-12 col-lg-3">
                                <div className="form-group">
                                    <label htmlFor="stPass" className="small text-muted">Password</label>
                                    <input data-test-id='workshopstudentpass' type="text" className="form-control" value={studentPass} onChange={(e) => setStudentPass(e.currentTarget.value)} />
                                </div>
                            </div>
                            <div className="col-12 col-lg-3">
                                <label className="small text-muted">Start, Count</label>
                                <div className="input-group">
                                    <input data-test-id='workshopstudentstart' type="text" className="form-control small p-1 text-center" value={studentStart} onChange={(e) => setStudentStart(parseInt(e.currentTarget.value) || 1)} />
                                    <button className="btn btn-secondary" onClick={() => setStudentCount(Math.max(1, studentCount - 1))}>-</button>
                                    <input data-test-id='workshopstudentcount' type="text" className="form-control small p-1 text-center" value={studentCount} onChange={(e) => setStudentCount(parseInt(e.currentTarget.value) || 1)} />
                                    <button className="btn btn-secondary" onClick={() => setStudentCount(studentCount + 1)}>+</button>
                                </div>
                            </div>
                            <div className="col-12 col-lg-3">
                                <div className="form-group">
                                    <label>&nbsp;</label>
                                    <button data-test-id='workshopaddstudentsbutton' onClick={addStudents} className="form-control btn btn-primary btn-has-icon"><small><Icon><FaPlusCircle></FaPlusCircle></Icon>Add Students</small></button>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="alert alert-warning border-warning">
                                    <p className="mb-1">The username for each student should be unique globally.</p>
                                    <p className="small mb-0">Duplicate entries will not be created.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row d-none d-md-flex justify-content-between">
                            <div className="col-3 small text-muted">Created Name</div>
                            <div className="col-3 small text-muted">Created Password</div>
                            <div className="col-2 col-lg-3 small text-muted">Initials</div>
                            <div className="col-3 small">&nbsp;</div>
                        </div>
                        {students.map(s =>
                            <div key={s.id} className="row background-row-light border-top border-bottom py-2 mx-0 justify-content-between" style={{
                                background: isStudentExisting(s.name) ? "#ff9999" : "unset",
                            }}>
                                <div className="col-12 col-md-3">
                                    <label className="small text-muted d-block d-md-none">Name</label>
                                    <input type="text" className="form-control" disabled defaultValue={s.name} />
                                </div>
                                <div className="col-12 col-md-3">
                                    <label className="small text-muted d-block d-md-none">Password</label>
                                    <input type="text" className="form-control" disabled defaultValue={s.pass} />
                                </div>
                                <div className="col-12 col-md-2 col-lg-3 mb-2 mb-md-0">
                                    <label className="small text-muted d-block d-md-none">Initials</label>
                                    <input type="text" className="form-control" value={s.initials} onChange={(e) => updateStudentInitials(s.id, e.currentTarget.value)} />
                                </div>
                                <div className="col-12 col-md-3 d-flex align-items-center justify-content-end">
                                    <button onClick={() => { removeStudent(s.id) }} className="btn btn-transparent btn-has-icon"><Icon noMargin fill="--icon-danger-color"><FaTrash></FaTrash></Icon></button>
                                </div>
                            </div>
                        )}
                        {errorMessage && <div className="row p-3 my-2 alert alert-danger border border-danger">{errorMessage}</div>}
                        {anySimilarStudentsExist() && <div className="row p-3 my-2 alert alert-danger border border-danger">The highlighted student names are already in use. Please choose another name. These ones will be skipped when saving this workshop.</div>}
                        {hasPermissionToAdd() && <div className="row align-items-center justify-content-end mt-3 border-top pt-3 pb-2">
                            <button data-test-id='workshopsavebutton' onClick={save} disabled={!validate() || isSaving || isCheckingStudentNames} className="btn btn-apple btn-has-icon"><Icon><MdSave></MdSave></Icon>Create</button>
                        </div>}
                    </div>
                </div>
            </PageBox>
            {
                askChapter &&
                <ModalDialog title="Change Setup" onClose={() => { setAskChapter(false) }} callbacks={
                    [
                        { text: "OK", className: "btn-primary", onClick: () => { setAskChapter(false) } }
                    ]}>
                    <div className="p-2">
                        <h6><strong>Display Chapters in Game</strong></h6>
                        <div className="d-flex border-top flex-column">
                            {allChapters.filter(c => !c.is_alternate && !c.is_dating && isChapterEnabledInSettings(c.id)).map((c, index) => (isDating ? true : !(c.is_dating || c.is_alternate)) &&
                                <div data-test-id='chaptersetupitem' key={c.id} className="border-bottom d-flex row m-0">
                                    <div className="col-2 p-0">
                                        <img src={c.image} alt={c.title} className="w-100" />
                                    </div>
                                    <div className="col-10 d-flex flex-column">
                                        <strong className="small">{c.title}</strong>
                                        <RadioGroup>
                                            <div className="form-check form-check-inline small">
                                                <input data-test-id='chaptersetupitemavailable' checked={!c.disabled} disabled={!canEnableChapter(c)} onChange={(e) => enableChapter(c, true)} name={`available-${c.id}`} className="form-check-input" id={`available-${c.id}`} type="radio" />
                                                <label className="form-check-label" htmlFor={`available-${c.id}`}> Available</label>
                                            </div>
                                            <div className="form-check form-check-inline small">
                                                <input data-test-id='chaptersetupitemunavailable' checked={c.disabled} disabled={!canEnableChapter(c)} onChange={(e) => enableChapter(c, false)} name={`available-${c.id}`} className="form-check-input" id={`unavailable-${c.id}`} type="radio" />
                                                <label className="form-check-label" htmlFor={`unavailable-${c.id}`}> Unavailable</label>
                                            </div>
                                        </RadioGroup>
                                    </div>
                                </div>)}
                        </div>
                    </div>
                </ModalDialog>
            }
        </AdminPage >
    )
}

export default AddWorkshop


const RadioGroup = styled.div.attrs((props) => ({ className: "d-flex" }))`
    height: calc(1.5em + 0.75rem + 2px);
`;
