import React, { useContext, useEffect, useRef, useState } from 'react'
import AdminPage from '../common/AdminPage'
import PageBox from '../common/PageBox'
import { createGetSchools } from '../../api/api';
import { useHistory } from 'react-router';
import { Loading } from '../common/Loading';
import { UserContext } from '../providers/UserProvider';
import SchoolList from '../sections/school/SchoolList';
import { Row } from '../common/Row';

const MySchool = () => {
    const [isLoading, setIsLoading] = useState(false);
    const request = useRef();
    const [schools, setSchools] = useState([]);
    const history = useHistory();
    const { role } = useContext(UserContext);


    useEffect(() => {
        let isMounted = true;
        async function loadSchools() {
            setIsLoading(true);
            if (request.current) {
                request.current.cancel();
                request.current = null;
            }

            const { request: c, f } = createGetSchools(role);
            request.current = c;

            const r = await f("", 0, 1);
            if (!isMounted) {
                return;
            }
            if (r && r.status) {
                setSchools(r.data.schools);
            } else {
                console.log("Error");
            }
            setIsLoading(false);
        }
        loadSchools();
        return () => {
            isMounted = false;
            request?.current?.cancel();
        }
    }, [role]);


    const editSchool = (schoolId) => {
        history.push(`/school/${schoolId}`);
    }


    return (
        <AdminPage breadcrumbs={[
            { title: "Home", path: "/" },
            { title: "My School" }
        ]}>

            <PageBox>
                <Row className="p-4">
                    <h4><strong>My School</strong></h4>

                    <div className="footer text-muted small"><strong>School Name</strong></div>
                </Row>

                {isLoading && <div className="p-3"><Loading></Loading></div>}
                {!isLoading && <SchoolList schools={schools} onClick={editSchool}></SchoolList>}
            </PageBox>
        </AdminPage >
    )
}

export default MySchool




