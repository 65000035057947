import React from 'react'
import styled from "styled-components"

const PageBox = (props) => {
    return (
        <CDiv {...props}>
            {props.children}
        </CDiv>
    )
}

export default PageBox

const CDiv = styled.div.attrs(props => ({ className: "card" }))`
    border:1px solid #dfe0eb;
`;
