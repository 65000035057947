import React, { useEffect } from 'react'
import { useContext } from 'react';
import { useState } from 'react'
import { Redirect } from 'react-router';
import { forgotPasswordSubmitCode, forgotPasswordSubmitEmail, forgotPasswordSubmitPassword, login } from '../../api/api';
import { PageLoading, Loading } from '../common/Loading';
import { UserConsumer, UserContext } from '../providers/UserProvider';
import styled from "styled-components";
import { Image } from "react-bootstrap";
import { FaSignInAlt, FaEnvelope, FaChevronCircleLeft } from "react-icons/fa";
import { TiDocumentText } from "react-icons/ti";
import { BsShieldLock } from "react-icons/bs";
import Icon from '../common/Icon';
import tenderLogo from "../../assets/images/frame.png";
import bg from "../../assets/images/bg.jpg";
import logo from "../../assets/images/logo.png";
import PasswordStrength from '../common/PasswordStrength';


const Login = () => {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [accepted, setAccepted] = useState(false);
    const [code, setCode] = useState();
    const [userId, setUserId] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [page, setPage] = useState("login");

    const userData = useContext(UserContext);

    useEffect(() => {
        setErrorMessage("");
        if (page === "login") {
            setEmail("");
            setPassword("");
            setCode("");
            setUserId("");
        }
    }, [page])

    const doLogin = async (e) => {
        if (!accepted) {
            return;
        }
        e.preventDefault();
        setErrorMessage("");
        setPage("loading");

        const loginData = await login(email, password);
        if (loginData && loginData.status) {
            userData.update(loginData.data);
        } else {
            setPage("login");
            await new Promise(r => setTimeout(r, 100));
            setErrorMessage("Login Failed");
        }
    }

    const submitEmail = async (e) => {
        e.preventDefault();
        setPage("loading");
        const r = await forgotPasswordSubmitEmail(email);
        if (r) {
            if (r.status) {
                setPage("forgot2");
            } else if (r.messages) {
                setPage("forgot1");
                await new Promise(r => setTimeout(r, 100));
                setErrorMessage(r.messages.shift());
            }
        } else {
            setPage("forgot1");
            await new Promise(r => setTimeout(r, 100));
            setErrorMessage("Error");
        }
    }

    const submitCode = async (e) => {
        e.preventDefault();
        setPage("loading");
        const r = await forgotPasswordSubmitCode(code);
        if (r) {
            if (r.status) {
                setUserId(r.data.user_id);
                setPage("forgot3");
            } else if (r.messages) {
                setPage("forgot2");
                await new Promise(r => setTimeout(r, 100));
                setErrorMessage(r.messages.shift());
            }
        } else {
            setPage("forgot2");
            await new Promise(r => setTimeout(r, 100));
            setErrorMessage("Error");
        }
    }

    const submitPassword = async (e) => {
        e.preventDefault();
        setPage("loading");
        const r = await forgotPasswordSubmitPassword(userId, code, password);
        if (r) {
            if (r.status) {
                setPage("forgot4");
            } else if (r.messages) {
                setPage("forgot3");
                await new Promise(r => setTimeout(r, 100));
                setErrorMessage(r.messages.shift());
            }
        } else {
            setPage("forgot3");
            await new Promise(r => setTimeout(r, 100));
            setErrorMessage("Error");
        }
    }


    const loginForm = () => userData.isLoading ?
        <PageLoading />
        :
        <div className="d-flex align-items-center justify-content-center flex-column min-vh-100">
            <CContainer>
                <div className="row justify-content-center">
                    <Logo><Image src={logo} width="100%"></Image></Logo>
                    {page === "loading" &&
                        <div className="card p-4 py-5 w-100"><Loading></Loading></div>
                    }
                    {page === "login" &&
                        <form className="card p-4 w-100" method="post" action="/admin/login">
                            <h4><strong>Login</strong></h4>
                            <p className="text-muted">Welcome Back!</p>
                            <div className="form-group">
                                <label name="email" className="small text-muted"><strong>Email Address</strong></label>
                                <input type="email" name="email" className="form-control" onChange={e => setEmail(e.target.value)} />
                            </div>

                            <div className="form-group">
                                <label name="password" className="small text-muted"><strong>Password</strong></label>
                                <input type="password" name="password" className="form-control" onChange={e => setPassword(e.target.value)} />
                            </div>

                            <div className="form-group">
                                {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                            </div>

                            <div className="form-group form-check">
                                <input type="checkbox" className="form-check-input" id="accept" checked={accepted} onChange={e => setAccepted(e.target.checked)} />
                                <label className="form-check-label small" htmlFor="accept">I accept the use of cookies (which are required for the use of our portal and can't be switched off in our system), our <a href="https://relationshipgoals.uk/privacy" rel="no-referrer" target="_blank">Privacy Policy</a> and <a href="https://relationshipgoals.uk/terms-of-use" target="_blank" rel="no-referrer">Terms of Use</a>.
                                </label>
                            </div>


                            <div className="form-group d-flex align-items-center justify-content-between">
                                <button disabled={!accepted} className="btn btn-has-icon btn-apple" type="submit" onClick={doLogin}><Icon><FaSignInAlt></FaSignInAlt></Icon>Log In</button>
                                <button className="btn btn-link" onClick={() => setPage("forgot1")}>Forgot Password</button>
                            </div>
                        </form>
                    }
                    {page === "forgot1" &&
                        <div className="card p-4 w-100" method="post" action="/admin/login">
                            <h4><strong>Forgot Password</strong></h4>
                            <p>Enter the email address associated with your account</p>
                            <div className="form-group">
                                <label name="email" className="small text-muted"><strong>Email Address</strong></label>
                                <input type="email" name="email" className="form-control" onChange={e => setEmail(e.target.value)} />
                            </div>
                            <div className="form-group">
                                {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                            </div>
                            <div className="form-group d-flex align-items-center justify-content-between">
                                <button className="btn btn-has-icon btn-apple" type="submit" onClick={() => setPage("login")}><Icon><FaChevronCircleLeft /></Icon>Back To Log In</button>
                                <button className="btn btn-has-icon btn-apple" type="submit" onClick={submitEmail}><Icon><FaEnvelope /></Icon>Submit</button>
                            </div>
                        </div>
                    }
                    {page === "forgot2" &&
                        <div className="card p-4 w-100" method="post" action="/admin/login">
                            <h4><strong>Forgot Password</strong></h4>
                            <p>An email has been sent to the email id with a code. Please enter the code to continue.</p>
                            <div className="form-group">
                                <label name="code" className="small text-muted"><strong>Code</strong></label>
                                <input type="text" name="code" className="form-control" onChange={e => setCode(e.target.value)} />
                            </div>
                            <div className="form-group">
                                {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                            </div>
                            <div className="form-group d-flex align-items-center justify-content-between">
                                <button className="btn btn-has-icon btn-apple" type="submit" onClick={() => setPage("login")}><Icon><FaChevronCircleLeft /></Icon>Back To Log In</button>
                                <button className="btn btn-has-icon btn-apple" type="submit" onClick={submitCode}><Icon><FaEnvelope /></Icon>Submit</button>
                            </div>
                        </div>
                    }
                    {page === "forgot3" &&
                        <div className="card p-4 w-100" method="post" action="/admin/login">
                            <h4><strong>Forgot Password</strong></h4>
                            <p>Please type in the new password.</p>
                            <div className="form-group">
                                <label name="password" className="small text-muted"><strong>Password</strong></label>
                                <input type="text" name="password" className="form-control" onChange={e => setPassword(e.target.value)} />
                                {password.length > 0 && <PasswordStrength password={password} className="d-block mt-0 p-1"></PasswordStrength>}
                            </div>
                            <div className="form-group">
                                {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                            </div>
                            <div className="form-group d-flex align-items-center justify-content-between">
                                <button className="btn btn-has-icon btn-apple" type="submit" onClick={() => setPage("login")}><Icon><FaChevronCircleLeft /></Icon>Back To Log In</button>
                                <button className="btn btn-has-icon btn-apple" type="submit" onClick={submitPassword}><Icon><FaEnvelope /></Icon>Submit</button>
                            </div>
                        </div>
                    }
                    {page === "forgot4" &&
                        <div className="card p-4 w-100" method="post" action="/admin/login">
                            <h4><strong>Forgot Password</strong></h4>
                            <p>Your password has been changed. Go back and login again with the new password.</p>
                            <div className="form-group d-flex align-items-center justify-content-between">
                                <button className="btn btn-has-icon btn-apple" type="submit" onClick={() => setPage("login")}><Icon><FaChevronCircleLeft /></Icon>Back To Log In</button>
                            </div>
                        </div>
                    }
                </div>
            </CContainer>

            <Footer className="d-flex flex-column align-items-center justify-content-end mb-5 mt-5">
                <TenderLogo></TenderLogo>
                <div className="d-flex align-items-center justify-content-center">
                    <a className="small text-white btn-has-icon" rel="noreferrer" target="_blank" href="https://relationshipgoals.uk/terms-of-use"><Icon className="mr-1" noMargin fill="--icon-white-color"><TiDocumentText></TiDocumentText></Icon>Terms & Conditions</a>
                    <a className="small text-white mx-5 btn-has-icon" rel="noreferrer" target="_blank" href="https://relationshipgoals.uk/privacy"><Icon className="mr-1" noMargin fill="--icon-white-color"><BsShieldLock></BsShieldLock></Icon>Privacy Policy</a>
                    <a className="small text-white btn-has-icon" rel="noreferrer" target="_blank" href="https://relationshipgoals.uk/contact"><Icon className="mr-1" noMargin fill="--icon-white-color"><FaEnvelope></FaEnvelope></Icon>Contact Us</a>
                </div>
            </Footer>
        </div>

    const redirectPath = () => {
        const location = window.location.href.replace("#", "/");
        const next = new URL(location).searchParams.get("next");
        return next || "/";
    }

    return <UserConsumer>
        {user => user.id ? <Redirect to={redirectPath()} /> : <Background>{loginForm()}</Background>}
    </UserConsumer>
}


export default Login


const Background = styled.div`
    background:url('${bg}');
    background-size:cover;
    width:100%;
    height:100%;
`;

const CContainer = styled.div.attrs(props => ({ className: "container" }))`
    @media screen and (min-width:769px){
        max-width:600px;
    }
`;

const Logo = styled.div`
    margin-bottom:15px;
`;


const Footer = styled.div``;

const TenderLogo = styled.div`
    background:url('${tenderLogo}');
    background-position:center;
    background-size:contain;
    background-repeat:no-repeat;
    height:70px;
    width:150px;
    margin:0 0 20px 0;
`;