export const USER_ROLE_ADMIN = "administrator";
export const USER_ROLE_TENDER_FACILITATOR = "facilitator";
export const USER_ROLE_TENDER_ADMIN = "tender_admin";
export const USER_ROLE_SCHOOL_ADMIN = "school_admin";
export const USER_ROLE_TEACHER = "teacher";


export const MAX_SCORE_CHAPTERS = [
    { chapterId: 1, score: 476 },
    { chapterId: 2, score: 574 },
    { chapterId: 3, score: 845 },
    { chapterId: 4, score: 1016 },
    { chapterId: 5, score: 813 },
    { chapterId: 6, score: 10 },
    { chapterId: 7, score: 1231 },
    { chapterId: 8, score: 808 },
    { chapterId: 9, score: 0 },
    { chapterId: 10, score: 0 },
    { chapterId: 11, score: 0 }
];