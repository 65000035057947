import React, { useContext, useEffect, useRef, useState } from 'react'
import { FaDownload, FaTimes } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { createAddUserRequest, createGetSchoolRequest, createGetSchools } from '../../api/api'
import { USER_ROLE_ADMIN, USER_ROLE_SCHOOL_ADMIN, USER_ROLE_TEACHER, USER_ROLE_TENDER_ADMIN, USER_ROLE_TENDER_FACILITATOR } from '../../constants'
import AdminPage from '../common/AdminPage'
import Badge from '../common/Badge'
import Icon from '../common/Icon'
import { Loading } from '../common/Loading'
import PageBox from '../common/PageBox'
import { UserContext } from '../providers/UserProvider'

const InviteUser = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSchools, setIsLoadingSchools] = useState(false);
    const [error, setError] = useState(null);
    const [user, setUser] = useState({
        fname: "",
        lname: "",
        role: "teacher",
        email: "",
    });
    const [schoolSearchString, setSchoolSearchString] = useState("");
    const [message, setMessage] = useState({ class: "", message: "" });
    const [userSchools, setUserSchools] = useState([]);
    const [schools, setSchools] = useState(null);
    const { role, permissions } = useContext(UserContext);
    const userReq = useRef();
    const schoolsReq = useRef();

    const clearMessage = () => {
        setMessage({ class: "", message: "" });
    }

    const updateUserData = (key, value) => {
        const nu = { ...user, [key]: value };
        setUser(nu);
    }
    const roleName = (role) => {
        const o = {
            [USER_ROLE_ADMIN]: "Administrator",
            [USER_ROLE_TENDER_ADMIN]: "Tender Admin",
            [USER_ROLE_TENDER_FACILITATOR]: "Facilitator",
            [USER_ROLE_TEACHER]: "Teacher",
            [USER_ROLE_SCHOOL_ADMIN]: "School Admin"
        }
        return o[role];
    }
    const getAddableRolesFromPermissions = () => {
        const o = {
            "pm_users_update_admin": USER_ROLE_ADMIN,
            "pm_users_update_tender_admin": USER_ROLE_TENDER_ADMIN,
            "pm_users_update_school_admin": USER_ROLE_SCHOOL_ADMIN,
            "pm_users_update_facilitator": USER_ROLE_TENDER_FACILITATOR,
            "pm_users_update_teacher": USER_ROLE_TEACHER
        };
        let p = [];
        for (const key in o) {
            const v = o[key];
            if (permissions[key]) {
                p.push(v);
            }
        };
        return p;
    }

    const canAdd = () => {
        const roles = getAddableRolesFromPermissions();
        return roles.indexOf(user.role) !== -1;
    }


    const isSchoolSelected = (id) => {
        return userSchools.some(s => s.id === id);
    }
    const toggleSchool = (school) => {
        let s = [...userSchools];
        if (isSchoolSelected(school.id)) {
            s = s.filter(e => e.id !== school.id);
        } else {
            s = [school];
            setSchoolSearchString("");
        }

        setUserSchools(s);
    }

    useEffect(() => {
        let isMounted = true;
        const location = window.location.href.replace("#", "/");
        const params = new URL(location).searchParams;
        const r = params.get("role");
        const schoolId = params.get("school_id");

        if (r) {
            setUser(u => ({ ...u, role: r }));
        }

        async function getSchools() {
            setIsLoadingSchools(true);
            schoolsReq?.current?.cancel();
            const { request, f } = createGetSchools(role);
            schoolsReq.current = request;
            const r = await f();
            console.log(r);
            setIsLoadingSchools(false);
            if (!isMounted) {
                return;
            }
            if (r instanceof Error) {
                setError(r);
                return;
            } else if (r && r.status) {
                setUserSchools(r.data.schools);
            }
        }

        async function getSchool() {
            setIsLoadingSchools(true);
            schoolsReq?.current?.cancel();
            const { request, f } = createGetSchoolRequest();
            schoolsReq.current = request;
            const r = await f(schoolId);
            setIsLoadingSchools(false);
            if (!isMounted) {
                return;
            }
            if (r instanceof Error) {
                setError(r);
                return;
            } else if (r && r.status) {
                setUserSchools([r.data]);
            }
        }
        if (parseInt(schoolId) > 0) {
            getSchool();
        } else if (role === USER_ROLE_SCHOOL_ADMIN || role === USER_ROLE_TEACHER) {
            getSchools();
        }
        return () => {
            isMounted = false;
        }
    }, [role])


    useEffect(() => {
        let isMounted = true;
        if (schoolSearchString.trim().length === 0) {
            setSchools([]);
            return;
        }
        async function getSchools() {
            setIsLoadingSchools(true);
            schoolsReq?.current?.cancel();
            const { request, f } = createGetSchools(role);
            schoolsReq.current = request;

            const r = await f(schoolSearchString, 0, 0);
            if (!isMounted) {
                return;
            }
            if (r instanceof Error) {
                setError(r);
                return;
            } else if (r && r.status) {
                setSchools(r.data.schools.slice(0, 5));
            }
            setIsLoadingSchools(false);
        }
        getSchools();
        return () => {
            isMounted = false;
            schoolsReq?.current?.cancel();
        }
    }, [schoolSearchString, role]);

    const save = async () => {
        clearMessage();
        userReq?.current?.cancel();

        if (user.fname.trim().length === 0) {
            setMessage({ class: "alert-danger", message: "First name is required" });
            return;
        } else if (user.lname.trim().length === 0) {
            setMessage({ class: "alert-danger", message: "Last name is required" });
            return;
        } else if (user.email.trim().length === 0 || user.email.indexOf("@") === -1 || user.email.indexOf(".") === -1) {
            setMessage({ class: "alert-danger", message: "Email is invalid" });
            return;
        } else if ([USER_ROLE_SCHOOL_ADMIN, USER_ROLE_TEACHER].indexOf(user.role) >= 0 && userSchools.length === 0) {
            setMessage({ class: "alert-danger", message: `School is required for ${roleName(user.role)}` });
            return;
        }

        setIsLoading(true);
        const { request, f } = createAddUserRequest();
        userReq.current = request;

        let schoolIds = userSchools.map(u => u.id) || [];

        if ([USER_ROLE_SCHOOL_ADMIN, USER_ROLE_TEACHER].indexOf(user.role) === -1) {
            schoolIds = [];
        }
        const r = await f(user.fname, user.lname, user.role, user.email, schoolIds);
        setIsLoading(false);
        if (r instanceof Error) {
            setMessage({ class: "alert-danger", message: "User invitation failed." });
        } else {
            if (r.status) {
                setMessage({ class: "alert-primary", message: "User invitation has been sent." });
            } else if (r.messages) {
                setMessage({ class: "alert-danger", message: r.messages.shift() });
            }
        }
    }



    return (
        <AdminPage error={error} breadcrumbs={[
            { title: "Home", path: "/" },
            { title: "Invite User" }
        ]}>
            <PageBox className="p-4">
                {isLoading && <div className="p-4"><Loading></Loading></div>}
                {!isLoading &&
                    <>
                        <h4><strong>Invite User</strong></h4>
                        <div className="container-fluid px-0">
                            <div className="row">
                                <div className="col-12 col-lg-4 ">
                                    <div className="form-group">
                                        <label className="text-muted small">First Name</label>
                                        <input className="form-control" type="text" value={user.fname} onChange={e => updateUserData("fname", e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 ">
                                    <div className="form-group">
                                        <label className="text-muted small">Last Name</label>
                                        <input className="form-control" type="text" value={user.lname} onChange={e => updateUserData("lname", e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 ">
                                    <div className="form-group">
                                        <label className="text-muted small">Email</label>
                                        <input className="form-control" type="email" value={user.email} onChange={e => updateUserData("email", e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-4 ">
                                    <div className="form-group">
                                        <label className="text-muted small">Role</label>
                                        <select name="role" className="small custom-select" value={user.role} onChange={e => updateUserData("role", e.target.value)}>
                                            {getAddableRolesFromPermissions().map(r => <option key={r} value={r}>{roleName(r)}</option>)}
                                        </select>
                                    </div>
                                </div>
                                {(["school_admin", "teacher"].indexOf(user.role) >= 0) && <div className="col-12 col-lg-8">
                                    <div className="form-group border p-2 background-row-light border-row-light">
                                        <label className="text-muted small">Schools</label>
                                        <div className="d-flex flex-wrap">
                                            {userSchools.map(s => <Badge className={s.deleted ? "badge-secondary" : "badge-primary"} key={s.id} onClose={() => { toggleSchool(s) }}><Link className="text-white" to={`/school/${s.id}`}>{s.name}</Link></Badge>)}
                                        </div>
                                        <input className="form-control" type="text" value={schoolSearchString} onChange={e => setSchoolSearchString(e.target.value)} placeholder="Type a school name" />
                                        <div>
                                            {isLoadingSchools && <div className="p-3"><Loading /></div>}
                                            {schools && schools.map((s, i) =>
                                                <div
                                                    key={s.id}
                                                    className={`d-flex align-items-center justify-content-start p-0 m-0 pl-2 ${i % 2 ? "background-row-light" : "background-row-lighter"} background-row-hover`}
                                                >
                                                    <input onChange={() => { toggleSchool(s) }} checked={isSchoolSelected(s.id)} id={`s${s.id}`} type="checkbox" className="mr-2" />
                                                    <label className="m-0 py-2 flex-grow-1 cursor-pointer" htmlFor={`s${s.id}`}>{s.name}</label>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>}
                            </div>
                            {message.message &&
                                <div className={"alert " + message.class}>
                                    {message.message}
                                    <button onClick={clearMessage} className="btn close"><FaTimes /></button>
                                </div>
                            }
                            {canAdd() && <div className="d-flex border-light border-top pt-2">
                                <button onClick={() => save()} className="btn btn-apple btn-has-icon mr-2"><Icon><FaDownload /></Icon>Save</button>
                            </div>}
                        </div>
                    </>
                }
            </PageBox>
        </AdminPage >
    )
}

export default InviteUser
