import React from 'react'
import AdminPage from '../common/AdminPage'
import PageBox from '../common/PageBox';
import TrainingItemList from '../sections/training/TrainingItemList';

const Training = () => {


    return (
        <AdminPage breadcrumbs={[
            { title: "Home", path: "/" },
            { title: "Training & Resources" }
        ]}>
            <PageBox className="p-4">
                <h4 className="title"><strong>Training & Resources</strong></h4>
                <TrainingItemList />
            </PageBox>
        </AdminPage >
    )
}

export default Training
