import React, { useEffect, useRef, useState } from 'react'
import { MdSave } from 'react-icons/md';
import styled from 'styled-components';
import { createGetChaptersRequest, createGetGlobalSettingsRequest, createSaveGlobalSettingsRequest } from '../../api/api';
import AdminPage from '../common/AdminPage'
import Icon from '../common/Icon';
import { Loading } from '../common/Loading';
import PageBox from '../common/PageBox';
import Thumbnail from '../common/Thumbnail';

const DEFAULT_SETTINGS = {
    secondary_school: {
        allow_dating: true,
        primary_text: false,
        chapter_unlocking: 0,
        chapter_ids: []
    },
    primary_school: {
        allow_dating: false,
        primary_text: true,
        chapter_unlocking: 1,
        chapter_ids: []
    }
};

const GlobalSettings = () => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [chapters, setChapters] = useState(null);
    const settingsReq = useRef();
    const chapterReq = useRef();

    useEffect(() => {
        let isMounted = true;
        async function loadData() {
            setIsLoading(true);
            settingsReq?.current?.cancel();
            chapterReq?.current?.cancel();

            const { request: sr, f: sf } = createGetGlobalSettingsRequest();
            settingsReq.current = sr;
            const s = await sf();

            const { request: cr, f: cf } = createGetChaptersRequest();
            settingsReq.current = cr;
            const c = await cf();

            if (!isMounted) {
                return;
            }
            if (s && s.status) {
                if (s.data.settings) {
                    setData(s.data.settings);
                } else {
                    setData(DEFAULT_SETTINGS);
                }
            }

            if (c && c.status) {
                setChapters(c.data);
            }

            setIsLoading(false);
        }
        loadData();

        return () => {
            isMounted = false;
            settingsReq?.current?.cancel();
        }
    }, []);

    useEffect(() => {

    }, []);


    const updateData = (path, value) => {
        let d = { ...data };
        let keys = path.split("/");
        let obj;
        while (keys.length > 1) {
            let key = keys.shift();
            obj = d[key];
            if (typeof (obj) !== "object") {
                throw new Error(`Cannot update. ${obj} is not a json`);
            }
        }
        obj[keys[0]] = value;
        setData(d);
    }

    const selectChapterForSecondary = (chapterId, selected) => {
        let d = { ...data };
        d.secondary_school.chapter_ids = d.secondary_school.chapter_ids.filter(c => parseInt(c) !== parseInt(chapterId));
        if (selected) {
            d.secondary_school.chapter_ids.push(chapterId);
        }
        setData(d);
    }

    const selectChapterForPrimary = (chapterId, selected) => {
        let d = { ...data };
        const i = d.primary_school.chapter_ids.indexOf(chapterId);
        if (i >= 0) {
            d.primary_school.chapter_ids.splice(i, 1);
        }
        if (selected) {
            d.primary_school.chapter_ids.push(chapterId);
        }
        setData(d);
    }

    const saveSettings = async () => {
        const { f } = createSaveGlobalSettingsRequest();
        setIsSaving(true);

        let alternateChapters = chapters.filter(c => c.is_alternate);
        alternateChapters.forEach(a => {

            data.secondary_school.chapter_ids = data.secondary_school.chapter_ids.filter(c => parseInt(c) !== parseInt(a.id));
            data.primary_school.chapter_ids = data.primary_school.chapter_ids.filter(c => parseInt(c) !== parseInt(a.id));

            if (data.secondary_school.chapter_ids.indexOf(a.linked_to) >= 0) {
                data.secondary_school.chapter_ids.push(a.id);
            }
            if (data.primary_school.chapter_ids.indexOf(a.linked_to) >= 0) {
                data.primary_school.chapter_ids.push(a.id);
            }

            data.primary_school.chapter_ids = [...new Set(data.primary_school.chapter_ids)];
            data.secondary_school.chapter_ids = [...new Set(data.secondary_school.chapter_ids)];
        });
        await f(data);
        await new Promise(r => setTimeout(r, 1000));
        setIsSaving(false);
    }


    return (
        <AdminPage breadcrumbs={[
            { title: "Home", path: "/" },
            { title: "Global Settings" }
        ]}>
            {isLoading && <div className="p-3 d-flex align-items-center justify-content-center"><Loading></Loading></div>}
            {!isLoading && data !== null && <PageBox className="p-4 mb-3">
                <h4 className="title mb-4"><strong>Global Settings</strong></h4>
                <h5 className="title"><strong>Default Settings for Secondary School</strong></h5>

                <div className="row">
                    <div className="col-12 col-lg-4">
                        <div className="form-group">
                            <label className="text-muted small">Allow Dating</label>
                            <RadioGroup>
                                <div className="form-check form-check-inline small">
                                    <input className="form-check-input" id="s_datingYes" type="radio" checked={data.secondary_school.allow_dating} onChange={e => updateData("secondary_school/allow_dating", true)} />
                                    <label className="form-check-label" htmlFor="s_datingYes">Yes</label>
                                </div>
                                <div className="form-check form-check-inline small">
                                    <input className="form-check-input" id="s_datingNo" type="radio" checked={!data.secondary_school.allow_dating} onChange={e => updateData("secondary_school/allow_dating", false)} />
                                    <label className="form-check-label" htmlFor="s_datingNo">No</label>
                                </div>
                            </RadioGroup>
                        </div>
                    </div>

                    {/* <div className="col-12 col-lg-4">
                        <div className="form-group">
                            <label className="text-muted small">Primary Text</label>
                            <RadioGroup>
                                <div className="form-check form-check-inline small">
                                    <input className="form-check-input" id="s_primaryTextYes" type="radio" checked={data.secondary_school.primary_text} onChange={e => updateData("secondary_school/primary_text", true)} />
                                    <label className="form-check-label" htmlFor="s_primaryTextYes">Yes</label>
                                </div>
                                <div className="form-check form-check-inline small">
                                    <input className="form-check-input" id="s_primaryTextNo" type="radio" checked={!data.secondary_school.primary_text} onChange={e => updateData("secondary_school/primary_text", false)} />
                                    <label className="form-check-label" htmlFor="s_primaryTextNo">No</label>
                                </div>
                            </RadioGroup>
                        </div>
                    </div> */}


                    <div className="col-12 col-lg-4">
                        <div className="form-group">
                            <label className="text-muted small">Chapter Unlocking</label>
                            <RadioGroup>
                                <div className="form-check form-check-inline small">
                                    <input className="form-check-input" id="s_chapterUnlockingYes" type="radio" checked={data.secondary_school.chapter_unlocking === 0} onChange={e => updateData("secondary_school/chapter_unlocking", 0)} />
                                    <label className="form-check-label" htmlFor="s_chapterUnlockingYes">Manually Unlock</label>
                                </div>
                                <div className="form-check form-check-inline small">
                                    <input className="form-check-input" id="s_chapterUnlockingNo" type="radio" checked={data.secondary_school.chapter_unlocking === 1} onChange={e => updateData("secondary_school/chapter_unlocking", 1)} />
                                    <label className="form-check-label" htmlFor="s_chapterUnlockingNo">Automatically Unlock</label>
                                </div>
                            </RadioGroup>
                        </div>
                    </div>
                </div>
                <h5 className="title"><strong>Available Chapters</strong></h5>

                <div className="row d-flex flex-column">
                    {!isLoading && chapters && chapters.filter(c => !c.is_alternate).sort((a, b) => a.display_order - b.display_order).map((c, index) =>
                        <div key={c.id} className="border-bottom d-flex align-items-center">
                            <Thumbnail title={c.title} src={c.image} width={75} height={50}></Thumbnail>
                            <div className="ml-2 form-check form-check-inline d-flex align-items-center">
                                <input id={`s_ch_${c.id}`} type="checkbox" className="form-check-input" checked={data.secondary_school.chapter_ids.indexOf(c.id) >= 0} onChange={e => selectChapterForSecondary(c.id, e.target.checked)} />
                                <label className="form-check-label" htmlFor={`s_ch_${c.id}`}>Enabled</label>
                            </div>
                        </div>
                    )}
                </div>

                <h5 className="title mt-5"><strong>Default Settings for Primary School</strong></h5>




                <div className="row">
                    <div className="col-12 col-lg-4">
                        <div className="form-group">
                            <label className="text-muted small">Allow Dating</label>
                            <RadioGroup>
                                <div className="form-check form-check-inline small">
                                    <input className="form-check-input" id="p_datingYes" type="radio" checked={data.primary_school.allow_dating} onChange={e => updateData("primary_school/allow_dating", true)} />
                                    <label className="form-check-label" htmlFor="p_datingYes">Yes</label>
                                </div>
                                <div className="form-check form-check-inline small">
                                    <input className="form-check-input" id="p_datingNo" type="radio" checked={!data.primary_school.allow_dating} onChange={e => updateData("primary_school/allow_dating", false)} />
                                    <label className="form-check-label" htmlFor="p_datingNo">No</label>
                                </div>
                            </RadioGroup>
                        </div>
                    </div>

                    {/* <div className="col-12 col-lg-4">
                        <div className="form-group">
                            <label className="text-muted small">Primary Text</label>
                            <RadioGroup>
                                <div className="form-check form-check-inline small">
                                    <input className="form-check-input" id="p_primaryTextYes" type="radio" checked={data.primary_school.primary_text} onChange={e => updateData("primary_school/primary_text", true)} />
                                    <label className="form-check-label" htmlFor="p_primaryTextYes">Yes</label>
                                </div>
                                <div className="form-check form-check-inline small">
                                    <input className="form-check-input" id="p_primaryTextNo" type="radio" checked={!data.primary_school.primary_text} onChange={e => updateData("primary_school/primary_text", false)} />
                                    <label className="form-check-label" htmlFor="p_primaryTextNo">No</label>
                                </div>
                            </RadioGroup>
                        </div>
                    </div> */}


                    <div className="col-12 col-lg-4">
                        <div className="form-group">
                            <label className="text-muted small">Chapter Unlocking</label>
                            <RadioGroup>
                                <div className="form-check form-check-inline small">
                                    <input className="form-check-input" id="p_chapterUnlockingYes" type="radio" checked={data.primary_school.chapter_unlocking === 0} onChange={e => updateData("primary_school/chapter_unlocking", 0)} />
                                    <label className="form-check-label" htmlFor="p_chapterUnlockingYes">Manually Unlock</label>
                                </div>
                                <div className="form-check form-check-inline small">
                                    <input className="form-check-input" id="p_chapterUnlockingNo" type="radio" checked={data.primary_school.chapter_unlocking === 1} onChange={e => updateData("primary_school/chapter_unlocking", 1)} />
                                    <label className="form-check-label" htmlFor="p_chapterUnlockingNo">Automatically Unlock</label>
                                </div>
                            </RadioGroup>
                        </div>
                    </div>
                </div>
                <h5 className="title"><strong>Available Chapters</strong></h5>

                <div className="row d-flex flex-column">
                    {!isLoading && chapters && chapters.filter(c => !c.is_alternate).sort((a, b) => a.display_order - b.display_order).map((c, index) =>
                        <div key={c.id} className="border-bottom d-flex align-items-center">
                            <Thumbnail title={c.title} src={c.image} width={75} height={50}></Thumbnail>
                            <div className="ml-2 form-check form-check-inline d-flex align-items-center">
                                <input id={`p_ch_${c.id}`} type="checkbox" className="form-check-input" checked={data.primary_school.chapter_ids.indexOf(c.id) >= 0} onChange={e => selectChapterForPrimary(c.id, e.target.checked)} />
                                <label className="form-check-label" htmlFor={`p_ch_${c.id}`}>Enabled</label>
                            </div>
                        </div>
                    )}
                </div>


                <div className="d-flex align-items-center justify-content-end mt-3">
                    {isSaving && <Loading></Loading>}
                    <button onClick={saveSettings} className="btn-apple btn btn-has-icon ml-2"><Icon><MdSave></MdSave></Icon>Save Settings</button>
                </div>
            </PageBox>}
        </AdminPage >
    )
}

export default GlobalSettings


const RadioGroup = styled.div.attrs((props) => ({ className: "d-flex" }))`
    height: calc(1.5em + 0.75rem + 2px);
`;