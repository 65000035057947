import React, { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router';
import { createGetWorkshopAnalytics, createGetWorkshopDeviceDetails } from '../../api/api';
import AdminPage from '../common/AdminPage'
import { Loading } from '../common/Loading';
import PageBox from '../common/PageBox';
import { Chart } from "react-google-charts";
import { UserContext } from '../providers/UserProvider';
import { USER_ROLE_SCHOOL_ADMIN, USER_ROLE_TEACHER } from '../../constants';

const WorkshopAnalytics = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [scores, setScores] = useState(null);
    const [sessions, setSessions] = useState(null);
    const [attemptCounts, setAttemptCounts] = useState(null);
    const [isLoadingDeviceDetails, setIsLoadingDeviceDetails] = useState(true);
    const [deviceDetails, setDeviceDetails] = useState(null);
    const { id } = useParams();
    const dataReq = useRef();
    const deviceDetailsReq = useRef();
    const { role } = useContext(UserContext);

    const canShowLoginStatusBoxes = () => {
        return !(role === USER_ROLE_SCHOOL_ADMIN || role === USER_ROLE_TEACHER);
    }

    const timestampToDate = (ts) => {
        const d = new Date(Number(ts) * 1000);
        return d.toLocaleDateString() + "  " + d.toLocaleTimeString();
    }

    useEffect(() => {
        let isMounted = true;
        async function loadData() {
            setIsLoading(true);
            dataReq?.current?.cancel();
            const { request, f } = createGetWorkshopAnalytics();
            dataReq.current = request;
            if (!isMounted) {
                return;
            }
            const r = await f(id);
            if (r instanceof Error) {
                setError(r);
            } else if (r && r.status) {
                setData(r.data);
                let _scores = [];
                r.data.chapters.sort((a, b) => a.display_order - b.display_order).forEach(c => {
                    const scoresSorted = r.data.attempts.filter(a => parseInt(a.chapter_id) === parseInt(c.id))?.map(a => a.score > 0 ? a.score : 0)?.sort((a, b) => a - b);
                    let max = Math.max(...scoresSorted);
                    let min = Math.min(...scoresSorted);
                    let avg = (scoresSorted.reduce((a, b) => a + b, 0) || 0) / (scoresSorted.length || 1);
                    max = max < 0 ? 0 : max;
                    min = min < 0 ? 0 : min;
                    avg = avg < 0 ? 0 : avg;
                    _scores.push({ title: c.title, min, max, avg });
                });
                setScores(_scores);
                let _sessions = [];
                r.data.chapters.sort((a, b) => a.display_order - b.display_order).forEach(c => {
                    const sessionsSorted = r.data.sessions.filter(a => parseInt(a.chapter_id) === parseInt(c.id))?.map(a => a.value)?.sort((a, b) => a - b);
                    let max = Math.max(...sessionsSorted);
                    let min = Math.min(...sessionsSorted);
                    let avg = (sessionsSorted.reduce((a, b) => a + b, 0) || 0) / (sessionsSorted.length || 1);
                    max = max < 0 ? 0 : max;
                    min = min < 0 ? 0 : min;
                    avg = avg < 0 ? 0 : avg;
                    _sessions.push({ title: c.title, min, max, avg });
                });
                setSessions(_sessions);
                let _attemptCounts = [];
                r.data.chapters.sort((a, b) => a.display_order - b.display_order).forEach(c => {
                    const attempts = r.data.attempts.filter(a => parseInt(a.chapter_id) === parseInt(c.id));
                    const students = attempts.map(a => a.student_id).filter((v, i, a) => a.indexOf(v) === i);
                    const counts = students.map(s => attempts.filter(a => parseInt(a.student_id) === parseInt(s)).length || 0);
                    let max = Math.max(...counts);
                    let min = Math.min(...counts);
                    let avg = (counts.reduce((a, b) => a + b, 0) || 0) / (counts.length || 1);
                    max = max < 0 ? 0 : max;
                    min = min < 0 ? 0 : min;
                    avg = avg < 0 ? 0 : avg;
                    _attemptCounts.push({ title: c.title, min, max, avg });
                });
                setAttemptCounts(_attemptCounts);
            }
            setIsLoading(false);
        }
        loadData();
        return () => {
            isMounted = false;
            dataReq?.current?.cancel();
        }
    }, [id])

    useEffect(() => {
        let isMounted = true;
        const loadData = async function () {
            setIsLoadingDeviceDetails(true);
            deviceDetailsReq?.current?.cancel();
            const { request: request2, f: f2 } = createGetWorkshopDeviceDetails();
            deviceDetailsReq.current = request2;
            if (!isMounted) {
                return;
            }
            const r2 = await f2(id);
            if (r2 instanceof Error) {
                setError(r2);
            } else if (r2 && r2.status && r2.data) {
                setDeviceDetails(r2.data);
            }
            setIsLoadingDeviceDetails(false);
        }

        loadData();

        return () => {
            isMounted = false;
            deviceDetailsReq?.current?.cancel();
        }
    }, [id]);

    const playingStudentsCount = () => {
        return data.attempts.map(a => a.student_id).filter((v, i, a) => a.indexOf(v) === i).length || 0;
    }

    const fmtMSS = (s) => { return (s && s !== Infinity && s !== -Infinity) ? new Date(s * 1000).toISOString().substr(11, 8) : "--" };
    const getTicks = (max, count) => {
        return new Array(count + 2).fill(null).map((r, i) => ({ v: i * max / count, f: fmtMSS(i * max / count) }));
    }

    return (
        <AdminPage error={error} breadcrumbs={[
            { title: "Home", path: "/" },
            { title: "Workshops", path: "/workshops" },
            { title: (data?.workshop?.name || ""), path: data?.workshop ? `/workshop/${data.workshop.id}` : null },
            { title: (data?.workshop?.name || "") + " Analytics" }
        ]}>
            {isLoading && <div className="d-flex align-items-center justify-content-center p-4"><Loading></Loading></div>}
            {!isLoading && (data !== null) && <>
                <h4><strong>{data.workshop.name}</strong> Analytics</h4>
                <div className="row justify-content-center">
                    <div className="col-6 col-md-2 display border rounded bg-white p-3 m-3 d-flex flex-column align-items-center justify-content-center">
                        <h6 className="text-muted text-center"><strong>Students Playing</strong></h6>
                        <span className="h3 m-0 text-primary text-center">{playingStudentsCount()}</span>
                    </div>
                    <div className="col-6 col-md-2 display border rounded bg-white p-3 m-3 d-flex flex-column align-items-center justify-content-center">
                        <h6 className="text-muted text-center"><strong>Students Completed</strong></h6>
                        <span className="h3 m-0 text-primary text-center">{data.students_finished || 0}</span>
                    </div>
                    {canShowLoginStatusBoxes() &&
                        <div className="col-6 col-md-2 display border rounded bg-white p-3 m-3 d-flex flex-column align-items-center justify-content-center">
                            <h6 className="text-muted text-center"><strong>Students Logged In</strong></h6>
                            <span className="h3 m-0 text-primary text-center">{Math.max(data.students_logged_in, playingStudentsCount(), 0)}</span>
                        </div>
                    }
                    {canShowLoginStatusBoxes() &&
                        <div className="col-6 col-md-2 display border rounded bg-white p-3 m-3 d-flex flex-column align-items-center justify-content-center">
                            <h6 className="text-muted text-center"><strong>Total Successful Logins</strong></h6>
                            <span className="h3 m-0 text-primary text-center">{Math.max(data.login_success_count, playingStudentsCount(), 0)}</span>
                        </div>
                    }
                    <div className="col-6 col-md-2 display border rounded bg-white p-3 m-3 d-flex flex-column align-items-center justify-content-center">
                        <h6 className="text-muted text-center"><strong>Voice Over Enabled</strong></h6>
                        <span className="h3 m-0 text-primary text-center">{data.student_vo_count || 0}</span>
                    </div>
                    <div className="col-6 col-md-2 display border rounded bg-white p-3 m-3 d-flex flex-column align-items-center justify-content-center">
                        <h6 className="text-muted text-center"><strong>SFX Enabled</strong></h6>
                        <span className="h3 m-0 text-primary text-center">{data.student_sfx_count || 0}</span>
                    </div>
                    <div className="col-6 col-md-2 display border rounded bg-white p-3 m-3 d-flex flex-column align-items-center justify-content-center">
                        <h6 className="text-muted text-center"><strong>Player Voice Over Enabled</strong></h6>
                        <span className="h3 m-0 text-primary text-center">{data.student_povo_count || 0}</span>
                    </div>
                    {canShowLoginStatusBoxes() &&
                        <>
                            <div className="col-6 col-md-2 display border rounded bg-white p-3 m-3 d-flex flex-column align-items-center justify-content-center">
                                <h6 className="text-muted text-center"><strong>Failed Logins</strong></h6>
                                <span className="h3 m-0 text-primary text-center">{data.login_fail_count || 0}</span>
                            </div>
                            <div className="col-6 col-md-2 display border rounded bg-white p-3 m-3 d-flex flex-column align-items-center justify-content-center">
                                <h6 className="text-muted text-center"><strong>Students Logged In Today</strong></h6>
                                <span className="h3 m-0 text-primary text-center">{data.login_success_count_today || 0}</span>
                            </div>
                        </>
                    }
                </div>

                <PageBox className="p-4">
                    {sessions.length > 0 && <><h5 className="mb-3">Time Spent by Students</h5>
                        <div className="d-flex align-items-center">
                            <span className="display circle circle-small analytics-bg-color1 mr-1"></span>
                            <span className="mr-3 small">Min. Session Time</span>
                            <span className="display circle circle-small analytics-bg-color2 mr-1"></span>
                            <span className="mr-3 small">Avg. Session Time</span>
                            <span className="display circle circle-small analytics-bg-color3 mr-1"></span>
                            <span className="mr-3 small">Max. Session Time</span>
                        </div>
                        <Chart
                            width={`100%`}
                            height={'500px'}
                            chartType="BarChart"
                            loader={<Loading />}
                            data={[
                                ["Chapter", "Min. Session Time", "Avg. Session Time", "Max. Session Time"],
                                ...sessions.map(r => [r.title, { v: r.min, f: fmtMSS(r.min) }, { v: r.avg, f: fmtMSS(r.avg) }, { v: r.max, f: fmtMSS(r.max) }])
                            ]}
                            options={{
                                legend: "none",
                                hAxis: {
                                    ticks: getTicks(Math.max(...sessions.map(r => r.max)), 5)
                                },
                                colors: ["#ffc458", "#3ac52b", "#1890ff"]
                            }}
                        ></Chart></>}
                    {attemptCounts.length > 0 && <><h5 className="mb-3">Chapter Attempts</h5>
                        <div className="d-flex align-items-center">
                            <span className="display circle circle-small analytics-bg-color1 mr-1"></span>
                            <span className="mr-3 small">Min. Attempts</span>
                            <span className="display circle circle-small analytics-bg-color2 mr-1"></span>
                            <span className="mr-3 small">Avg. Attempts</span>
                            <span className="display circle circle-small analytics-bg-color3 mr-1"></span>
                            <span className="mr-3 small">Max. Attempts</span>
                        </div>
                        <Chart
                            width={`100%`}
                            height={'500px'}
                            chartType="BarChart"
                            loader={<Loading />}
                            data={[
                                ["Chapter", "Min. Attempts", "Avg. Attempts", "Max. Attempts"],
                                ...attemptCounts.map(r => [r.title, r.min, r.avg, r.max])
                            ]}
                            options={{
                                legend: "none",
                                colors: ["#ffc458", "#3ac52b", "#1890ff"]
                            }}
                        ></Chart></>}

                    {scores && scores.length > 0 && <><h5 className="mb-3">Scores</h5>
                        <div className="d-flex align-items-center">
                            <span className="display circle circle-small analytics-bg-color1 mr-1"></span>
                            <span className="mr-3 small">Min</span>
                            <span className="display circle circle-small analytics-bg-color2 mr-1"></span>
                            <span className="mr-3 small">Max</span>
                        </div>
                        <Chart
                            width={`100%`}
                            height={'500px'}
                            chartType="BarChart"
                            loader={<Loading />}
                            data={[
                                ["Chapter", "Min", "Max"],
                                ...scores.map(r => [r.title, r.min, r.max])
                            ]}
                            options={{
                                legend: "none",
                                colors: ["#ffc458", "#3ac52b", "#1890ff"]
                            }}
                        ></Chart></>}


                    {isLoadingDeviceDetails ?
                        <div className="d-flex align-items-center justify-content-center p-4"><Loading></Loading></div> :
                        deviceDetails && deviceDetails.length && <>
                            <h5 className="mb-3">Device Metrics</h5>
                            <div>
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <th>Student</th>
                                            <th>Device details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {deviceDetails.map((d, i) => <tr key={i}>
                                            <td>{d.studentName}</td>
                                            <td>
                                                {!!d.devices.length ? <>
                                                    {d.devices.map(x => <><span>{x.osName} {x.osVersion} - {x.browserName} {x.browserVersion} <span className='small rounded badge badge-info'>({timestampToDate(x.date)})</span></span><br /></>)}
                                                </> : <span>No data yet</span>}
                                            </td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </>}
                </PageBox>
            </>}
        </AdminPage >
    )
}

export default WorkshopAnalytics
