import React, { useEffect, useRef, useState } from 'react'
import { FaDownload, FaLink } from 'react-icons/fa'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { createGetTrainingItemRequest } from '../../api/api'
import AdminPage from '../common/AdminPage'
import Icon from '../common/Icon'
import { Loading } from '../common/Loading'
import PageBox from '../common/PageBox'

const TrainingItem = () => {
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [sidebarItems, setSidebarItems] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { name } = useParams();
    const req = useRef();

    useEffect(() => {
        let isMounted = true;
        async function loadItem() {
            setIsLoading(true);

            if (req.current) {
                req.current.cancel();
                req.current = null;
            }

            const { request, f } = createGetTrainingItemRequest();
            req.current = request;

            const r = await f(name);
            if (!isMounted) {
                return;
            }
            if (r && r.status) {
                setTitle(r.data.item_title);
                setContent(r.data.item_content);
                setSidebarItems(r.data.sidebar_blocks);
            } else {
                setError("Error loading content");
            }

            setIsLoading(false);
        }
        loadItem();
        return () => {
            isMounted = false;
            req?.current?.cancel();
        }
    }, [name])

    return (
        <AdminPage error={error} breadcrumbs={[
            { title: "Home", path: "/" },
            { title: "Training & Resources", path: "/training" },
            { title }
        ]}>
            {isLoading && <div className="p-4"><Loading></Loading></div>}
            {!isLoading &&
                <div className="container-fluid px-0">
                    <div className="row">
                        <div className="col-12 col-xl-8 mb-2">
                            <PageBox className="p-3">
                                <h4 className="title">{title}</h4>
                                <CContent dangerouslySetInnerHTML={{ __html: content }}></CContent>
                            </PageBox>
                        </div>
                        <div className="col-12 col-xl-4">
                            {sidebarItems && sidebarItems.map(item =>
                                <PageBox key={item.title} className="p-3 mb-2">
                                    <h6 className="h6"><strong>{item.title}</strong></h6>
                                    <CSidebarList>
                                        {item.items && item.items.map(i =>
                                            <li key={i.title} className="small">
                                                {i.type === "file" && <><Icon fill="--icon-primary-color"><FaDownload /></Icon><span><a rel="noreferrer" target="_blank" href={i.url}>{i.title}</a></span></>}
                                                {i.type === "url" && <><Icon fill="--icon-primary-color"><FaLink /></Icon><span><a rel="noreferrer" target="_blank" href={i.url}>{i.title}</a></span></>}
                                                {i.type === "link" && <><Icon fill="--icon-primary-color"><FaLink /></Icon><span><Link replace to={`/training/${i.url}`}>{i.title}</Link></span></>}
                                            </li>
                                        )}
                                    </CSidebarList>
                                </PageBox>
                            )}
                        </div>
                    </div>
                </div>
            }

        </AdminPage >

    )
}

export default TrainingItem


const CContent = styled.div`
    list-style-position:inside;
    
    img{
        max-width:100%;
    }
`;

const CSidebarList = styled.ul`
    list-style:none;
    margin:0;
    padding:0;
    li{
        margin:0 0 0 1em;
        padding:0;
    }
`;