import React from 'react'
import styled from 'styled-components'

const SimpleProgressBar = ({ bgColor, fgColor, percent, height }) => {
    return (
        <Bg color={bgColor} height={height}><Fg color={fgColor} percent={percent}></Fg></Bg>
    )
}

export default SimpleProgressBar

const Bg = styled.div`
    position:relative;
    top:0;
    left:0;
    bottom:0;
    right:0;
    height:${p => p.height ? p.height : 15}px;
    background:${p => p.color || "#cccccc"};
`;

const Fg = styled.div`
    position:absolute;
    top:0;
    left:0;
    bottom:0;
    width:${p => p.percent || 0}%;
    background:${p => p.color || "#c66"};
`;
