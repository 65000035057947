import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router';
import { createGetStudentReport } from '../../api/api';
import { MAX_SCORE_CHAPTERS } from '../../constants';
import AdminPage from '../common/AdminPage'
import LearningOutcomeChapterRowCheck from '../common/LeaningOutcomeChapterRowCheck';
import { Loading } from '../common/Loading'
import PageBox from '../common/PageBox';
import SimpleProgressBar from '../common/SimpleProgressBar';

const StudentReport = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [scoreData, setScoreData] = useState(null);
    const [firstSession, setFirstSession] = useState(true);
    const [hasMultipleSessions, setHasMultipleSessions] = useState(false);

    const { id } = useParams();
    const dataReq = useRef();

    useEffect(() => {
        let isMounted = true;
        async function loadData() {
            setIsLoading(true);
            dataReq?.current?.cancel();
            const { request, f } = createGetStudentReport();
            dataReq.current = request;
            if (!isMounted) {
                return;
            }
            const r = await f(id);
            if (r instanceof Error) {
                setError(r);
            } else if (r && r.status) {
                setData(r.data);
            }
            setIsLoading(false);
        }
        loadData();
        return () => {
            isMounted = false;
            dataReq?.current?.cancel();
        }
    }, [id])

    useEffect(() => {
        const getScores = (attempts, chapterId) => {
            let cha = attempts.filter(a => parseInt(chapterId) === parseInt(a.chapter_id));
            let total = MAX_SCORE_CHAPTERS.find(c => parseInt(c.chapterId) === parseInt(chapterId));
            const studentIds = [...new Set(cha.map(c => c.student_id))];
            let scores = [];
            studentIds.forEach(sid => {
                let sorted;
                if (firstSession) {
                    sorted = cha.filter(c => parseInt(c.student_id) === parseInt(sid)).sort((a, b) => a.updated_at - b.updated_at);
                } else {
                    sorted = cha.filter(c => parseInt(c.student_id) === parseInt(sid)).sort((a, b) => b.updated_at - a.updated_at);
                }
                const p = sorted[0]?.healthy > 0 ? sorted[0].healthy : 0;
                scores.push(Math.floor(p * 100 / total.score));
                if (sorted.length > 1) {
                    setHasMultipleSessions(h => h || true);
                }
            });
            return scores;
        }

        const getLearningOutcomes = (attempts, chapterId) => {
            let cha = attempts.filter(a => parseInt(chapterId) === parseInt(a.chapter_id));
            const studentIds = [...new Set(cha.map(c => c.student_id))];
            let lo = [];
            studentIds.forEach(sid => {
                let sorted;
                if (firstSession) {
                    sorted = cha.filter(c => parseInt(c.student_id) === parseInt(sid)).sort((a, b) => a.updated_at - b.updated_at);
                } else {
                    sorted = cha.filter(c => parseInt(c.student_id) === parseInt(sid)).sort((a, b) => b.updated_at - a.updated_at);
                }
                lo.push(sorted[0].learning_outcomes);
            });
            lo = lo.filter(l => l.length);
            lo = [].concat.apply([], lo);
            let clo = data.tournament_data.find(t => parseInt(t.chapter_id) === parseInt(chapterId))?.learning_outcomes;
            clo = clo?.map(c => ({ count: lo.filter(l => l === c).length, title: data.learning_outcomes_all[c] }));
            return clo;
        }

        if (data) {
            let d = data.workshop_chapter_ids.map(k => {
                let scores = getScores(data.attempts, k);
                let lo = getLearningOutcomes(data.attempts, k);
                return { chapterId: k, title: data.cms_chapters[k].title, display_order: data.cms_chapters[k].display_order, scores, learningOutcomes: lo };
            });
            setScoreData(d.sort((a, b) => a.display_order - b.display_order));
        }
    }, [data, firstSession]);

    const getColor = (percent) => {
        if (percent <= 0) {
            return "#ff6164";
        } else if (percent > 0 && percent < 50) {
            return "#ff6164";
        } else if (percent >= 50 && percent < 70) {
            return "#ffc458";
        } else {
            return "#8cd36d";
        }
    }

    return (
        <AdminPage error={error} breadcrumbs={[
            { title: "Home", path: "/" },
            { title: "Workshops", path: "/workshops" },
            { title: data?.workshop?.name, path: data?.workshop ? `/workshop/${data.workshop.id}` : null },
            { title: data?.student?.initials }
        ]}>
            <PageBox className="p-3">
                {isLoading && <div className="d-flex align-items-center justify-content-center p-4"><Loading></Loading></div>}
                {!isLoading && (data !== null) && <>
                    <h4><strong><span className="text-primary">{data.school.name}: {data.workshop.name} - {data.student.initials}</span> <span className="text-dark">Student Report</span></strong></h4>
                    <div className="row mt-5">
                        <div className="col-12 col-md-6 d-flex align-items-center">
                            <h5>{data.student.initials}</h5>
                        </div>
                        {hasMultipleSessions && <div className="col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-end">
                            <button disabled={firstSession} onClick={() => setFirstSession(true)} className={`btn mr-1 ${firstSession ? "btn-secondary" : "btn-primary"}`}>1st Session</button>
                            <button disabled={!firstSession} onClick={() => setFirstSession(false)} className={`btn ${!firstSession ? "btn-secondary" : "btn-primary"}`}>Latest Session</button>
                        </div>}
                    </div>
                    {scoreData && scoreData.map((r, i) =>
                        <div key={i} className={`py-3 row border-bottom border-row-light small ${i % 0 ? "background-row-light" : "background-row-lighter"}`}>
                            <div className="col-12 col-lg-2"><strong>{r.title}</strong></div>
                            <div className="col-12 col-lg-10">
                                <SimpleProgressBar fgColor={getColor(r.scores[0] || 0)} bgColor="#afafaf" percent={r.scores[0] || 0}></SimpleProgressBar>
                                <div className="small d-flex align-items-center"><span className="d-inline-block mr-1 circle circle-small" style={{ backgroundColor: r.scores.length > 0 ? getColor(r.scores[0] || 0) : "#afafaf" }}>&nbsp;</span><span>{`${r.scores[0] || 0}%`}</span></div>
                            </div>
                        </div>)}

                    <h6 className="my-4">User Learning Outcomes</h6>
                    <div className="row">
                        <div className="col-6"><small className="text-muted">Learning Outcomes</small></div>
                        <div className="col-6"></div>
                    </div>
                    {scoreData && scoreData.map((r, i) =>
                        <LearningOutcomeChapterRowCheck
                            key={i}
                            chapterTitle={r.title}
                            learningOutcomes={r.learningOutcomes}
                        ></LearningOutcomeChapterRowCheck>
                    )}
                </>}
            </PageBox>
        </AdminPage>
    )
}

export default StudentReport


