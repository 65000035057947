import React from 'react'
import styled from "styled-components"

const Loading = () => {
    return (
        <div className="text-center">
            <div className="spinner-border spinner-primary" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}

const PageLoading = () => {
    return <LoadingOvelay><Loading /></LoadingOvelay>
}

const LoadingOvelay = styled.div`
    position:fixed;
    top:0;
    left:0;
    bottom:0;
    right:0;
    background:rgba(255,255,255,0.5);
    z-index:999;

    &>div{
        position:fixed;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        z-index:1000;
    }
`;

export { Loading, PageLoading }
