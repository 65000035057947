import React, { useContext, useEffect, useRef, useState } from 'react'
import AdminPage from '../common/AdminPage'
import Icon from '../common/Icon'
import PageBox from '../common/PageBox'
import { FaPlusCircle, FaChartLine, FaChartPie, FaSearch } from "react-icons/fa";
import { createGetSchools } from '../../api/api';
import { useHistory } from 'react-router';
import { Loading } from '../common/Loading';
import { Link } from 'react-router-dom';
import { UserContext } from '../providers/UserProvider';
import SchoolList from '../sections/school/SchoolList';
import { Row } from '../common/Row';
import Pagination from '../common/Pagination';
import { USER_ROLE_ADMIN, USER_ROLE_TENDER_ADMIN } from '../../constants';

const Schools = () => {
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(0);
    const [sort, setSort] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const request = useRef();
    const [schools, setSchools] = useState([]);
    const [totalCount, setTotalCount] = useState();
    const [countPerPage, setCountPerPage] = useState();
    const history = useHistory();
    const { role, permissions: loggedUserPermissions } = useContext(UserContext);

    const hasPermissionToAdd = () => {
        return loggedUserPermissions["pm_schools_add"];
    }

    const canShowGlobalReportsAndAnalytics = () => {
        return role === USER_ROLE_ADMIN || role === USER_ROLE_TENDER_ADMIN;
    }




    useEffect(() => {
        let isMounted = true;
        async function loadSchools() {
            setIsLoading(true);
            if (request.current) {
                request.current.cancel();
                request.current = null;
            }

            const { request: c, f } = createGetSchools(role);
            request.current = c;

            const r = await f(search, page, sort);
            if (!isMounted) {
                return;
            }
            if (r && r.status) {
                let schools = r.data.schools.map(s => ({ ...s, close_requested: r.data.close_requested.indexOf(s.id) !== -1 }));
                setSchools(schools);
                setTotalCount(r.data.total);
                setCountPerPage(r.data.count_per_page);
            } else {
                console.log("Error");
            }
            setIsLoading(false);
        }
        loadSchools();
        return () => {
            isMounted = false;
            request?.current?.cancel();
        }
    }, [search, page, sort, role]);

    useEffect(() => {
        setPage(0);
    }, [search, sort])

    const editSchool = (schoolId) => {
        history.push(`/school/${schoolId}`);
    }


    return (
        <AdminPage breadcrumbs={[
            { title: "Home", path: "/" },
            { title: "Schools" }
        ]}>
            <div className="d-flex align-items-md-center justify-content-start mb-2 flex-column flex-sm-row ">
                {hasPermissionToAdd() && <Link to="/school/add" className="btn btn-apple btn-has-icon mr-sm-2">
                    <Icon><FaPlusCircle /></Icon>Add School
                </Link>
                }
                {canShowGlobalReportsAndAnalytics() && <Link to="/global_school_report" className="btn btn-primary btn-has-icon mr-sm-2">
                    <Icon><FaChartLine /></Icon>Global Report
                </Link>}
                {canShowGlobalReportsAndAnalytics() && <Link to="/global_school_analytics" className="btn btn-outline-primary btn-has-icon mr-sm-2">
                    <Icon fill="--icon-dull-color"><FaChartPie /></Icon>Global Analytics
                </Link>}
            </div>
            <div className="form-group mb-2">
                <div className="input-group">
                    <div className="input-group-prepend">
                        <div className="input-group-text pr-1"><Icon fill="--icon-dull-color"><FaSearch /></Icon></div>
                    </div>
                    <input type="text" className="form-control" id="inlineFormInputGroup" placeholder="Find School" value={search} onChange={(e) => { setSearch(e.target.value) }} onKeyUp={(e) => { setSearch(e.target.value) }} />
                </div>
            </div>
            <PageBox>
                <Row className="p-4">
                    <h4><strong>Schools</strong></h4>
                    <select name="sort" className="small custom-select right" value={sort} onChange={e => setSort(e.target.value)}>
                        <option value="0">Oldest First</option>
                        <option value="1">Most Recent</option>
                        <option value="2">Alphabetical A-Z</option>
                        <option value="3">Alphabetical Z-A</option>
                        <option value="4">Archived</option>
                        <option value="5">Unarchived</option>
                        <option value="6">Close Requested</option>
                    </select>
                    <div className="footer text-muted small"><strong>School Name</strong></div>
                </Row>

                {isLoading && <div className="p-3"><Loading></Loading></div>}
                {!isLoading && <SchoolList schools={schools} onClick={editSchool}></SchoolList>}

                <Row className="p-4">
                    <div className="d-flex align-items-center justify-content-end">
                        <Pagination totalCount={totalCount} page={page} countPerPage={countPerPage} setPage={setPage}></Pagination>
                    </div>
                </Row>
            </PageBox>
        </AdminPage >
    )
}

export default Schools




