import styled from "styled-components";

export const Row = styled.div`
    border-bottom:1px solid #dfe0eb;
    position:relative;

    .footer{
        position:absolute;
        bottom:0;
    }
    .right{
        position: absolute;
        right: 1.5rem;
        width: auto;
        top: 50%;
        transform: translateY(-50%);
    }
`;
