import React, { useContext, useState } from 'react'
import { FaChalkboardTeacher, FaCheckCircle, FaEnvelope, FaHeart, FaSmile, FaUserCog, FaUserShield } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { createInviteAgainUserRequest } from '../../../api/api';
import { USER_ROLE_ADMIN, USER_ROLE_SCHOOL_ADMIN, USER_ROLE_TEACHER, USER_ROLE_TENDER_ADMIN, USER_ROLE_TENDER_FACILITATOR } from '../../../constants';
import Icon from '../../common/Icon';
import { Row } from '../../common/Row';
import { UserContext } from '../../providers/UserProvider';

const UserList = ({ users, userSchools, onClick }) => {
    const [invited, setInvited] = useState([]);
    const { permissions } = useContext(UserContext);

    const roleIcon = (role) => {
        const o = {
            [USER_ROLE_ADMIN]: <FaUserShield className="user-icon" />,
            [USER_ROLE_TENDER_ADMIN]: <FaUserCog className="user-icon" />,
            [USER_ROLE_TENDER_FACILITATOR]: <FaHeart className="user-icon" />,
            [USER_ROLE_TEACHER]: <FaSmile className="user-icon" />,
            [USER_ROLE_SCHOOL_ADMIN]: <FaChalkboardTeacher className="user-icon" />
        }
        return o[role];
    }

    const roleName = (role) => {
        const o = {
            [USER_ROLE_ADMIN]: "Administrator",
            [USER_ROLE_TENDER_ADMIN]: "Tender Admin",
            [USER_ROLE_TENDER_FACILITATOR]: "Facilitator",
            [USER_ROLE_TEACHER]: "Teacher",
            [USER_ROLE_SCHOOL_ADMIN]: "School Admin"
        }
        return o[role];
    }

    const getUpdateableRolesFromPermissions = () => {
        const o = {
            "pm_users_update_admin": USER_ROLE_ADMIN,
            "pm_users_update_tender_admin": USER_ROLE_TENDER_ADMIN,
            "pm_users_update_school_admin": USER_ROLE_SCHOOL_ADMIN,
            "pm_users_update_facilitator": USER_ROLE_TENDER_FACILITATOR,
            "pm_users_update_teacher": USER_ROLE_TEACHER
        };
        let p = [];
        for (const key in o) {
            const v = o[key];
            if (permissions[key]) {
                p.push(v);
            }
        };
        return p;
    }

    const isTender = (role) => role === USER_ROLE_TENDER_ADMIN || role === USER_ROLE_TENDER_FACILITATOR;

    const getSchoolName = (id) => {
        return userSchools?.find(u => parseInt(u.id) === parseInt(id))?.schools[0]?.name;
    }
    const getSchoolId = (id) => {
        return userSchools?.find(u => parseInt(u.id) === parseInt(id))?.schools[0].id;
    }


    const canUpdate = (role) => {
        const roles = getUpdateableRolesFromPermissions();
        return roles.indexOf(role) !== -1;
    }

    const inviteUser = async (userId) => {
        const i = [...invited, userId];
        setInvited(i);
        const { f } = createInviteAgainUserRequest();
        await f(userId);
    }

    return (
        <>
            {users && users.map(s =>
                <Row className="p-0" key={s.id} onClick={() => { onClick(s.id) }} data-test-useritem>
                    <UserRow>
                        <div className="row align-items-center">
                            <div className="col-12 col-md-12 col-lg-8 d-flex align-items-center mb-2 mb-lg-0">
                                {roleIcon(s.role)}
                                <div className="d-flex flex-column">
                                    <div><strong>{s.fname} {s.lname}</strong> {s.archived === 1 && <span className="badge badge-danger">Archived</span>}</div>
                                    <div className="d-flex align-items-center justify-content-start">
                                        <div className="small text-muted">{roleName(s.role)}</div>
                                        {userSchools && <div className="small ml-2">{isTender(s.role) ? "TENDER" : <Link onClick={e => e.stopPropagation()} to={`/school/${getSchoolId(s.id)}`}>{getSchoolName(s.id)}</Link>}</div>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-4 d-flex justify-content-around">
                                {!s.active && canUpdate(s.role) && <>
                                    {!s.school_archived && invited.indexOf(s.id) === -1 &&
                                        <button onClick={(e) => { e.stopPropagation(); inviteUser(s.id); }} className="btn btn-outline-primary btn-has-icon flex-grow-1 mr-1"><Icon fill="--icon-dull-color"><FaEnvelope /></Icon>Resend Invite</button>
                                    }
                                    {!s.school_archived && invited.indexOf(s.id) !== -1 &&
                                        <button disabled className="btn btn-outline-secondary btn-has-icon flex-grow-1 mr-1"><Icon fill="--icon-dull-color"><FaCheckCircle /></Icon>Invited</button>
                                    }
                                    {s.school_archived === 1 && <button disabled className="btn btn-outline-secondary btn-has-icon flex-grow-1 mr-1"><Icon fill="--icon-dull-color"><FaEnvelope /></Icon>Resend Invite</button>}
                                </>}
                            </div>
                        </div>
                    </UserRow>
                </Row>
            )
            }
            {(!users || users.length === 0) && <div className="alert-warning m-2 p-4 text-center border text-dark border-warning">No users to show.</div>}
        </>
    )
}

export default UserList


const UserRow = styled.div.attrs((prop) => ({ className: "container-fluid m-0 p-4" }))`
    cursor:pointer;
    .user-icon{
        fill:var(--icon-dull-color);
        width:50px;
        height:50px;
        margin-right:1rem;
    }
    &:hover{
        background:#f6fbff;
        .user-icon{
            fill:var(--icon-primary-color);
        }
    }
`;