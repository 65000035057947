import React from 'react'
import { FaBars } from "react-icons/fa";
import logo from "../../assets/images/logo.png";
import styled from "styled-components";
import Menu from "./Menu";
import { Image } from "react-bootstrap";
import { useState } from "react";
import PageBar from './PageBar';
import { useContext } from 'react';
import { UserContext } from '../providers/UserProvider';
import { PageNotFoundError, PermissionDeniedError } from '../../errors';
import { useHistory } from 'react-router';



const AdminPage = ({ error, children, breadcrumbs }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const userData = useContext(UserContext);
  const history = useHistory();

  const logout = () => {
    userData.remove();
    setTimeout(() => {
      history.push("/");
    }, 100);
  }

  return (
    <>
      <TopBar>
        <MenuButton onClick={() => setMenuOpen(true)}><FaBars></FaBars></MenuButton>
        <div className="flex-grow-1 d-flex justify-content-center"><Logo src={logo} height={35} /></div>
      </TopBar>
      <div className="d-flex min-vh-100">
        <SidebarMenu onLogout={logout}></SidebarMenu>
        <Content>
          <PageBar breadcrumbs={breadcrumbs}></PageBar>
          {error == null && <div>{children}</div>}
          {(error instanceof PermissionDeniedError) && <div className="flex-grow-1 p-3 alert alert-danger text-center">Permission Denied</div>}
          {(error instanceof PageNotFoundError) && <div className="flex-grow-1 p-3 alert alert-danger text-center">Sorry. The page does not exist.</div>}
        </Content>
      </div>
      {menuOpen && <HamburgerMenu onLogout={logout} onClose={() => setMenuOpen(false)}></HamburgerMenu>}
    </>
  )
}

const TopBar = styled.div`
  display:flex;
  height:50px;
  background:#0b389c;
  align-items:center;
  position:fixed;
  width:100%;
  z-index:998;
  @media screen and (min-width:769px){
    display:none;
  }
`;

const MenuButton = styled.button.attrs((prop) => ({ className: "btn btn-transparent" }))`
  width: 50px;
  height: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
  color: white;

  &:hover{
    color:grey;
  }
`;

const SidebarMenu = styled(Menu)`
  min-width:250px;

  @media screen and (max-width:769px){
    display:none;
  }
`;

const HamburgerMenu = styled(Menu)`
  position:fixed;
  top: 0;
  left: 0;
  width:250px;
  bottom: 0;
  z-index: 999;
  @media screen and (min-width:769px){
    display:none;
  }
`;

const Logo = styled(Image)`
  align-self:center;
  justify-self:center;
  margin-left:-50px;
`;

const Content = styled.div`
  background:#f5f5f5;
  flex-grow:1;
  padding:1rem 2rem;
  @media screen and (max-width:769px){
    margin-top:50px;
    padding:1rem;
  }
`;

export default AdminPage
