import React, { useContext, useEffect, useRef, useState } from 'react'
import { FaPlusCircle } from 'react-icons/fa';
import { Link, useHistory } from 'react-router-dom';
import { createGetIsAllSchoolsArchived, createGetWorkshops } from '../../api/api';
import { USER_ROLE_ADMIN, USER_ROLE_SCHOOL_ADMIN, USER_ROLE_TEACHER, USER_ROLE_TENDER_ADMIN } from '../../constants';
import AdminPage from '../common/AdminPage'
import Icon from '../common/Icon';
import { Loading } from '../common/Loading';
import PageBox from '../common/PageBox';
import Pagination from '../common/Pagination';
import { Row } from '../common/Row';
import SearchField from '../common/SearchField';
import { UserContext } from '../providers/UserProvider';
import WorkshopList from '../sections/workshop/WorkshopList';

const Workshops = () => {

    const [search, setSearch] = useState("");
    const [error, setError] = useState(null);
    const [page, setPage] = useState(0);
    const [sort, setSort] = useState(1);
    const [allSchoolsArchived, setAllSchoolsArchived] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const request = useRef();
    const [workshops, setWorkshops] = useState([]);
    const [totalCount, setTotalCount] = useState();
    const [countPerPage, setCountPerPage] = useState();
    const history = useHistory();
    const { role } = useContext(UserContext);


    const canAdd = () => {
        return (role === USER_ROLE_ADMIN || role === USER_ROLE_TENDER_ADMIN || role === USER_ROLE_SCHOOL_ADMIN || role === USER_ROLE_TEACHER) && !allSchoolsArchived;
    }


    useEffect(() => {
        let isMounted = true;
        async function loadWorkshops() {
            setIsLoading(true);
            request?.current?.cancel();
            const { request: c, f } = createGetWorkshops(role);
            request.current = c;

            const r = await f(search, page, sort);
            setIsLoading(false);
            if (!isMounted) {
                return;
            }
            if (r instanceof Error) {
                setError(r);
            } else if (r && r.status) {
                let w = r.data.workshops.map(w => {
                    return {
                        ...w,
                        yearGroups: r.data.year_groups.find(y => parseInt(y.workshop_id) === parseInt(w.id))?.year_groups,
                        hasManager: r.data.managers?.indexOf(w.id) >= 0,
                        hasData: r.data.workshop_ids_with_data?.indexOf(w.id) >= 0
                    };
                });
                setWorkshops(w);
                setTotalCount(r.data.total);
                setCountPerPage(r.data.count_per_page);
            }
        }
        loadWorkshops();
        return () => {
            isMounted = false;
            request?.current?.cancel();
        }
    }, [search, page, sort, role]);

    useEffect(() => {
        async function getIsAllSchoolsArchived() {
            const { f } = createGetIsAllSchoolsArchived();
            const r = await f();
            if (r instanceof Error) {

            } else if (r && r.status) {
                setAllSchoolsArchived(r.data.all_archived);
            }
        }
        getIsAllSchoolsArchived();
    }, []);

    useEffect(() => {
        setPage(0);
    }, [search, sort])

    const openWorkshop = (id) => {
        history.push(`/workshop/${id}`);
    }

    return (
        <AdminPage error={error} breadcrumbs={[
            { title: "Home", path: "/" },
            { title: "Workshops" }
        ]}>
            <div className="d-flex align-items-md-center justify-content-start mb-2 flex-column flex-sm-row ">
                {canAdd() && <Link to="/workshop/add" className="btn btn-apple btn-has-icon mr-sm-2">
                    <Icon><FaPlusCircle /></Icon>Add Workshop
                </Link>
                }
            </div>
            <SearchField className="mb-2" placeholder="Find Workshops" value={search} onChange={(value) => setSearch(value)}></SearchField>

            <PageBox>

                <Row className="p-4">
                    <h4><strong>Workshops</strong></h4>
                    <select name="sort" className="small custom-select right" value={sort} onChange={e => setSort(e.target.value)}>
                        <option value="0">Oldest First</option>
                        <option value="1">Most Recent</option>
                        <option value="2">Alphabetical A-Z</option>
                        <option value="3">Alphabetical Z-A</option>
                        <option value="4">Archived</option>
                        <option value="5">Unarchived</option>
                    </select>
                    <div className="footer text-muted small"><strong>Name</strong></div>
                </Row>

                {isLoading &&
                    <div className="p-3">
                        <Loading></Loading>
                    </div>
                }

                {!isLoading && <WorkshopList workshops={workshops} onClick={openWorkshop}></WorkshopList>}

                <Row className="p-4">
                    <div className="d-flex align-items-center justify-content-end">
                        <Pagination countPerPage={countPerPage} page={page} totalCount={totalCount} setPage={setPage}></Pagination>
                    </div>
                </Row>

            </PageBox>

        </AdminPage >
    )
}

export default Workshops