import React, { useContext, useEffect, useRef, useState } from 'react'
import { createGetUsersRequest, createGetSchools, createGetWorkshops } from '../../api/api'
import AdminPage from '../common/AdminPage'
import { Loading } from '../common/Loading'
import PageBox from '../common/PageBox'
import { UserContext } from '../providers/UserProvider'
import { useHistory } from 'react-router'
import WorkshopList from '../sections/workshop/WorkshopList'
import { USER_ROLE_ADMIN, USER_ROLE_TENDER_ADMIN, USER_ROLE_SCHOOL_ADMIN, USER_ROLE_TENDER_FACILITATOR } from '../../constants'
import SchoolList from '../sections/school/SchoolList'
import UserList from '../sections/user/UserList'
import TrainingItemList from '../sections/training/TrainingItemList'

const Dashboard = () => {

    const [isLoadingWorkshops, setIsLoadingWorkshops] = useState(true);
    const [isLoadingSchools, setIsLoadingSchools] = useState(true);
    const [isLoadingUsers, setIsLoadingUsers] = useState(true);
    const [workshopError, setWorkshopError] = useState(null);
    const [schoolsError, setSchoolsError] = useState(null);
    const [usersError, setUsersError] = useState(null);
    const [workshops, setWorkshops] = useState([]);
    const [schools, setSchools] = useState([]);
    const [userSchools, setUserSchools] = useState([]);
    const [userSchoolIds, setUserSchoolIds] = useState([]);
    const [users, setUsers] = useState([]);
    const workshopReq = useRef();
    const schoolsReq = useRef();
    const usersReq = useRef();
    const { role } = useContext(UserContext);
    const history = useHistory();


    const canShowSchools = () => {
        return role === USER_ROLE_ADMIN || role === USER_ROLE_TENDER_ADMIN;
    }

    const canShowUsers = () => {
        return role === USER_ROLE_ADMIN || role === USER_ROLE_TENDER_ADMIN || role === USER_ROLE_SCHOOL_ADMIN;
    }

    const openWorkshop = (id) => {
        history.push(`/workshop/${id}`);
    }
    const editSchool = (schoolId) => {
        history.push(`/school/${schoolId}`);
    }

    const editUser = (userId) => {
        history.push(`/user/${userId}`);
    }

    const getUserSchools = () => {
        const r = userSchoolIds?.map(u => ({ id: u.user_id, schools: userSchools?.filter(s => parseInt(s.id) === parseInt(u.school_id)) }));
        return r;
    }


    useEffect(() => {
        let isMounted = true;
        async function loadWorkshops() {
            setIsLoadingWorkshops(true);
            workshopReq?.current?.cancel();

            const { request, f } = createGetWorkshops(role);
            workshopReq.current = request;

            const r = await f("", 0, 1);
            setIsLoadingWorkshops(false);
            if (!isMounted) {
                return;
            }
            if (r instanceof Error) {
                setWorkshopError(r);
                return;
            } else if (r && r.status) {
                let w = r.data.workshops.slice(0, 5).map(w => {
                    return {
                        ...w,
                        yearGroups: r.data.year_groups.find(y => parseInt(y.workshop_id) === parseInt(w.id))?.year_groups,
                        hasManager: r.data.managers?.indexOf(w.id) >= 0,
                        hasData: r.data.workshop_ids_with_data?.indexOf(w.id) >= 0
                    };
                });
                setWorkshops(w);
            }
        }
        loadWorkshops();
        return () => {
            isMounted = false;
            workshopReq?.current?.cancel();
        }
    }, [role])


    useEffect(() => {
        let isMounted = true;
        async function loadSchools() {
            setIsLoadingSchools(true);
            schoolsReq?.current?.cancel();
            const { request, f } = createGetSchools(role);
            schoolsReq.current = request;

            const r = await f("", 0, 1);
            setIsLoadingSchools(false);
            if (!isMounted) {
                return;
            }
            if (r instanceof Error) {
                setSchoolsError(r);
                return;
            } else if (r && r.status) {
                let schools = r.data.schools.map(s => ({ ...s, close_requested: r.data.close_requested.indexOf(s.id) !== -1 })).slice(0, 5);
                setSchools(schools);
            }
        }
        loadSchools();
        return () => {
            isMounted = false;
            schoolsReq?.current?.cancel();
        }
    }, [role])


    useEffect(() => {
        let isMounted = true;
        async function loadUsers() {
            setIsLoadingUsers(true);
            usersReq?.current?.cancel();
            const { request, f } = createGetUsersRequest();
            usersReq.current = request;

            const r = await f("", 0, 1, null, role !== USER_ROLE_ADMIN && role !== USER_ROLE_TENDER_ADMIN);
            setIsLoadingUsers(false);
            if (!isMounted) {
                return;
            }
            if (r instanceof Error) {
                setUsersError(r);
                return;
            } else if (r && r.status) {
                setUsers(r.data.users.slice(0, 5)?.map(u => {
                    const usid = r.data.school_ids?.find(s => s.user_id === u.id);
                    const s = r.data.schools?.find(s => s.id === usid?.school_id);
                    return { ...u, school_archived: s?.archived };
                }));
                setUserSchools(r.data.schools);
                setUserSchoolIds(r.data.school_ids);
            }
        }
        loadUsers();
        return () => {
            isMounted = false;
            usersReq?.current?.cancel();
        }
    }, [role])


    return (
        <AdminPage breadcrumbs={[{ title: "Home" }]} >

            <h4 className="title mb-4"><strong>Training & Resources</strong></h4>
            <TrainingItemList count="5" />

            {
                !workshopError && <PageBox className=" my-2">
                    <h5 className="title p-4"><strong>
                        {role === USER_ROLE_TENDER_FACILITATOR ? "My Workshops" : "Recently Added Workshops"}
                    </strong></h5>
                    {isLoadingWorkshops && <div className="p-3 d-flex align-items-center justify-content-center"><Loading /></div>}
                    {!isLoadingWorkshops && <WorkshopList workshops={workshops} onClick={openWorkshop}></WorkshopList>}
                </PageBox>
            }

            {
                canShowSchools() && !schoolsError && <PageBox className="my-2">
                    <h5 className="title p-4"><strong>Recently Added Schools</strong></h5>
                    {isLoadingSchools && <div className="p-3 d-flex align-items-center justify-content-center"><Loading /></div>}
                    {!isLoadingSchools && <SchoolList schools={schools} onClick={editSchool}></SchoolList>}
                </PageBox>
            }


            {
                canShowUsers() && !usersError && <PageBox className="my-2">
                    <h5 className="title p-4"><strong>Recently Added Users</strong></h5>
                    {isLoadingUsers && <div className="p-3 d-flex align-items-center justify-content-center"><Loading /></div>}
                    {!isLoadingUsers && <UserList userSchools={getUserSchools()} users={users} onClick={editUser}></UserList>}
                    {!isLoadingUsers && !usersError && users.length === 0 && <span>No new users found.</span>}
                </PageBox>
            }
        </AdminPage >


    )
}

export default Dashboard
