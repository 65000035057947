import React from 'react'
import { FaSearch } from 'react-icons/fa'
import Icon from './Icon'

const SearchField = ({ className, placeholder, value, onChange }) => {
    return (
        <div className={`${className} form-group`}>
            <div className="input-group">
                <div className="input-group-prepend">
                    <div className="input-group-text pr-1"><Icon fill="--icon-dull-color"><FaSearch /></Icon></div>
                </div>
                <input type="text" className="form-control" id="inlineFormInputGroup" placeholder={placeholder} value={value} onChange={(e) => { onChange(e.target.value) }} onKeyUp={(e) => { onChange(e.target.value) }} />
            </div>
        </div>
    )
}


export default SearchField
