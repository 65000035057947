import React from 'react'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import Icon from './Icon'

const Pagination = ({ countPerPage, page, totalCount, setPage }) => {
    return (<>
        { countPerPage && <span className="small text-muted">
            {(page * countPerPage) + 1}-{Math.min(totalCount, (page * countPerPage) + countPerPage)} of {totalCount}
        </span>}
        <button onClick={() => setPage(page - 1)} disabled={page <= 0} className="btn btn-outline btn-has-icon"><Icon noMargin fill="--icon-dull-color"><FaChevronLeft /></Icon></button>
        <button onClick={() => setPage(page + 1)} disabled={(page + 1) * countPerPage >= totalCount} className="btn btn-outline btn-has-icon"><Icon noMargin fill="--icon-dull-color"><FaChevronRight /></Icon></button>
    </>
    )
}

export default Pagination
