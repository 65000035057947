import axios from "axios";
import { PageNotFoundError, PermissionDeniedError, ServerError, UnauthorizedError } from "../errors";
import { USER_ROLE_ADMIN, USER_ROLE_SCHOOL_ADMIN, USER_ROLE_TEACHER, USER_ROLE_TENDER_ADMIN, USER_ROLE_TENDER_FACILITATOR } from "../constants";


axios.defaults.withCredentials = true;
let serverUrl = "";
if (window.location.href.includes("localhost")) {
    serverUrl = "http://localhost/projects/rel-goals";
    axios.defaults.params = {
        "_client": "1"
    }
}

export const getVersion = async () => {
    try {
        const response = await axios.get(serverUrl + "/admin/version");
        if (response && response.data) {
            return response.data;
        }
        return null;
    } catch (e) {
        return null;
    }
}

export const checkIfLoggedIn = async () => {
    try {
        const response = await axios.get(serverUrl + "/admin/me");
        if (response && response.data.status) {
            return response.data;
        }
        return null;
    } catch (e) {
        return null;
    }
}

export const login = async (email, password) => {
    try {
        const response = await axios.post(serverUrl + "/admin/login", {
            username: email, password
        });
        if (response && response.data.status) {
            return response.data;
        }
        return null;
    } catch (e) {
        return null;
    }
}

export const logout = async () => {
    try {
        const response = await axios.get(serverUrl + "/admin/logout");
        if (response && response.data.status) {
            return response.data;
        }
        return null;
    } catch (e) {
        return null;
    }
}

export const forgotPasswordSubmitEmail = async (email) => {
    try {
        const response = await axios.post(serverUrl + "/admin/forgot_password/submit_email", {
            email
        });
        if (response) {
            return response.data;
        }
        return null;
    } catch (e) {
        return null;
    }
}

export const forgotPasswordSubmitCode = async (code) => {
    try {
        const response = await axios.post(serverUrl + "/admin/forgot_password/submit_code", {
            code
        });
        if (response) {
            return response.data;
        }
        return null;
    } catch (e) {
        return null;
    }
}

export const forgotPasswordSubmitPassword = async (userId, code, password) => {
    try {
        const response = await axios.post(serverUrl + "/admin/forgot_password/submit_password", {
            user_id: userId,
            code,
            password
        });
        if (response) {
            return response.data;
        }
        return null;
    } catch (e) {
        return null;
    }
}

const createGetAllSchoolsRequest = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (search, page, sort) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/schools",
                    method: "get",
                    params: {
                        query: search,
                        page,
                        sort
                    },
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }
                return createError(e?.response?.status || 500);
            }
        }
    }
}
const createGetLinkedSchoolsRequest = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (search, page, sort) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/schools_linked",
                    method: "get",
                    params: {
                        query: search,
                        page,
                        sort
                    },
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }
                return createError(e?.response?.status || 500);
            }
        }
    }
}
const createGetSchoolsLinkedByWorkshopRequest = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (search, page, sort) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/schools_linked_by_workshop",
                    method: "get",
                    params: {
                        query: search,
                        page,
                        sort
                    },
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }
                return createError(e?.response?.status || 500);
            }
        }
    }
}


export const createGetSchoolRequest = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (id) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/school",
                    method: "get",
                    params: {
                        id
                    },
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                console.log(response);
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }
                return createError(e?.response?.status || 500);
            }
        }
    }
}

export const createDeleteSchoolRequest = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (id) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/school/delete",
                    method: "get",
                    params: {
                        id
                    },
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                console.log(response);
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }
                return createError(e?.response?.status || 500);
            }
        }
    }
}

export const createCloseSchoolRequest = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (id, reason) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/school/close",
                    method: "post",
                    data: {
                        id, reason
                    },
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }
                return createError(e?.response?.status || 500);
            }
        }
    }
}

export const createGetSchoolUsersRequest = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (id) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/school/users",
                    method: "get",
                    params: {
                        id
                    },
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }
                return createError(e?.response?.status || 500);
            }
        }
    }
}

export const createGetIsAllSchoolsArchived = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async () => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/schools/is_all_schools_archived",
                    method: "get",
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }
                return createError(e?.response?.status || 500);
            }
        }
    }
}


export const createUpdateSchoolRequest = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (id, name, address, city, postcode, phone, isPrimary, isTesting) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/school/update",
                    method: "post",
                    data: {
                        id, name, address, city, postcode, phone,
                        is_primary: isPrimary,
                        is_testing: isTesting
                    },
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }
                return createError(e?.response?.status || 500);
            }
        }
    }
}

export const createArchiveSchoolRequest = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (id, archived) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/school/archive",
                    method: "get",
                    params: {
                        id, archived
                    },
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }
                return createError(e?.response?.status || 500);
            }
        }
    }
}


export const createAddSchoolRequest = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (name, address, city, postcode, phone, isPrimary, isTesting) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/school/add",
                    method: "post",
                    data: {
                        name, address, city, postcode, phone,
                        is_primary: isPrimary,
                        is_testing: isTesting
                    },
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }
                return createError(e?.response?.status || 500);
            }
        }
    }
}


export const createGetUsersRequest = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (search, page, sort, roles, activeOnly) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/users",
                    method: "get",
                    params: {
                        query: search,
                        page,
                        sort,
                        roles,
                        active_only: activeOnly
                    },
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }

                return createError(e?.response?.status || 500);
            }
        }
    }
}


export const createGetUserRequest = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (id) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/user",
                    method: "get",
                    params: {
                        id
                    },
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                console.log(response);
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }
                return createError(e?.response?.status || 500);
            }
        }
    }
}

export const createAddUserRequest = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (fname, lname, role, email, school_ids) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/user/add",
                    method: "post",
                    data: {
                        fname, lname, role, email, school_ids
                    },
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }
                return createError(e?.response?.status || 500);
            }
        }
    }
}

export const createActivateUserRequest = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (code, password) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/user/activate",
                    method: "post",
                    data: {
                        code, password
                    },
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }
                return createError(e?.response?.status || 500);
            }
        }
    }
}

export const createUpdateUserRequest = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (id, fname, lname, role, email, school_ids) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/user/update",
                    method: "post",
                    data: {
                        id, fname, lname, role, email, school_ids
                    },
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }
                return createError(e?.response?.status || 500);
            }
        }
    }
}

export const createInviteAgainUserRequest = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (id) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/user/send_invite_code",
                    method: "get",
                    params: {
                        id
                    },
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }
                return createError(e?.response?.status || 500);
            }
        }
    }
}

export const createDeleteUserRequest = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (id) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/user/delete",
                    method: "get",
                    params: {
                        id
                    },
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }
                return createError(e?.response?.status || 500);
            }
        }
    }
}

export const createArchiveUserRequest = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (id, archived) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/user/archive",
                    method: "get",
                    params: {
                        id,
                        archived
                    },
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }
                return createError(e?.response?.status || 500);
            }
        }
    }
}

export const createGetTrainingItemsRequest = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async () => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/training",
                    method: "get",
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }

                return createError(e?.response?.status || 500);
            }
        }
    }
}

export const createGetTrainingItemRequest = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (name) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/training_item",
                    method: "get",
                    params: {
                        name
                    },
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }

                return createError(e?.response?.status || 500);
            }
        }
    }
}

const createGetAllWorkshopsRequest = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (search, page, sort) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/workshops",
                    method: "get",
                    params: {
                        search, page, sort
                    },
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }

                return createError(e?.response?.status || 500);
            }
        }
    }
}

const createGetWorkshopsForMySchoolsRequest = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (search, page, sort) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/workshops_my_schools",
                    method: "get",
                    params: {
                        search, page, sort
                    },
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }

                return createError(e?.response?.status || 500);
            }
        }
    }
}

const createGetLinkedWorkshopsRequest = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (search, page, sort) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/workshops_linked",
                    method: "get",
                    params: {
                        search, page, sort
                    },
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }

                return createError(e?.response?.status || 500);
            }
        }
    }
}

export const createGetUserLinkedWorkshopsRequest = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (user_id, search, page, sort, common_only) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/workshops_linked_to_user",
                    method: "get",
                    params: {
                        user_id, search, page, sort, common_only
                    },
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }

                return createError(e?.response?.status || 500);
            }
        }
    }
}

export const createGetChaptersRequest = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async () => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/chapters",
                    method: "get",
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }

                return createError(e?.response?.status || 500);
            }
        }
    }
}

export const createGetWorkshopRequest = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (id) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/workshop",
                    method: "get",
                    params: {
                        id
                    },
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }

                return createError(e?.response?.status || 500);
            }
        }
    }
}

export const createAddWorkshopRequest = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (name, school_id, is_dating, send, year_groups, chapters, students, manager_ids, auto_unlock) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/workshop/add",
                    method: "post",
                    data: {
                        name, school_id, is_dating, send, year_groups, chapters, students, manager_ids, auto_unlock
                    },
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }
                return createError(e?.response?.status || 500);
            }
        }
    }
}


export const createCheckStudentNamesRequest = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (students) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/workshop/existing_students",
                    method: "post",
                    data: {
                        students
                    },
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }
                return createError(e?.response?.status || 500);
            }
        }
    }
}

export const createUpdateWorkshopRequest = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (id, name, school_id, is_dating, send, year_groups, chapters, students, manager_ids, auto_unlock) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/workshop/update",
                    method: "post",
                    data: {
                        id, name, school_id, is_dating, send, year_groups, chapters, students, manager_ids, auto_unlock
                    },
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }
                return createError(e?.response?.status || 500);
            }
        }
    }
}

export const createArchiveWorkshopRequest = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (workshopId, archive) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/workshop/archive",
                    method: "get",
                    params: {
                        id: workshopId,
                        archive
                    },
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }

                return createError(e?.response?.status || 500);
            }
        }
    }
}

export const createDeleteWorkshopRequest = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (workshopId) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/workshop/delete",
                    method: "get",
                    params: {
                        id: workshopId
                    },
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }

                return createError(e?.response?.status || 500);
            }
        }
    }
}

export const createDeleteStudentRequest = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (studentId, workshopId) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/workshop/delete_student",
                    method: "get",
                    params: {
                        sid: studentId,
                        wid: workshopId
                    },
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }

                return createError(e?.response?.status || 500);
            }
        }
    }
}

export const createGetExportContent = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (chapterId) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/export_script",
                    method: "get",
                    params: {
                        chapter_id: chapterId
                    },
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }

                return createError(e?.response?.status || 500);
            }
        }
    }
}

export const createGetWorkshopAnalytics = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (id) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/workshop_analytics",
                    method: "get",
                    params: {
                        id: id
                    },
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }

                return createError(e?.response?.status || 500);
            }
        }
    }
}

export const createGetSchoolAnalytics = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (id) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/school_analytics",
                    method: "get",
                    params: {
                        id: id
                    },
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }

                return createError(e?.response?.status || 500);
            }
        }
    }
}

export const createGetSchoolLastLogins = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (id, from) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/school_last_logins",
                    method: "get",
                    params: {
                        id,
                        from
                    },
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }

                return createError(e?.response?.status || 500);
            }
        }
    }
}


export const createGetSchoolDeviceDetails = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (id) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/school_device_details",
                    method: "get",
                    params: {
                        id
                    },
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }

                return createError(e?.response?.status || 500);
            }
        }
    }
}

export const createGetWorkshopDeviceDetails = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (id) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/workshop_device_details",
                    method: "get",
                    params: {
                        id
                    },
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }

                return createError(e?.response?.status || 500);
            }
        }
    }
}


export const createGetAllSchoolAnalytics = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (id) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/all_schools_analytics",
                    method: "get",
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }

                return createError(e?.response?.status || 500);
            }
        }
    }
}

export const createGetStudentReport = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (id) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/student_report",
                    method: "get",
                    params: {
                        id: id
                    },
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }

                return createError(e?.response?.status || 500);
            }
        }
    }
}

export const createGetSchoolReport = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (id, sends, year_groups) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/school_report",
                    method: "get",
                    params: {
                        id: id,
                        sends: sends.join(","),
                        year_groups: year_groups.join(",")
                    },
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }

                return createError(e?.response?.status || 500);
            }
        }
    }
}

export const createGetAllSchoolsReport = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (sends, year_groups) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/all_schools_report",
                    method: "get",
                    params: {
                        sends: sends.join(","),
                        year_groups: year_groups.join(",")
                    },
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }

                return createError(e?.response?.status || 500);
            }
        }
    }
}

export const createGetWorkshopReport = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (id) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/workshop_report",
                    method: "get",
                    params: {
                        id: id
                    },
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }

                return createError(e?.response?.status || 500);
            }
        }
    }
}



export const createGetGlobalSettingsRequest = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (id) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/global_settings",
                    method: "get",
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                console.log(response);
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }
                return createError(e?.response?.status || 500);
            }
        }
    }
}

export const createSaveGlobalSettingsRequest = () => {
    const request = axios.CancelToken.source();
    return {
        request: request,
        f: async (settings) => {
            try {
                const response = await axios({
                    url: serverUrl + "/admin/global_settings",
                    method: "post",
                    data: { settings },
                    cancelToken: request.token
                });
                if (response) {
                    return response.data;
                }
                return null;
            } catch (e) {
                if (axios.isCancel(e)) {
                    return null;
                }
                return createError(e?.response?.status || 500);
            }
        }
    }
}

const createError = (code) => {
    const o = {
        401: new UnauthorizedError(),
        403: new PermissionDeniedError(),
        404: new PageNotFoundError(),
        500: new ServerError(),
    }
    if (code === 401) {
        logout();
        localStorage.removeItem("user");
        window.location.reload();
    }
    return o[code] || new Error();
}


export const createGetSchools = (role) => {
    let apims = {
        [USER_ROLE_ADMIN]: createGetAllSchoolsRequest,
        [USER_ROLE_TENDER_ADMIN]: createGetAllSchoolsRequest,
        [USER_ROLE_SCHOOL_ADMIN]: createGetLinkedSchoolsRequest,
        [USER_ROLE_TEACHER]: createGetLinkedSchoolsRequest,
        [USER_ROLE_TENDER_FACILITATOR]: createGetSchoolsLinkedByWorkshopRequest
    };
    return apims[role]();
}

export const createGetWorkshops = (role) => {
    let apims = {
        [USER_ROLE_ADMIN]: createGetAllWorkshopsRequest,
        [USER_ROLE_TENDER_ADMIN]: createGetAllWorkshopsRequest,
        [USER_ROLE_SCHOOL_ADMIN]: createGetWorkshopsForMySchoolsRequest,
        [USER_ROLE_TEACHER]: createGetWorkshopsForMySchoolsRequest,
        [USER_ROLE_TENDER_FACILITATOR]: createGetLinkedWorkshopsRequest
    };
    return apims[role]();
}