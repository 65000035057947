import React from 'react'
import styled from 'styled-components'

const ModalDialog = ({ title, children, onClose, callbacks }) => {
    return (
        <Underlay tabindex="-1">
            <ModalBox>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{title}</h5>
                        {onClose && <button onClick={onClose} type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>}
                    </div>
                    <div className="modal-body">
                        {children}
                    </div>
                    {callbacks && <div className="modal-footer">
                        {callbacks.map(c => <button key={c.text} onClick={c.onClick} data-test-id={c.testId ? c.testId : ""} type="button" className={`btn ${c.className}`}>{c.text}</button>)}
                    </div>}
                </div>
            </ModalBox>
        </Underlay>
    )
}

export default ModalDialog

const Underlay = styled.div.attrs((props) => ({ className: "modal" }))`
    display:block;
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background:rgba(0,0,0,0.7);
    z-index:9999;
`;

const ModalBox = styled.div.attrs((props) => ({ className: "modal-dialog" }))`
    display:block;
    position:absolute;
    top:1rem;
    left:calc(250px + (100vw - 250px)/2);
    transform:translate(-50%,0);
    z-index:10000;
    width:50%;

    .modal-body{
        max-height:70vh;
        overflow:auto;
    }

    @media screen and (max-width:760px){
        left:50%;
        width:90%;
        margin:0;
    }
`
