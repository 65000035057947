import React, { useState } from 'react'
import { FaCheckCircle, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import Icon from './Icon';

const LearningOutcomeChapterRowCheck = ({
    chapterTitle,
    learningOutcomes
}) => {

    const [expanded, setExpanded] = useState(false);

    const countDisplay = () => {
        return learningOutcomes.reduce((a, b) => parseInt(a) + parseInt(b.count), 0) + "/" + learningOutcomes.length;
    }

    return (
        <>

            {learningOutcomes && <div onClick={() => setExpanded(!expanded)} className="background-row-hover-light border-top small row d-flex align-items-center justify-content-around background-row-light py-3">
                <div className="col-8 col-lg-7 d-flex align-items-center">
                    <Icon fill="--icon-primary-color" noMargin> {expanded ? <FaChevronUp></FaChevronUp> : <FaChevronDown></FaChevronDown>}</Icon>
                    <strong className="ml-1">{chapterTitle}</strong>
                </div>
                <div className="col-4 col-lg-5 text-right">{countDisplay()}</div>
            </div>}

            {!learningOutcomes && <div className="small row d-flex align-items-center justify-content-around background-row-light py-3">
                <div className="col-1 d-flex align-items-center justify-content-end mr-0">
                    <Icon fill="--icon-dull-color" noMargin> <FaChevronDown></FaChevronDown></Icon>
                </div>
                <div className="col-6 text-muted"><strong>{chapterTitle}</strong></div>
                <div className="col-5 text-right"></div>
            </div>}

            {expanded && learningOutcomes && learningOutcomes.map((l, i) => <div key={i} className="small row align-items-center justify-content-around background-row-lighter py-3">
                <div className="col-5 m-0 p-0 ml-2">{l.title}</div>
                <div className="col-5 m-0 p-0 d-flex align-item-center justify-content-end">
                    {l.count > 0 && <Icon fill="--icon-primary-color" noMargin><FaCheckCircle></FaCheckCircle></Icon>}
                </div>
            </div>)}
        </>
    )
}

export default LearningOutcomeChapterRowCheck
