import React from 'react'
import { UserConsumer } from "../providers/UserProvider";
import { FaChevronRight, FaGamepad, FaHome } from "react-icons/fa";
import { GiTeacher } from "react-icons/gi";
import Icon from './Icon';
import { Link } from 'react-router-dom';

const PageBar = ({ breadcrumbs }) => {

    const gameLink = () => {
        let url = "https://game.relationshipgoals.uk/";
        if (window.location.href.indexOf("stagingserver") >= 0) {
            url = "https://staginggame.relationshipgoals.uk/";
        } else if (window.location.href.indexOf("shark") >= 0) {
            url = "https://rel-goals-client-shark-eu.herokuapp.com/";
        }
        return url;
    }


    return (
        <div className="d-flex align-items-center justify-content-between mb-3 flex-column-reverse flex-lg-row">
            <span className="d-flex flex-wrap align-self-stretch justify-content-start align-items-center text-muted small mt-2 mt-lg-0">
                {breadcrumbs && breadcrumbs.map((b, i) => <div key={i}>
                    {i === 0 && <Icon fill="icon-dull-color" className="mx-1"><FaHome></FaHome></Icon>}
                    {i !== 0 && <Icon fill="icon-dull-color" className="mx-1"><FaChevronRight style={{ height: "10px" }}></FaChevronRight></Icon>}
                    {!!b.path && <span><Link className="text-muted" to={b.path}>{b.title}</Link></span>}
                    {!b.path && <span>{b.title}</span>}
                </div>)}
            </span>
            <div className="d-flex align-self-stretch align-self-lg-inherit justify-content-end align-items-center text-muted small">
                <UserConsumer>{user =>
                    <span className="text-muted mr-0 mr-lg-3">Hello <Link to={`/user/${user.id}`} replace>{user.fname}</Link></span>
                }</UserConsumer>
                <div className="d-none d-lg-flex align-items-center">
                    <Icon fill="--icon-dull-color"><FaGamepad /></Icon> <a target="_blank" rel="noreferrer" href={gameLink()}>Play Game</a>
                </div>
                <div className="d-lg-flex align-items-center ml-3">
                    <Icon fill="--icon-dull-color" className="mr-2"><GiTeacher /></Icon> <a target="_blank" rel="noreferrer" href="https://tendertraining.clcmoodle.org/login/signup.php">Training Module</a>
                </div>
            </div>
        </div >
    )
}

export default PageBar
