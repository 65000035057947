import React, { useEffect, useState } from 'react'
const owasp = require("owasp-password-strength-test");

const PasswordStrength = ({ password, className }) => {

    const [pwdStrength, setPwdStrength] = useState(null);

    useEffect(() => {
        const r = owasp.test(password || "");
        if (r.strong) {
            setPwdStrength({ text: "Strong", bgColor: "#8cd36d", textColor: "#000" });
        } else if (r.requiredTestErrors.length === 0) {
            setPwdStrength({ text: "Medium", bgColor: "#ffc458", textColor: "#000" });
        } else {
            setPwdStrength({ text: "Weak", bgColor: "#ff6164", textColor: "#000" });
        }
    }, [password])


    return pwdStrength && <div style={{ backgroundColor: pwdStrength.bgColor, color: pwdStrength.textColor }} className={className}>{pwdStrength.text}</div>
}

export default PasswordStrength
