import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { checkIfLoggedIn, logout } from '../../api/api';


export const UserContext = React.createContext();


export const UserProvider = ({ children }) => {

    const userDataDefault = {
        isLoading: false,
        id: null,
        fname: null,
        lname: null,
        role: null,
        permissions: null,
        update: (data) => {
            setUserData(prev => {
                return { ...prev, ...data };
            });
        },
        remove: async () => {
            await logout();
            setUserData(prev => {
                return { ...prev, id: null, fname: null, lname: null, role: null, permissions: null };
            });
        }
    };

    const [userData, setUserData] = useState(userDataDefault);

    useEffect(() => {
        if (!localStorage.getItem("user")) {
            setUserData(prev => {
                return { ...prev, isLoading: true };
            });
            async function isLoggedIn() {
                const data = await checkIfLoggedIn();
                if (data) {
                    userData.update(data.data);
                }
                setUserData(prev => {
                    return { ...prev, isLoading: false };
                });
            }
            isLoggedIn();
        } else {
            userData.update({ ...JSON.parse(localStorage.getItem("user")) });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (userData == null || userData.id == null) {
            localStorage.removeItem("user");
        } else {
            localStorage.setItem("user", JSON.stringify(userData));
        }
    }, [userData]);

    return (
        <UserContext.Provider value={userData}>{children}</UserContext.Provider>
    )
}

export const UserConsumer = UserContext.Consumer;