import React, { useContext } from 'react'
import { FaChartLine, FaChartPie, FaGamepad } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { USER_ROLE_ADMIN, USER_ROLE_SCHOOL_ADMIN, USER_ROLE_TEACHER, USER_ROLE_TENDER_ADMIN, USER_ROLE_TENDER_FACILITATOR } from '../../../constants';
import { formatDate } from '../../../utils';
import Icon from '../../common/Icon';
import { Row } from '../../common/Row';
import { UserContext } from '../../providers/UserProvider';

const WorkshopList = ({ workshops, onClick }) => {
    const { role } = useContext(UserContext);

    const showSchool = () => role === USER_ROLE_ADMIN || role === USER_ROLE_TENDER_ADMIN || role === USER_ROLE_TENDER_FACILITATOR;
    const showYearGroups = () => role === USER_ROLE_SCHOOL_ADMIN || role === USER_ROLE_TEACHER;

    const sendText = (s) => {
        if (s === "no") {
            return null;
        } else if (s === "yes") {
            return "Yes";
        } else if (s === "mixed") {
            return "Mixed";
        }
        return null;
    }

    return (
        <>
            {workshops && workshops.map(s =>
                <Row className="p-0" key={s.id} onClick={() => { onClick(s.id) }} data-test-workshopitem>
                    <WorkshopRow>
                        {s.hasManager && <TenderBadge className="badge badge-secondary">Tender</TenderBadge>}
                        <div className="row align-items-center">
                            <div className="col-12 col-md-12 col-lg-5 d-flex align-items-center mb-2 mb-lg-0">
                                <WorkshopIcon className="workshop-icon"></WorkshopIcon>
                                <div className="d-flex flex-column">
                                    <div><strong>{s.name}</strong>{s.archived === 1 && <span className="ml-2 badge badge-danger">Archived</span>}</div>
                                    <div className="small text-muted">{formatDate(s.created_at * 1000)}</div>
                                </div>
                            </div>
                            {showSchool() && <div className="col-12 col-md-12 col-lg-3 d-flex align-items-center mb-2 mb-lg-0">
                                <div className="d-flex flex-column">
                                    <div><strong>{s.school_name}</strong></div>
                                    <div className="small text-muted">{s.school_city}</div>
                                </div>
                            </div>}
                            {showYearGroups() && <div className="col-12 col-md-12 col-lg-3 d-flex align-items-center mb-2 mb-lg-0">
                                <div className="d-flex flex-column">
                                    {s.yearGroups?.length > 0 && <div><strong>Year: {s.yearGroups?.join(", ")}</strong></div>}
                                    {sendText(s.send) && <div className="small text-muted">SEND: {sendText(s.send)}</div>}
                                </div>
                            </div>}
                            <div className="col-12 col-md-12 col-lg-4 d-flex justify-content-around">
                                {s.hasData && <>
                                    <Link onClick={(e) => { e.stopPropagation(); }} to={`/workshop_report/${s.id}`} className="btn btn-primary btn-has-icon flex-grow-1 mr-1" data-test-workshopreportbutton><Icon><FaChartLine></FaChartLine></Icon>Report</Link>
                                    <Link onClick={(e) => { e.stopPropagation(); }} to={`/workshop_analytics/${s.id}`} className="btn btn-outline-primary btn-has-icon flex-grow-1 ml-1" data-test-workshopanalyticsbutton><Icon fill="--icon-dull-color"><FaChartPie /></Icon>Analytics</Link>
                                </>}
                            </div>
                        </div>
                    </WorkshopRow>
                </Row>
            )}
            {(!workshops || workshops.length === 0) && <div className="alert-warning m-2 p-4 text-center border text-dark border-warning">No workshops to show.</div>}
        </>
    )
}

export default WorkshopList

const WorkshopRow = styled.div.attrs((prop) => ({ className: "container-fluid m-0 p-4" }))`
    cursor:pointer;
    &:hover{
        background:#f6fbff;
        .workshop-icon{
            fill:var(--icon-primary-color);
        }
    }
`;

const WorkshopIcon = styled(FaGamepad)`
    fill:var(--icon-dull-color);
    width:50px;
    height:50px;
    margin-right:1rem;
`;

const TenderBadge = styled.span`
    margin-left:65px;
`;



