
import { HashRouter as Router, Route, Switch } from "react-router-dom";

import Login from "./components/pages/Login";
import { UserProvider } from "./components/providers/UserProvider";
import Dashboard from "./components/pages/Dashboard";
import ProtectedRoute from "./components/common/ProtectedRoute";
import Schools from "./components/pages/Schools";
import SchoolProfile from "./components/pages/SchoolProfile";
import Training from "./components/pages/Training";
import AddSchool from "./components/pages/AddSchool";
import Users from "./components/pages/Users";
import InviteUser from "./components/pages/InviteUser";
import UserProfile from "./components/pages/UserProfile";
import TrainingItem from "./components/pages/TrainingItem";
import Workshops from "./components/pages/Workshops";
import AddWorkshop from "./components/pages/AddWorkshop";
import Workshop from "./components/pages/Workshop";
import ExportScript from "./components/pages/ExportScript";
import WorkshopAnalytics from "./components/pages/WorkshopAnalytics";
import WorkshopReport from "./components/pages/WorkshopReport";
import SchoolReport from "./components/pages/SchoolReport";
import StudentReport from "./components/pages/StudentReport";
import UserActivate from "./components/pages/UserActivate";
import { ServerParamsProvider } from "./components/providers/ServerParamsProvider";
import AllSchoolsReport from "./components/pages/AllSchoolsReport";
import SchoolAnalytics from "./components/pages/SchoolAnalytics";
import AllSchoolAnalytics from "./components/pages/AllSchoolsAnalytics";
import MySchool from "./components/pages/MySchool";
import GlobalSettings from "./components/pages/GlobalSettings";

function App() {



  return (
    <ServerParamsProvider>
      <UserProvider>
        <Router>
          <Switch>
            <Route exact path="/login"><Login /></Route>
            <Route exact path="/user/activate" component={UserActivate}></Route>
            <ProtectedRoute exact path="/" component={Dashboard}></ProtectedRoute>
            <ProtectedRoute exact path="/schools" component={Schools}></ProtectedRoute>
            <ProtectedRoute exact path="/my_school" component={MySchool}></ProtectedRoute>
            <ProtectedRoute exact path="/school/add" component={AddSchool}></ProtectedRoute>
            <ProtectedRoute exact path="/school/:id" component={SchoolProfile}></ProtectedRoute>
            <ProtectedRoute exact path="/users" component={Users}></ProtectedRoute>
            <ProtectedRoute exact path="/user/invite" component={InviteUser}></ProtectedRoute>
            <ProtectedRoute exact path="/user/:id" component={UserProfile}></ProtectedRoute>
            <ProtectedRoute exact path="/training" component={Training}></ProtectedRoute>
            <ProtectedRoute exact path="/training/:name" component={TrainingItem}></ProtectedRoute>
            <ProtectedRoute exact path="/workshops" component={Workshops}></ProtectedRoute>
            <ProtectedRoute exact path="/workshop/add" component={AddWorkshop}></ProtectedRoute>
            <ProtectedRoute exact path="/workshop/:id" component={Workshop}></ProtectedRoute>
            <ProtectedRoute exact path="/export_scripts" component={ExportScript}></ProtectedRoute>
            <ProtectedRoute exact path="/workshop_analytics/:id" component={WorkshopAnalytics}></ProtectedRoute>
            <ProtectedRoute exact path="/school_analytics/:id" component={SchoolAnalytics}></ProtectedRoute>
            <ProtectedRoute exact path="/global_school_analytics" component={AllSchoolAnalytics}></ProtectedRoute>
            <ProtectedRoute exact path="/workshop_report/:id" component={WorkshopReport}></ProtectedRoute>
            <ProtectedRoute exact path="/school_report/:id" component={SchoolReport}></ProtectedRoute>
            <ProtectedRoute exact path="/student_report/:id" component={StudentReport}></ProtectedRoute>
            <ProtectedRoute exact path="/global_school_report" component={AllSchoolsReport}></ProtectedRoute>
            <ProtectedRoute exact path="/global_settings" component={GlobalSettings}></ProtectedRoute>
          </Switch>
        </Router>
      </UserProvider>
    </ServerParamsProvider>
  );
}



export default App;
