import React from 'react'
import styled from "styled-components"

const Icon = ({ className, children, fill, noMargin }) => {
    return (
        <CIcon noMargin={noMargin} fill={fill} className={className}>{children}</CIcon>
    )
}

const CIcon = styled.div`
    display:inline-flex;
    align-items:center;
    justify-content:flex-start;
    margin:0 ${props => props.noMargin ? '0px' : '10px'} 0 0;
    svg{
        fill:var(${props => props.fill ? props.fill : '--icon-white-color'})
    }
`;

export default Icon
