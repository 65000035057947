import React, { useContext, useEffect, useRef, useState } from 'react'
import { FaChartLine, FaChartPie, FaDownload, FaPlusCircle, FaTimes } from 'react-icons/fa'
import { useHistory, useParams } from 'react-router'
import { createGetSchoolRequest, createGetSchoolUsersRequest, createUpdateSchoolRequest, createArchiveSchoolRequest, createCloseSchoolRequest, createDeleteSchoolRequest } from '../../api/api'
import AdminPage from '../common/AdminPage'
import Icon from '../common/Icon'
import { Loading, PageLoading } from '../common/Loading'
import PageBox from '../common/PageBox'
import styled from "styled-components";
import { UserContext } from '../providers/UserProvider'
import { Link } from 'react-router-dom'
import ModalDialog from '../common/ModalDialog'
import { MdArchive, MdDeleteForever, MdUnarchive } from 'react-icons/md'
import { USER_ROLE_ADMIN, USER_ROLE_SCHOOL_ADMIN, USER_ROLE_TEACHER, USER_ROLE_TENDER_ADMIN, USER_ROLE_TENDER_FACILITATOR } from '../../constants'

const SchoolProfile = () => {
    const { id } = useParams();
    const [school, setSchool] = useState();
    const [schoolUnchanged, setSchoolUnchanged] = useState();
    const [schoolAdmins, setSchoolAdmins] = useState([]);
    const [teachers, setTeachers] = useState([]);
    const [facilitators, setFacilitators] = useState([]);
    const [isLoadingSchool, setIsLoadingSchool] = useState(true);
    const [isLoadingUsers, setIsLoadingUsers] = useState(true);
    const [askArchive, setAskArchive] = useState(false);
    const [askRestore, setAskRestore] = useState(false);
    const [askDelete, setAskDelete] = useState(false);
    const [askCloseAccount, setAskCloseAccount] = useState(false);
    const [closeReason, setCloseReason] = useState("");
    const [pageBusy, setPageBusy] = useState(false);
    const [message, setMessage] = useState({ class: "", message: "" });
    const [error, setError] = useState(null);
    const schoolReq = useRef();
    const usersReq = useRef();
    const history = useHistory();
    const { role } = useContext(UserContext);

    const clearMessage = () => {
        setMessage({ class: "", message: "" });
    }

    const canUpdate = () => {
        return role === USER_ROLE_ADMIN || role === USER_ROLE_TENDER_ADMIN || role === USER_ROLE_SCHOOL_ADMIN;
    }

    const canUpdateSchoolType = () => {
        return role === USER_ROLE_ADMIN || role === USER_ROLE_TENDER_ADMIN;
    }

    const canArchive = () => {
        return role === USER_ROLE_ADMIN || role === USER_ROLE_TENDER_ADMIN;
    }

    const canDelete = () => {
        return role === USER_ROLE_ADMIN || role === USER_ROLE_TENDER_ADMIN;
    }

    const canCloseAccount = () => {
        return role === USER_ROLE_SCHOOL_ADMIN && !!school.close_reason === false && !school.archived;
    }

    const canInviteSchoolAdmin = () => {
        return (role === USER_ROLE_ADMIN || role === USER_ROLE_TENDER_ADMIN) && !school?.archived;
    }
    const canInviteFacilitator = () => {
        return (role === USER_ROLE_ADMIN || role === USER_ROLE_TENDER_ADMIN) && !school?.archived;
    }
    const canInviteTeacher = () => {
        return (role === USER_ROLE_ADMIN || role === USER_ROLE_TENDER_ADMIN || role === USER_ROLE_SCHOOL_ADMIN) && !school?.archived;
    }


    const canShowReports = () => {
        return role !== USER_ROLE_TENDER_FACILITATOR;
    }

    const canShowAnalytics = () => {
        return role !== USER_ROLE_TENDER_FACILITATOR;
    }

    const canShowSchoolCloseRequestStatus = () => {
        return role === USER_ROLE_SCHOOL_ADMIN && !!school.close_reason;
    }

    const canShowSchoolCloseRequestDetail = () => {
        return (role === USER_ROLE_ADMIN || role === USER_ROLE_TENDER_ADMIN) && !!school.close_reason;
    }

    const canShowSchoolsOnBreadcrumb = () => {
        return role === USER_ROLE_ADMIN || role === USER_ROLE_TENDER_ADMIN || role === USER_ROLE_SCHOOL_ADMIN;
    }

    useEffect(() => {
        let isMounted = true;
        async function getSchool() {
            setIsLoadingSchool(true);
            if (schoolReq.current) {
                schoolReq.current.cancel();
                schoolReq.current = null;
            }
            const { request, f } = createGetSchoolRequest();
            schoolReq.current = request;

            const r = await f(id);
            if (!isMounted) {
                return;
            }
            if (r instanceof Error) {
                setError(r);
                return;
            } else if (r && r.status) {
                setSchool(r.data);
                setSchoolUnchanged(r.data);
            }
            setIsLoadingSchool(false);
        }

        async function getUsers() {
            setIsLoadingUsers(true);
            if (usersReq.current) {
                usersReq.current.cancel();
                usersReq.current = null;
            }
            const { request, f } = createGetSchoolUsersRequest();
            usersReq.current = request;

            const r = await f(id);
            if (!isMounted) {
                return;
            }
            if (r && r.status) {
                if (r.data) {
                    setSchoolAdmins(r.data.filter(u => u.role === USER_ROLE_SCHOOL_ADMIN && !u.archived && u.active));
                    setTeachers(r.data.filter(u => u.role === USER_ROLE_TEACHER && !u.archived && u.active));
                    setFacilitators(r.data.filter(u => u.role === USER_ROLE_TENDER_FACILITATOR && !u.archived && u.active));
                }
            } else {
                return;
            }
            setIsLoadingUsers(false);
        }


        getSchool();
        getUsers();
        return () => {
            schoolReq?.current?.cancel();
            usersReq?.current?.cancel();
        }
    }, [id]);

    const updateSchoolData = (key, value) => {
        let s = { ...school, [key]: value };
        setSchool(s);
    }

    const cancel = () => {
        let s = { ...schoolUnchanged };
        setSchool(s);
        clearMessage();
    }

    const save = async () => {
        setIsLoadingSchool(true);
        clearMessage();
        const { f } = createUpdateSchoolRequest();
        const r = await f(school.id, school.name, school.address, school.city, school.postcode, school.phone, school.is_primary, false);
        setIsLoadingSchool(false);
        if (r) {
            if (r.status) {
                setMessage({ class: "alert-primary", message: "School updated" });
            } else {
                setMessage({ class: "alert-danger", message: r.messages.shift() });
            }
        } else {
            setMessage({ class: "alert-danger", message: "School not updated. Some error occured" });
        }
    }

    const archiveSchool = async () => {
        setAskArchive(false);
        setIsLoadingSchool(true);
        const { f } = createArchiveSchoolRequest();
        const r = await f(school.id, 1);
        setIsLoadingSchool(false);
        if (r && r.status) {
            history.push("/schools");
        } else {
            setMessage({ class: "alert-danger", message: "School not archived. Some error occured" });
        }
    }
    const cancelModals = () => {
        setAskArchive(false);
        setAskRestore(false);
        setAskDelete(false);
        setAskCloseAccount(false);
    }

    const restoreSchool = async () => {
        setAskRestore(false);
        setIsLoadingSchool(true);
        const { f } = createArchiveSchoolRequest();
        const r = await f(school.id, 0);
        setIsLoadingSchool(false);
        if (r && r.status) {
            history.push("/schools");
        } else {
            setMessage({ class: "alert-danger", message: "School not restored. Some error occured" });
        }
    }

    const deleteSchool = async () => {
        setAskDelete(false);
        setPageBusy(true);
        const { f } = createDeleteSchoolRequest();
        const r = await f(school.id);
        if (r && r.status) {
            history.push("/schools");
        } else {
            setPageBusy(false);
            setMessage({ class: "alert-danger", message: "School not deleted. Some error occured" });
        }
    }

    const closeAccount = async () => {
        setAskCloseAccount(false);
        setPageBusy(true);
        const { f } = createCloseSchoolRequest();
        const r = await f(school.id, closeReason);
        setPageBusy(false);
        if (r && r.status) {
            window.location.reload();
        } else {
            setMessage({ class: "alert-danger", message: "Some error occured" });
        }
    }


    return (
        <AdminPage error={error} breadcrumbs={[
            { title: "Home", path: "/" },
            { title: "Schools", path: canShowSchoolsOnBreadcrumb() ? "/schools" : null },
            { title: school?.name }
        ]}>
            <div className="d-flex align-items-md-center justify-content-start mb-2 flex-column flex-sm-row ">
                {canShowReports() && <Link to={`/school_report/${id}`} className="btn btn-primary btn-has-icon mr-2">
                    <Icon><FaChartLine /></Icon>School Report
                </Link>}
                {canShowAnalytics() && <Link to={`/school_analytics/${id}`} className="btn btn-outline-primary btn-has-icon mr-2">
                    <Icon fill="--icon-dull-color"><FaChartPie /></Icon>School Analytics
                </Link>}
            </div>
            <PageBox className="p-4">
                {isLoadingSchool && <div className="p-4"><Loading></Loading></div>}
                {!isLoadingSchool &&
                    <>
                        <h4><strong>{school.name}</strong></h4>

                        <div className="container-fluid px-0">
                            <div className="row">
                                <div className="col-12 col-lg-4 ">
                                    <div className="form-group">
                                        <label className="text-muted small">School Name</label>
                                        <input data-test-id='schoolnameinput' className="form-control" type="text" value={school.name} onChange={e => updateSchoolData("name", e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4">
                                    <div className="form-group">
                                        <label className="text-muted small">School Address</label>
                                        <input data-test-id='schooladdressinput' className="form-control" type="text" value={school.address} onChange={e => updateSchoolData("address", e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-2">
                                    <div className="form-group">
                                        <label className="text-muted small">City</label>
                                        <input data-test-id='schoolcityinput' className="form-control" type="text" value={school.city} onChange={e => updateSchoolData("city", e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-2 ">
                                    <div className="form-group">
                                        <label className="text-muted small">Post Code</label>
                                        <input data-test-id='schoolpostcodeinput' className="form-control" type="text" value={school.postcode} onChange={e => updateSchoolData("postcode", e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-4 ">
                                    <div className="form-group">
                                        <label className="text-muted small">Phone</label>
                                        <input data-test-id='schoolphoneinput' className="form-control" type="text" value={school.phone} onChange={e => updateSchoolData("phone", e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4">
                                    <div className="form-group">
                                        <label className="text-muted small">School Type</label>
                                        <RadioGroup>
                                            <div className="form-check form-check-inline">
                                                <input data-test-id='schooltypeprimary' disabled={!canUpdateSchoolType()} id="schoolTypePrimary" name="schoolType" type="radio" className="form-check-input" checked={school.is_primary} onChange={e => updateSchoolData("is_primary", true)} />
                                                <label htmlFor="schoolTypePrimary" className="form-check-label">Primary</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input data-test-id='schooltypesecondary' disabled={!canUpdateSchoolType()} id="schoolTypeSecondary" name="schoolType" type="radio" className="form-check-input" checked={!school.is_primary} onChange={e => updateSchoolData("is_primary", false)} />
                                                <label htmlFor="schoolTypeSecondary" className="form-check-label" >Secondary</label>
                                            </div>
                                        </RadioGroup>
                                    </div>
                                </div>
                                {/* <div className="col-12 col-lg-2">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label className="text-muted small">Testing</label>
                                            <RadioGroup>
                                                <div className="form-check form-check-inline">
                                                    <input id="testingYes" name="testing" type="radio" className="form-check-input" checked={school.is_testing} onChange={e => updateSchoolData("is_testing", true)} />
                                                    <label htmlFor="testingYes" className="form-check-label">Yes</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input id="testingNo" name="testing" type="radio" className="form-check-input" checked={!school.is_testing} onChange={e => updateSchoolData("is_testing", false)} />
                                                    <label htmlFor="testingNo" className="form-check-label">No</label>
                                                </div>
                                            </RadioGroup>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>


                        <div className="d-flex flex-wrap">
                            <div className="d-flex">
                                {canUpdate() && <button data-test-id='schoolsavebutton' onClick={() => save()} className="btn btn-apple btn-has-icon mr-2"><Icon><FaDownload /></Icon>Save</button>}
                                {canUpdate() && <button onClick={() => cancel()} className="btn btn-secondary btn-has-icon"><Icon><FaDownload /></Icon>Cancel</button>}
                                <div className="flex-grow-1"></div>
                            </div>
                            <div className="d-flex">
                                {canArchive() && !school.archived && <button data-test-id='schoolarchivebutton' onClick={() => setAskArchive(true)} className="btn btn-transparent btn-has-icon"><Icon noMargin fill="--icon-primary-color"><MdArchive /></Icon><small className="text-primary">Archive</small></button>}
                                {canArchive() && !!school.archived && <button data-test-id='schoolrestorebutton' onClick={() => setAskRestore(true)} className="btn btn-transparent btn-has-icon"><Icon noMargin fill="--icon-primary-color"><MdUnarchive /></Icon><small className="text-primary">Restore</small></button>}
                                {canDelete() && <button data-test-id='schooldeletebutton' onClick={() => setAskDelete(true)} className="btn btn-transparent btn-has-icon"><Icon noMargin fill="--icon-danger-color"><MdDeleteForever /></Icon><small className="text-danger">Delete Entry</small></button>}
                                {canCloseAccount() && <button onClick={() => setAskCloseAccount(true)} className="btn btn-transparent btn-has-icon"><Icon noMargin fill="--icon-danger-color"><MdDeleteForever /></Icon><small className="text-danger">Close Account</small></button>}
                            </div>
                        </div>

                        {message.message &&
                            <div className={"alert mt-3 " + message.class}>
                                {message.message}
                                <button onClick={clearMessage} className="btn close"><FaTimes /></button>
                            </div>
                        }

                        <div className="d-flex mt-3">
                            {!!school.archived && <div className="w-100 alert alert-danger text-center mb-0">School Archived</div>}
                            {!school.archived && canShowSchoolCloseRequestStatus() && <div className="w-100 alert alert-danger text-center mb-0">School Account Close Requested</div>}
                            {!school.archived && canShowSchoolCloseRequestDetail() && <div className="w-100 text-center alert alert-danger mb-0">
                                <Link to={`/user/${school.close_requested_by.id}`}>{school.close_requested_by?.fname} {school.close_requested_by?.lname}</Link> requested to close this account.<br />Reason: {school.close_reason}
                            </div>}
                        </div>
                    </>
                }
            </PageBox >
            <PageBox className="p-4 mt-4">
                <div className="position-relative">
                    <h5><strong>School Admins</strong></h5>
                    <div className="d-flex">
                        {isLoadingUsers && <div className="p-4"><Loading></Loading></div>}
                        {!isLoadingSchool && schoolAdmins.length === 0 &&
                            <div>No School Admins found.</div>
                        }
                        {!isLoadingSchool && schoolAdmins.length > 0 &&
                            schoolAdmins.map(u => <Link to={`/user/${u.id}`} key={u.id} className="d-flex align-items-center justify-content-center border border-primary rounded mr-2 p-2">{u.fname} {u.lname}</Link>)
                        }
                    </div>
                    {!isLoadingSchool && canInviteSchoolAdmin() && <Link to={`/user/invite?role=${USER_ROLE_SCHOOL_ADMIN}&school_id=${id}`} className="btn btn-apple btn-has-icon position-absolute t-0 r-0"><Icon><FaPlusCircle /></Icon>Invite</Link>}
                </div>
            </PageBox>

            <PageBox className="p-4 mt-4">
                <div className="position-relative">
                    <h5><strong>Teachers</strong></h5>
                    <div className="d-flex">
                        {isLoadingUsers && <div className="p-4"><Loading></Loading></div>}
                        {!isLoadingSchool && teachers.length === 0 &&
                            <div>No Teachers found.</div>
                        }
                        {!isLoadingSchool && teachers.length > 0 &&
                            teachers.map(u => <Link to={`/user/${u.id}`} key={u.id} className="d-flex align-items-center justify-content-center border border-primary rounded mr-2 p-2">{u.fname} {u.lname}</Link>)
                        }
                    </div>
                    {!isLoadingSchool && canInviteTeacher() && <Link to={`/user/invite?role=${USER_ROLE_TEACHER}&school_id=${id}`} className="btn btn-apple btn-has-icon position-absolute t-0 r-0"><Icon><FaPlusCircle /></Icon>Invite</Link>}
                </div>
            </PageBox>

            <PageBox className="p-4 mt-4">
                <div className="position-relative">
                    <h5><strong>Facilitators</strong></h5>
                    <div className="d-flex">
                        {isLoadingUsers && <div className="p-4"><Loading></Loading></div>}
                        {!isLoadingSchool && facilitators.length === 0 &&
                            <div>No Facilitators found.</div>
                        }
                        {!isLoadingSchool && facilitators.length > 0 &&
                            facilitators.map(u => <Link to={`/user/${u.id}`} key={u.id} className="d-flex align-items-center justify-content-center border border-primary rounded mr-2 p-2">{u.fname} {u.lname}</Link>)
                        }
                    </div>
                    {!isLoadingSchool && canInviteFacilitator() && <Link to={`/user/invite?role=${USER_ROLE_TENDER_FACILITATOR}&school_id=${id}`} className="btn btn-apple btn-has-icon position-absolute t-0 r-0"><Icon><FaPlusCircle /></Icon>Invite</Link>}
                </div>
            </PageBox>
            {askArchive && school && <ModalDialog
                title="Confirm Archive?"
                callbacks={[
                    { text: "No", className: "btn-secondary px-5", onClick: cancelModals },
                    { text: "Yes", testId: "schoolarchiveyes", className: "btn-danger px-5", onClick: archiveSchool },
                ]}
            >
                <div>This will archive the school and all linked workshops. <div className="alert alert-info my-2">{school.name}</div> Archive?</div>
            </ModalDialog>}

            {askRestore && school && <ModalDialog
                title="Confirm Restore?"
                callbacks={[
                    { text: "No", className: "btn-secondary px-5", onClick: cancelModals },
                    { text: "Yes", testId: "schoolrestoreyes", className: "btn-danger px-5", onClick: restoreSchool },
                ]}
            >
                <div>This will restore the school. <div className="alert alert-info my-2">{school.name}</div> Restore?</div>
            </ModalDialog>}

            {askDelete && school && <ModalDialog
                title="Confirm Delete?"
                callbacks={[
                    { text: "No", className: "btn-secondary px-5", onClick: cancelModals },
                    { text: "Yes", testId: "schooldeleteyes", className: "btn-danger px-5", onClick: deleteSchool },
                ]}
            >
                <div>This will delete the school and its linked workshops, students and analytics from the database and is irreversible. <div className="alert alert-info my-2">{school.name}</div> Delete?</div>
            </ModalDialog>}

            {askCloseAccount && school && <ModalDialog
                title={`Do you want to close ${school.name} Account?`}
                callbacks={[
                    { text: "Cancel", className: "btn-secondary px-5", onClick: cancelModals },
                    { text: "Close School Account", className: "btn-danger px-5", onClick: closeAccount },
                ]}
            >
                <div>
                    <div className="form-group">
                        <label htmlFor="reason" className="small text-muted">Tell us why you would like to close your account *</label>
                        <textarea id="reason" className="form-control" rows="6" value={closeReason} onChange={(e) => setCloseReason(e.currentTarget.value)}></textarea>
                    </div>
                </div>
            </ModalDialog>}
            {pageBusy && <PageLoading></PageLoading>}
        </AdminPage >
    )
}

const RadioGroup = styled.div.attrs((props) => ({ className: "d-flex" }))`
    height: calc(1.5em + 0.75rem + 2px);
`;

export default SchoolProfile
