import React, { useContext, useState } from 'react'
import { FaDownload, FaTimes } from 'react-icons/fa'
import AdminPage from '../common/AdminPage'
import Icon from '../common/Icon'
import PageBox from '../common/PageBox'
import styled from "styled-components"
import { UserContext } from '../providers/UserProvider'
import { createAddSchoolRequest } from '../../api/api'
import { Loading } from '../common/Loading'
import { useHistory } from 'react-router'



const AddSchool = () => {
    const [school, setSchool] = useState({
        name: "",
        address: "",
        city: "",
        postcode: "",
        phone: "",
        is_primary: false,
        is_testing: false
    });
    const [isLoadingSchool, setIsLoadingSchool] = useState(false);
    const [message, setMessage] = useState({ class: "", message: "" });
    const { permissions: loggedUserPermissions } = useContext(UserContext);
    const history = useHistory();

    const clearMessage = () => {
        setMessage({ class: "", message: "" });
    }

    const hasPermission = () => {
        return loggedUserPermissions["pm_schools_add"];
    }

    const updateSchoolData = (key, value) => {
        let s = { ...school, [key]: value };
        setSchool(s);
    }


    const save = async () => {
        setIsLoadingSchool(true);
        clearMessage();
        const { f } = createAddSchoolRequest();
        const r = await f(school.name, school.address, school.city, school.postcode, school.phone, school.is_primary, false);
        setIsLoadingSchool(false);
        if (r) {
            if (r.status) {
                setMessage({ class: "alert-primary", message: "School added" });
                history.push("/school/" + r.data.id);
            } else {
                setMessage({ class: "alert-danger", message: r.messages.shift() });
            }
        } else {
            setMessage({ class: "alert-danger", message: "School not added. Some error occured" });
        }
    }


    return (
        <AdminPage breadcrumbs={[
            { title: "Home", path: "/" },
            { title: "Add School" }
        ]}>
            <PageBox className="p-4">
                {isLoadingSchool && <div className="p-4"><Loading></Loading></div>}
                {!isLoadingSchool &&
                    <>
                        <h4><strong>Add a School</strong></h4>
                        <div className="container-fluid px-0">
                            <div className="row">
                                <div className="col-12 col-lg-4 ">
                                    <div className="form-group">
                                        <label className="text-muted small">School Name</label>
                                        <input data-test-id="schoolnameinput" className="form-control" type="text" value={school.name} onChange={e => updateSchoolData("name", e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4">
                                    <div className="form-group">
                                        <label className="text-muted small">School Address</label>
                                        <input data-test-id="schooladdressinput" className="form-control" type="text" value={school.address} onChange={e => updateSchoolData("address", e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-2">
                                    <div className="form-group">
                                        <label className="text-muted small">City</label>
                                        <input data-test-id="schoolcityinput" className="form-control" type="text" value={school.city} onChange={e => updateSchoolData("city", e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-2 ">
                                    <div className="form-group">
                                        <label className="text-muted small">Post Code</label>
                                        <input data-test-id="schoolpostcodeinput" className="form-control" type="text" value={school.postcode} onChange={e => updateSchoolData("postcode", e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-4 ">
                                    <div className="form-group">
                                        <label className="text-muted small">Phone</label>
                                        <input data-test-id="schoolphoneinput" className="form-control" type="text" value={school.phone} onChange={e => updateSchoolData("phone", e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4">
                                    <div className="form-group">
                                        <label className="text-muted small">School Type</label>
                                        <RadioGroup>
                                            <div className="form-check form-check-inline">
                                                <input data-test-id="schooltypeprimary" id="schoolTypePrimary" name="schoolType" type="radio" className="form-check-input" checked={school.is_primary} onChange={e => updateSchoolData("is_primary", true)} />
                                                <label htmlFor="schoolTypePrimary" className="form-check-label">Primary</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input data-test-id="schooltypesecondary" id="schoolTypeSecondary" name="schoolType" type="radio" className="form-check-input" checked={!school.is_primary} onChange={e => updateSchoolData("is_primary", false)} />
                                                <label htmlFor="schoolTypeSecondary" className="form-check-label" >Secondary</label>
                                            </div>
                                        </RadioGroup>
                                    </div>
                                </div>
                                {/* <div className="col-12 col-lg-2">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label className="text-muted small">Testing</label>
                                            <RadioGroup>
                                                <div className="form-check form-check-inline">
                                                    <input id="testingYes" name="testing" type="radio" className="form-check-input" checked={school.is_testing} onChange={e => updateSchoolData("is_testing", true)} />
                                                    <label htmlFor="testingYes" className="form-check-label">Yes</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input id="testingNo" name="testing" type="radio" className="form-check-input" checked={!school.is_testing} onChange={e => updateSchoolData("is_testing", false)} />
                                                    <label htmlFor="testingNo" className="form-check-label">No</label>
                                                </div>
                                            </RadioGroup>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>

                        {message.message &&
                            <div className={"alert " + message.class}>
                                {message.message}
                                <button onClick={clearMessage} className="btn close"><FaTimes /></button>
                            </div>
                        }

                        {hasPermission() && <div className="d-flex">
                            <button data-test-id="schoolsubmitbutton" onClick={() => save()} className="btn btn-apple btn-has-icon mr-2"><Icon><FaDownload /></Icon>Save</button>
                        </div>}

                    </>}
            </PageBox>
        </AdminPage>
    )
}

export default AddSchool

const RadioGroup = styled.div.attrs((props) => ({ className: "d-flex" }))`
    height: calc(1.5em + 0.75rem + 2px);
`;