import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { createGetTrainingItemsRequest } from '../../../api/api';
import { Loading } from '../../common/Loading';

const TrainingItemList = ({ count }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [error, setError] = useState(null);
    const req = useRef();

    useEffect(() => {
        let isMounted = true;
        async function loadItems() {
            setIsLoading(true);
            if (req.current) {
                req.current.cancel();
                req.current = null;
            }

            const { request, f } = createGetTrainingItemsRequest();
            req.current = request;

            const r = await f();
            if (!isMounted) {
                return;
            }
            if (r && r.status) {
                if (count) {
                    setItems(r.data.sort((a, b) => a.display_order - b.display_order).slice(0, count));
                } else {
                    setItems(r.data.sort((a, b) => a.display_order - b.display_order).slice(0, count));
                }
            } else {
                setError("Error Loading Training Content");
            }

            setIsLoading(false);
        }
        loadItems();
        return () => {
            isMounted = false;
            req?.current?.cancel();
        }
    }, [count])

    return (
        <>
            {error && <div className="d-flex justify-content-center p-4">
                <div className="alert alert-danger">{error}</div>
            </div>}
            {!error && isLoading && <div className="p-4"><Loading></Loading></div>}
            {!error && !isLoading &&
                <div className="d-flex justify-content-center justify-content-lg-start flex-wrap">
                    {items && items.map(item =>
                        <CLink key={item.name} to={`/training/${item.name}`} data-test-groupid="trainingItem">
                            <h6 className="title"><strong>{item.title}</strong></h6>
                            <img src={item.thumbnail} alt={item.title} className="mb-2 w-100 h-auto" />
                            <div className="small" dangerouslySetInnerHTML={{ __html: item.content }}></div>
                        </CLink>)}
                </div>
            }
        </>
    )
}

export default TrainingItemList

const CLink = styled(Link)`
    width:250px;
    display:flex;
    flex-direction:column;
    color:black;
    margin:1em;
    &:hover{
        text-decoration:none;
    }
    @media screen and (max-width:769px){
        width:350px;
    }
    @media screen and (max-width:577px){
        width:100%;
    }
`
