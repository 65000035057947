import React, { useContext } from 'react'
import { BiShieldQuarter } from 'react-icons/bi';
import { FaChartLine, FaChartPie } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { USER_ROLE_TENDER_FACILITATOR } from '../../../constants';
import Icon from '../../common/Icon';
import { Row } from '../../common/Row'
import { UserContext } from '../../providers/UserProvider'

const SchoolList = ({ schools, onClick }) => {

    const { role } = useContext(UserContext);

    const canSeeReports = () => role !== USER_ROLE_TENDER_FACILITATOR;
    const canSeeAnalytics = () => role !== USER_ROLE_TENDER_FACILITATOR;

    const phpTimestampToJSDate = (time)=>{
        const d = new Date(Number(time)*1000);
        return d.toLocaleDateString();
    }

    return (
        <>
            {schools && schools.map(s =>
                <Row className="p-0" key={s.id} onClick={() => { onClick(s.id) }} data-test-schoolitem>
                    <SchoolRow>
                        {s.close_requested && <CloseBadge className="badge badge-warning">Close Request</CloseBadge>}
                        <div className="row align-items-center">
                            <div className="col-12 col-md-12 col-lg-8 d-flex align-items-center mb-2 mb-lg-0">
                                <SchoolIcon className="school-icon"></SchoolIcon>
                                <div className="d-flex flex-column">
                                    <div className="d-flex align-items-center"><strong>{s.name}</strong>{s.archived === 1 && <span className="ml-2 badge badge-danger">Archived</span>}</div>
                                    <div className="small text-muted">{s.is_primary ? 'Primary' : 'Secondary'} - {s.city}</div>
                                    {!!s.last_login && <div className='badge badge-info'>Last Login: {phpTimestampToJSDate(s.last_login)}</div>}
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-4 d-flex justify-content-around">
                                {canSeeReports() && <Link onClick={(e) => e.stopPropagation()} to={`/school_report/${s.id}`} className="btn btn-primary btn-has-icon flex-grow-1 mr-1" data-test-schoolreportbutton><Icon><FaChartLine></FaChartLine></Icon>Report</Link>}
                                {canSeeAnalytics() && <Link onClick={(e) => e.stopPropagation()} to={`/school_analytics/${s.id}`} className="btn btn-outline-primary btn-has-icon flex-grow-1 ml-1" data-test-schoolanalyticsbutton><Icon fill="--icon-dull-color"><FaChartPie /></Icon>Analytics</Link>}
                            </div>
                        </div>
                    </SchoolRow>
                </Row>
            )}
            {(!schools || schools.length === 0) && <div className="alert-warning m-2 p-4 text-center border text-dark border-warning">No schools to show.</div>}
        </>
    )
}

export default SchoolList



const SchoolRow = styled.div.attrs((prop) => ({ className: "container-fluid m-0 p-4" }))`
    cursor:pointer;
    &:hover{
        background:#f6fbff;
        .school-icon{
            fill:var(--icon-primary-color);
        }
    }
`;

const SchoolIcon = styled(BiShieldQuarter)`
    fill:var(--icon-dull-color);
    width:50px;
    height:50px;
    margin-right:1rem;
`;

const CloseBadge = styled.span`
    margin-left:65px;
`;
