import React from 'react'
import styled from 'styled-components';

const getColor = (r1, r2) => {
    const colors = {
        "0-50": "#ff6164",
        "50-70": "#ffc458",
        "70-Infinity": "#8cd36d"
    };

    return colors[`${r1}-${r2}`];
}

const RangeBar = ({ ranges }) => {



    return (
        <>
            <Div className="d-flex align-items-stretch justify-content-start w-100">
                {ranges && ranges.map((r, i) => <div className="text-white text-right pr-2 text-shadow small" key={i} style={{ backgroundColor: getColor(r.range[0], r.range[1]), width: `${r.count * 100 / r.total}%` }}><strong>{`${r.range[0]}%-${Math.min(100, r.range[1])}%`}</strong></div>)}
            </Div>
            <div className="d-flex mt-0 mt-lg-2 align-items-baseline justify-content-start">
                {ranges.map((r, i) => <div key={i} className="d-flex mt-2 align-items-center justify-content-start">
                    <span className="small circle circle-small mr-1 ml-2 d-inline-block" style={{ backgroundColor: getColor(r.range[0], r.range[1]) }}>&nbsp;</span>
                    <span className="small">{Math.round(r.count * 100 / r.total)}% Achieved ({r.count}/{r.total})</span>
                </div>)}
                {ranges.length === 0 && <div className="d-flex mt-2 align-items-center justify-content-start">
                    <span className="small circle circle-small mr-1 ml-2 d-inline-block" style={{ backgroundColor: "#afafaf" }}>&nbsp;</span>
                    <span className="small">0% Achieved</span>
                </div>}
            </div>
        </>
    )
}

const Div = styled.div`
    height:1.5em;
    background:#afafaf;
    div{
        padding-top:2px;
    }
`;

export default RangeBar
