import React, { useEffect, useRef, useState } from 'react'
import { createGetChaptersRequest, createGetExportContent } from '../../api/api';
import AdminPage from '../common/AdminPage';
import { Loading } from '../common/Loading';
import PageBox from '../common/PageBox';

const ExportScript = () => {
    const [isLoadingChapters, setIsLoadingChapters] = useState(true);
    const [isLoadingContent, setIsLoadingContent] = useState(true);
    const [chapters, setChapters] = useState(null);
    const [chapterId, setChapterId] = useState(0);
    const [characters, setCharacters] = useState(null);
    const [characterId, setCharacterId] = useState(0);
    const [content, setContent] = useState(null);
    const [error, setError] = useState(null);
    const chapterReq = useRef();
    const contentReq = useRef();

    const getOptions = (q, label, lb) => {
        if (q[label]) {
            return q[label].map(function (m) { return "* " + (m.text || m.title || "--") }).join(lb === "br" ? "<br/><br/>" : "\n\n");
        } else {
            return "";
        }
    }

    const selectedChapter = () => {
        return chapters.find(c => parseInt(c.id) === parseInt(chapterId));
    }

    const selectedCharacter = () => {
        return characters.find(c => parseInt(c.id) === parseInt(characterId));
    }



    useEffect(() => {
        let isMounted = true;
        async function loadChapters() {
            setIsLoadingChapters(true);
            chapterReq?.current?.cancel();
            const { request, f } = createGetChaptersRequest();
            chapterReq.current = request;
            const r = await f();
            setIsLoadingChapters(false);
            if (!isMounted) {
                return;
            }
            if (r instanceof Error) {
                setError(r);
            } else if (r && r.status) {
                let chapters = r.data.sort((a, b) => a.display_order - b.display_order);
                setChapters(chapters);
                setChapterId(chapters[0].id);
            }
        }
        loadChapters();

        return () => {
            isMounted = false;
            chapterReq?.current?.cancel();
        }
    }, []);

    useEffect(() => {
        let isMounted = true;
        async function loadContent() {
            setIsLoadingContent(true);
            contentReq?.current?.cancel();
            const { request, f } = createGetExportContent();
            contentReq.current = request;
            const r = await f(chapterId);
            if (!isMounted) {
                return;
            }
            if (r instanceof Error) {
                setError(r);
            } else if (r && r.status) {
                let chs = r.data.dialogs.map(d => d.character).filter(c => c !== null);
                let d = [];
                chs.forEach(c => {
                    if (!d.some(q => q.id === c.id)) {
                        d.push(c);
                    }
                });
                if (d && d.length > 0) {
                    setCharacters(d);
                    setCharacterId(d[0].id);
                    setContent(r.data);
                }
                setIsLoadingContent(false);

            }
        }
        loadContent();

        return () => {
            isMounted = false;
            contentReq?.current?.cancel();
        }
    }, [chapterId]);

    const exportPanelTitles = () => {
        const chapter = selectedChapter();
        var csv = [
            ["Chapter Name", escapeWithQuotes(chapter?.title || ""), ""],
            ["Chapter Id", escapeWithQuotes(chapterId), ""],
            ["", "", ""],
            ["One To One Panel Title", "", ""],
            ["Title text", "CMS Id", "Filename"],
            [escapeWithQuotes(chapter?.one_to_one_panel_text), "", ""],
            ["", "", ""],
            ["Panel Titles", "", ""],
            ["Title text", "CMS Id", "Filename"]
        ];

        let ds = content.dialogs.filter(d => d.panel_title !== null && d.panel_title.trim() !== "");
        let panelTitlesCsv = ds.map(d => [escapeWithQuotes(d.panel_title), escapeWithQuotes(d.name), ""]);
        csv = csv.concat(panelTitlesCsv);
        exportCSV(`${chapter?.title}_panel_titles`, csv);
    }

    const exportPlayerOptions = () => {
        const chapter = selectedChapter();
        var csv = [
            ["Chapter Name", escapeWithQuotes(chapter?.title || ""), "", ""],
            ["Chapter Id", escapeWithQuotes(chapterId), "", ""],
            ["", "", "", ""],
            ["Player Options", "", "", ""],
            ["", "", "", ""],
            ["Text", "Text Alt", "Filename", "Filename ALT"]
        ];

        content.playerOptions.forEach(d => {
            for (var i = 0; i < d.answers.length; i++) {
                csv.push([escapeWithQuotes(d.answers[i].text), escapeWithQuotes(d.answers[i].text_alt), escapeWithQuotes(d.name) + "_" + (i + 1), escapeWithQuotes(d.name) + "_" + (i + 1) + "_ALT"]);
            }
        });

        exportCSV(`${chapter?.title}_player_options`, csv);
    }

    const exportHelpItems = () => {
        var csv = [
            ["Menu Title", "Content", "Content Alt", "Filename"],
            ["", "", "", ""]
        ];

        for (let i = 0; i < content.helps[0].items.length; i++) {
            const hi = content.helps[0].items[i];
            csv.push([
                hi.menuTitle,
                escapeWithQuotes(hi.content),
                escapeWithQuotes(hi.content_alt)
            ]);
        }
        exportCSV("help", csv);
    }
    const exportGlossary = () => {
        const chapter = selectedChapter();
        var csv = [
            ["Chapter Name", escapeWithQuotes(chapter?.title || ""), "", ""],
            ["Chapter Id", escapeWithQuotes(chapterId), "", ""],
            ["", "", "", ""],
            ["Menu Title", "Content", "Content Alt", "Filename"],
            ["", "", "", ""]
        ];

        for (let i = 0; i < content.glossary[0].items.length; i++) {
            const hi = content.glossary[0].items[i];
            csv.push([
                hi.menuTitle,
                escapeWithQuotes(hi.content),
                escapeWithQuotes(hi.content_alt)
            ]);
        }
        exportCSV(`${chapter?.title}_glossary`, csv);
    }
    const exportTournaments = () => {
        const chapter = selectedChapter();
        var csv = [
            ["Chapter Name", escapeWithQuotes(chapter?.title || ""), ""],
            ["Chapter Id", escapeWithQuotes(chapterId), ""],
            ["", "", ""],
            ["Name", "Intro Dialog", "Filename"],
            ["", "", ""]
        ];

        for (let i = 0; i < content.tournaments.length; i++) {
            const t = content.tournaments[i];
            csv.push([
                t.name,
                escapeWithQuotes(t.intro_dialog)
            ]);
        }
        exportCSV(`${chapter?.title}_tournaments`, csv);
    }
    const exportTournamentQuestions = () => {
        const chapter = selectedChapter();
        var csv = [
            ["Chapter Name", escapeWithQuotes(chapter?.title || ""), ""],
            ["Chapter Id", escapeWithQuotes(chapterId), ""],
            ["", "", ""],
            ["--", "--", "Filename"]
        ];
        for (let i = 0; i < content.allQuestions.length; i++) {
            csv.push(["", "", ""]);
            const t = content.allQuestions[i];
            csv.push([
                "CMS Id", escapeWithQuotes(t.name), ""
            ]);
            csv.push([
                "Question", escapeWithQuotes(t.question[0].question_text.replace("$BLANK", "---")), ""
            ]);
            if (t.question[0].options) {
                var options = t.question[0].options.map(o => o.text || "");
                for (let i = 0; i < options.length; i++) {
                    const o = options[i];
                    if (o !== "") {
                        csv.push([
                            i === 0 ? "Options" : "", escapeWithQuotes(o), ""
                        ]);
                    }
                }
            }
            if (t.question[0].bank_a) {
                var bank_a = t.question[0].bank_a.map(o => o.text || "");
                for (let i = 0; i < bank_a.length; i++) {
                    const o = bank_a[i];
                    if (o !== "") {
                        csv.push([
                            i === 0 ? "Bank A" : "", escapeWithQuotes(o), ""
                        ]);
                    }
                }
            }
            if (t.question[0].bank_b) {
                var bank_b = t.question[0].bank_b.map(o => o.text || "");
                for (let i = 0; i < bank_b.length; i++) {
                    const o = bank_b[i];
                    if (o !== "") {
                        csv.push([
                            i === 0 ? "Bank B" : "", escapeWithQuotes(o), ""
                        ]);
                    }
                }
            }
            if (t.question[0].slots) {
                var slots = t.question[0].slots.map(o => o.title || "");
                for (let i = 0; i < slots.length; i++) {
                    const o = slots[i];
                    if (o !== "") {
                        csv.push([
                            i === 0 ? "Slots" : "", escapeWithQuotes(o), ""
                        ]);
                    }
                }
            }
        }
        exportCSV(`${chapter?.title}_tournament_questions`, csv);
    }
    const exportDialogs = () => {
        const chapter = selectedChapter();
        var csv = [
            ["Chapter Name", escapeWithQuotes(chapter?.title || ""), "", "", ""],
            ["Chapter Id", escapeWithQuotes(chapterId), "", "", ""],
            ["", "", "", "", ""],
            ["Character Name", escapeWithQuotes(selectedCharacter()?.name), "", "", ""],
            ["", "", "", "", ""],
            ["Expression", "Dialog", "Dialog Alt", "CMS Id", "Filename"]
        ];
        let dialogs = content.dialogs.filter(d => d.character !== null && parseInt(d.character.id) === parseInt(selectedCharacter().id) && (d.dialog || d.dialog_alt));

        let dialogsCsv = dialogs.map(d => [escapeWithQuotes(d.character_expression.name), escapeWithQuotes(d.dialog), escapeWithQuotes(d.dialog_alt), escapeWithQuotes(d.name), ""]);
        csv = csv.concat(dialogsCsv);
        exportCSV(`${chapter.title}_${selectedCharacter().name}_dialogs`, csv);
    }

    const escapeWithQuotes = (string) => {
        if (string === undefined || string === null || string.length === 0) {
            return "";
        }
        string = string.toString().replace(/"/g, '""');
        return "\"" + string + "\"";
    }

    const exportCSV = (title, csv) => {
        let csvContent = "data:text/csv;charset=utf-8,";
        csv.forEach(function (rowArray) {
            let row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        let encodedUri = encodeURI(csvContent);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `${title}.csv`);
        document.body.appendChild(link); // Required for FF
        link.click();
        link.remove();
    }

    return (
        <AdminPage error={error} breadcrumbs={[
            { title: "Home", path: "/" },
            { title: "Export Script" }
        ]}>
            <PageBox className="p-3">
                <h4><strong>Export Script</strong></h4>
                {isLoadingChapters && <div className="d-flex p-3 align-items-center justify-content-center"><Loading></Loading></div>}
                {!isLoadingChapters && chapters && <div className="form-group">
                    <label>Chapter</label>
                    <select value={chapterId} onChange={(e) => setChapterId(e.currentTarget.value)} className="custom-select form-control">
                        {chapters && chapters.map(c =>
                            <option key={c.id} value={c.id}>{c.title}</option>
                        )}
                    </select>
                </div>}
                {!isLoadingChapters && isLoadingContent && <div className="d-flex align-items-center justify-content-center p-3"><Loading></Loading></div>}
                {!isLoadingContent && characters && <div className="form-group">
                    <label>Character</label>
                    <select value={characterId} onChange={(e) => setCharacterId(e.currentTarget.value)} className="custom-select form-control">
                        {characters && characters.map(c =>
                            <option key={c.id} value={c.id}>{c.name}</option>
                        )}
                    </select>
                </div>
                }
            </PageBox>
            {!isLoadingContent && content &&
                <>
                    <PageBox className="p-3 mt-3">
                        <div className="d-flex align-items-center justify-content-between">
                            <h5>Panel Titles</h5>
                            <button onClick={exportPanelTitles} className="btn btn-outline-primary">Export</button>
                        </div>
                        <div className="small"><strong>One To One Panel Text:</strong> {selectedChapter()?.one_to_one_panel_text}</div>
                        <div className="container-fluid">
                            <div className="row border-bottom">
                                <div className="col-6 small text-muted">Title Text</div>
                                <div className="col-6 small text-muted">CMS Id</div>
                            </div>
                            {content.dialogs.filter(d => d.panel_title && d.panel_title.trim().length).slice(0, 5).map(d => <div key={d.name} className="row border-bottom">
                                <div className="col-6 small">{d.panel_title}</div>
                                <div className="col-6 small">{d.name}</div>
                            </div>)}
                        </div>
                    </PageBox>
                    {selectedCharacter() && <PageBox className="p-3 mt-3">
                        <div className="d-flex align-items-center justify-content-between">
                            <h5>Dialogs {(selectedCharacter()?.name)}</h5>
                            <button onClick={exportDialogs} className="btn btn-outline-primary">Export</button>
                        </div>
                        <div className="container-fluid">
                            <div className="row border-bottom">
                                <div className="col-3 small text-muted">Expression</div>
                                <div className="col-3 small text-muted">Dialog</div>
                                <div className="col-3 small text-muted">Dialog Alt</div>
                                <div className="col-3 small text-muted">CMS Id</div>
                            </div>
                            {content.dialogs.filter(d => d?.character?.id === selectedCharacter()?.id).slice(0, 5).map(d => <div key={d.name} className="row border-bottom">
                                <div className="col-3 small">{d?.character_expression?.name}</div>
                                <div className="col-3 small">{d.dialog}</div>
                                <div className="col-3 small">{d.dialog_alt}</div>
                                <div className="col-3 small">{d.name}</div>
                            </div>)}
                        </div>
                    </PageBox>
                    }

                    <PageBox className="p-3 mt-3">
                        <div className="d-flex align-items-center justify-content-between">
                            <h5>Player Options</h5>
                            <button onClick={exportPlayerOptions} className="btn btn-outline-primary">Export</button>
                        </div>
                        <div className="container-fluid">
                            <div className="row border-bottom">
                                <div className="col-4 small text-muted">Text</div>
                                <div className="col-4 small text-muted">Text Alt</div>
                                <div className="col-4 small text-muted">CMS Id</div>
                            </div>
                            {content.playerOptions.slice(0, 3).filter(p => p != null).map(po => po.answers.slice(0, 3).filter(o => o != null).map(a => <div key={a.text} className="row border-bottom">
                                <div className="col-4 small">{a.text}</div>
                                <div className="col-4 small">{a.text_alt}</div>
                                <div className="col-4 small">{po.name}</div>
                            </div>))}
                        </div>
                    </PageBox>

                    <PageBox className="p-3 mt-3">
                        <div className="d-flex align-items-center justify-content-between">
                            <h5>Help Items</h5>
                            <button onClick={exportHelpItems} className="btn btn-outline-primary">Export</button>
                        </div>
                        <div className="container-fluid">
                            <div className="row border-bottom">
                                <div className="col-4 small text-muted">Menu Title</div>
                                <div className="col-4 small text-muted">Text</div>
                                <div className="col-4 small text-muted">Text Alt</div>
                            </div>
                            {content.helps.slice(0, 3).filter(h => h != null).map(po => po.items.slice(0, 3).filter(o => o != null).map(a => <div key={a.menuTitle} className="row border-bottom">
                                <div className="col-4 small">{a.menuTitle}</div>
                                <div className="col-4 small">{a.content.substring(0, 25)}...</div>
                                <div className="col-4 small">{a.content_alt?.substring(0, 25)}...</div>
                            </div>))}
                        </div>
                    </PageBox>

                    <PageBox className="p-3 mt-3">
                        <div className="d-flex align-items-center justify-content-between">
                            <h5>Glossary</h5>
                            <button onClick={exportGlossary} className="btn btn-outline-primary">Export</button>
                        </div>
                        <div className="container-fluid">
                            <div className="row border-bottom">
                                <div className="col-4 small text-muted">Menu Title</div>
                                <div className="col-4 small text-muted">Text</div>
                                <div className="col-4 small text-muted">Text Alt</div>
                            </div>
                            {content.glossary.slice(0, 3).filter(h => h != null).map(po => po.items.slice(0, 3).filter(o => o != null).map(a => <div key={a.menuTitle} className="row border-bottom">
                                <div className="col-4 small">{a.menuTitle}</div>
                                <div className="col-4 small">{a.content.substring(0, 25)}...</div>
                                <div className="col-4 small">{a.content_alt?.substring(0, 25)}...</div>
                            </div>))}
                        </div>
                    </PageBox>

                    <PageBox className="p-3 mt-3">
                        <div className="d-flex align-items-center justify-content-between">
                            <h5>Tournaments</h5>
                            <button onClick={exportTournaments} className="btn btn-outline-primary">Export</button>
                        </div>
                        <div className="container-fluid">
                            <div className="row border-bottom">
                                <div className="col-6 small text-muted">Intro Dialog</div>
                                <div className="col-6 small text-muted">CMS Id</div>
                            </div>
                            {content.tournaments.filter(d => d.intro_dialog && d.intro_dialog.trim().length).slice(0, 5).map(d => <div key={d.name} className="row border-bottom">
                                <div className="col-6 small">{d.intro_dialog?.substring(0, 25)}...</div>
                                <div className="col-6 small">{d.name}</div>
                            </div>)}
                        </div>
                    </PageBox>

                    <PageBox className="p-3 mt-3">
                        <div className="d-flex align-items-center justify-content-between">
                            <h5>Tournament Questions</h5>
                            <button onClick={exportTournamentQuestions} className="btn btn-outline-primary">Export</button>
                        </div>
                        <div className="container-fluid">
                            <div className="row border-bottom">
                                <div className="col-4 small text-muted">Question</div>
                                <div className="col-2 small text-muted">Options</div>
                                <div className="col-2 small text-muted">Bank A</div>
                                <div className="col-2 small text-muted">Bank B</div>
                                <div className="col-2 small text-muted">Slots</div>
                            </div>
                            {content.allQuestions.slice(0, 5).map(d => <div key={d.name} className="row border-bottom">
                                <div className="col-4 small">{d.question[0].question_text.replace("$BLANK", "---")?.substring(0, 35)}...</div>
                                <div className="col-2 small" dangerouslySetInnerHTML={{ __html: getOptions(d.question[0], 'options', "br") }}></div>
                                <div className="col-2 small" dangerouslySetInnerHTML={{ __html: getOptions(d.question[0], 'bank_a', "br") }}></div>
                                <div className="col-2 small" dangerouslySetInnerHTML={{ __html: getOptions(d.question[0], 'bank_b', "br") }}></div>
                                <div className="col-2 small" dangerouslySetInnerHTML={{ __html: getOptions(d.question[0], 'slots', "br") }}></div>
                            </div>)}
                        </div>
                    </PageBox>
                </>
            }
        </AdminPage>
    )
}

export default ExportScript
