import React, { useContext } from 'react'
import { Nav } from "react-bootstrap";
import styled from "styled-components";
import { FaTimes, FaUser, FaGamepad, FaChalkboardTeacher, FaSignOutAlt, FaHome, FaUserCircle } from "react-icons/fa";
import { MdSchool } from "react-icons/md";
import { Link } from "react-router-dom";
import Icon from "./Icon";
import background from "../../assets/images/bg.jpg";
import logo from "../../assets/images/logo.png";
import tenderLogo from "../../assets/images/frame.png";
import { UserContext, UserConsumer } from '../providers/UserProvider';
import { ServerParamsContext } from '../providers/ServerParamsProvider';
import { USER_ROLE_ADMIN, USER_ROLE_SCHOOL_ADMIN, USER_ROLE_TEACHER, USER_ROLE_TENDER_ADMIN } from '../../constants';


const Menu = ({ className, onClose, onLogout }) => {
    const { role } = useContext(UserContext);
    const { version } = useContext(ServerParamsContext);

    const canShowSchools = () => {
        return role === USER_ROLE_ADMIN || role === USER_ROLE_TENDER_ADMIN;
    }

    const canShowMySchool = () => {
        return role === USER_ROLE_SCHOOL_ADMIN || role === USER_ROLE_TEACHER;
    }

    const canShowUsers = () => {
        return role === USER_ROLE_ADMIN || role === USER_ROLE_TENDER_ADMIN || role === USER_ROLE_SCHOOL_ADMIN;
    }

    const canShowExportScript = () => {
        return role === USER_ROLE_ADMIN || role === USER_ROLE_TENDER_ADMIN;
    }

    const canShowGlobalSettings = () => {
        return role === USER_ROLE_ADMIN;
    }

    return (
        <CNav className={className}>
            <CloseButton onClick={onClose}><FaTimes></FaTimes></CloseButton>
            <Link to="/"><Logo /></Link>
            <CLink to="/" onClick={onClose}><Icon fill='--icon-dull-color'><FaHome /></Icon>Home</CLink>
            <CLink to="/training" onClick={onClose}><Icon fill='--icon-dull-color'><FaChalkboardTeacher /></Icon>Training Resources</CLink>
            <CLink to="/workshops" onClick={onClose}><Icon fill='--icon-dull-color'><FaGamepad /></Icon>Workshops</CLink>
            {canShowSchools() && <CLink to="/schools" onClick={onClose}><Icon fill='--icon-dull-color'><MdSchool /></Icon>Schools</CLink>}
            {canShowMySchool() && <CLink to="/my_school" onClick={onClose}><Icon fill='--icon-dull-color'><MdSchool /></Icon>My School</CLink>}
            {canShowUsers() && <CLink to="/users" onClick={onClose}><Icon fill='--icon-dull-color'><FaUser /></Icon>Users</CLink>}
            <hr className="hr-border-light" />
            {canShowExportScript() && <CLink to="/export_scripts" onClick={onClose} className="d-none d-md-block"><Icon fill='--icon-dull-color'><FaGamepad /></Icon>Export Dialogue Scripts</CLink>}
            {canShowGlobalSettings() && <CLink to="/global_settings" onClick={onClose}><Icon fill='--icon-dull-color'><FaGamepad /></Icon>Global Settings</CLink>}
            <UserConsumer>{user =>
                <CLink to={`/user/${user.id}`} onClick={onClose}><Icon fill='--icon-dull-color'><FaUserCircle /></Icon>My Profile</CLink>
            }</UserConsumer>
            <CLink2 onClick={onLogout}><Icon fill='--icon-dull-color'><FaSignOutAlt /></Icon>Logout</CLink2>
            <div className="text-center text-muted small">{version}</div>
            <TenderLogo></TenderLogo>
        </CNav >
    )
}

const CNav = styled(Nav)`
    flex-direction: column;
    background:#0b389c url('${background}');
    background-position:center;
    background-size:cover;
`;

const CLink = styled(Link)`
    color:white;
    font-weight:500;
    display:flex;
    align-items:center;
    justify-content:flex-start;
    padding:0.5rem 1rem;
  
    &:hover{
        text-decoration:none;
        color:white;
        background:rgba(255,255,255,0.1);
    }
`;

const CLink2 = styled.a`
    color:white;
    font-weight:500;
    display:flex;
    align-items:center;
    justify-content:flex-start;
    padding:0.5rem 1rem;
    cursor:pointer;
  
    &:hover{
        text-decoration:none;
        color:white;
        background:rgba(255,255,255,0.1);
    }
`;

const CloseButton = styled.button.attrs(props => ({ className: "close" }))`
    width:50px;
    height:50px;
    align-self:flex-end;
    color:white;

    @media screen and (min-width:769px){
        display:none;
    }
`;

const Logo = styled.div`
    background:url('${logo}');
    background-position:center;
    background-size:contain;
    background-repeat:no-repeat;
    height:70px;
    margin:0 0.5rem;
    @media screen and (max-width:769px){
        display:none;
    }
`;

const TenderLogo = styled.div`
    background:url('${tenderLogo}');
    background-position:center;
    background-size:contain;
    background-repeat:no-repeat;
    height:70px;
    margin:50px 0.5rem;

`;

export default Menu
