import React, { useEffect, useRef, useState } from 'react'
import { createGetAllSchoolsReport } from '../../api/api';
import { MAX_SCORE_CHAPTERS } from '../../constants';
import AdminPage from '../common/AdminPage'
import LearningOutcomeChapterRow from '../common/LearningOutcomeChapterRow';
import { Loading } from '../common/Loading'
import PageBox from '../common/PageBox';
import RangeBar from '../common/RangeBar';
import MultiSelectDropdown from "../common/MultiSelectDropdown";

const SEND_FILTERS = [
    {
        label: "Yes",
        value: "yes",
        selected: true
    },
    {
        label: "No",
        value: "no",
        selected: true
    },
    {
        label: "Mixed",
        value: "mixed",
        selected: true
    },
];

const YEAR_GROUPS = [
    { label: "Post 16", value: 16, selected: true },
    { label: "11", value: 11, selected: true },
    { label: "10", value: 10, selected: true },
    { label: "9", value: 9, selected: true },
    { label: "8", value: 8, selected: true },
    { label: "7", value: 7, selected: true },
    { label: "6", value: 6, selected: true },
    { label: "5", value: 5, selected: true },
    { label: "4", value: 4, selected: true },
];

const AllSchoolsReport = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [scoreData, setScoreData] = useState(null);
    const [firstSession, setFirstSession] = useState(true);
    const [sends, setSends] = useState([...SEND_FILTERS]);
    const [hasMultipleSessions, setHasMultipleSessions] = useState(false);

    const [yearGroups, setYearGroups] = useState([...YEAR_GROUPS]);
    const dataReq = useRef();

    useEffect(() => {
        let isMounted = true;
        async function loadData() {
            setIsLoading(true);
            dataReq?.current?.cancel();
            const { request, f } = createGetAllSchoolsReport();
            dataReq.current = request;
            if (!isMounted) {
                return;
            }
            const r = await f(sends.filter(s => s.selected).map(s => s.value), yearGroups.filter(y => y.selected).map(y => y.value));
            if (r instanceof Error) {
                setError(r);
            } else if (r && r.status) {
                setData(r.data);
            }
            setIsLoading(false);
        }
        loadData();
        return () => {
            isMounted = false;
            dataReq?.current?.cancel();
        }
    }, [sends, yearGroups])

    useEffect(() => {
        const getScores = (attempts, chapterId) => {
            let cha = attempts.filter(a => parseInt(chapterId) === parseInt(a.chapter_id));
            const studentIds = [...new Set(cha.map(c => c.student_id))];
            let scores = [];
            studentIds.forEach(sid => {
                let sorted;
                if (firstSession) {
                    sorted = cha.filter(c => parseInt(c.student_id) === parseInt(sid)).sort((a, b) => a.updated_at - b.updated_at);
                } else {
                    sorted = cha.filter(c => parseInt(c.student_id) === parseInt(sid)).sort((a, b) => b.updated_at - a.updated_at);
                }
                scores.push(sorted[0].healthy > 0 ? sorted[0].healthy : 0);
                if (sorted.length > 1) {
                    setHasMultipleSessions(h => h || true);
                }
            });
            return scores;
        }

        const scoresToRanges = (scores, ranges, maxScore) => {
            let totalStudents = parseInt(data.playing_students);
            let counts = ranges.map(r => {
                const min = maxScore * r[0] / 100;
                const max = maxScore * r[1] / 100;
                const count = scores.filter(s => s >= min && s < max).length;
                return { range: r, total: totalStudents, count };
            });
            return counts.filter(c => c.count !== 0);
        }

        const getLearningOutcomes = (attempts, chapterId) => {
            let cha = attempts.filter(a => parseInt(chapterId) === parseInt(a.chapter_id));
            const studentIds = [...new Set(cha.map(c => c.student_id))];
            let lo = [];
            studentIds.forEach(sid => {
                let sorted;
                if (firstSession) {
                    sorted = cha.filter(c => parseInt(c.student_id) === parseInt(sid)).sort((a, b) => a.updated_at - b.updated_at);
                } else {
                    sorted = cha.filter(c => parseInt(c.student_id) === parseInt(sid)).sort((a, b) => b.updated_at - a.updated_at);
                }
                lo.push(sorted[0].learning_outcomes);
            });
            lo = lo.filter(l => l.length);
            lo = [].concat.apply([], lo);
            let clo = data.tournament_data.find(t => parseInt(t.chapter_id) === parseInt(chapterId))?.learning_outcomes;
            let max = data.playing_per_chapter.find(p => parseInt(p.chapter_id) === parseInt(chapterId))?.student_count;
            clo = clo?.map(c => ({ max, count: lo.filter(l => l === c).length, title: data.learning_outcomes_all[c] }));
            return clo;
        }

        if (data) {
            let d = data.workshop_chapter_ids.map(k => {
                let scores = getScores(data.attempts, k);
                let lo = getLearningOutcomes(data.attempts, k);
                let total = data.playing_per_chapter.find(p => parseInt(p.chapter_id) === parseInt(k))?.student_count;
                let ranges = scoresToRanges(scores, [[0, 50], [50, 70], [70, Infinity]], MAX_SCORE_CHAPTERS.find(c => parseInt(c.chapterId) === parseInt(k)).score, total);
                return { chapterId: k, title: data.cms_chapters[k].title, display_order: data.cms_chapters[k].display_order, scores, ranges, learningOutcomes: lo };
            });
            setScoreData(d.sort((a, b) => a.display_order - b.display_order));
        }
    }, [data, firstSession]);

    return (
        <AdminPage error={error} breadcrumbs={[
            { title: "Home", path: "/" },
            { title: "Schools", path: "/schools" },
            { title: "Global Schools Report" }
        ]}>
            <PageBox className="p-3">
                {isLoading && <div className="d-flex align-items-center justify-content-center p-4"><Loading></Loading></div>}
                {!isLoading && (data !== null) && <>
                    <div className="d-flex flex-column flex-lg-row align-items-stretch justify-content-between">
                        <h4 className="d-flex m-0 align-items-center"><strong>All Schools Report</strong></h4>
                        <div className="d-flex flex-column flex-lg-row align-items-start">
                            <div className="d-flex align-items-center">
                                <label className="m-0 mr-1 text-left text-lg-right" style={{ minWidth: "50px" }}>SEND:</label>
                                <MultiSelectDropdown className="form-control w-auto flex-grow-0 mr-2 my-2 my-lg-0" submitButton={true} options={sends} onChange={setSends}></MultiSelectDropdown>
                            </div>
                            <div className="d-flex align-items-center">
                                <label className="m-0 mr-1 text-left text-lg-right" style={{ minWidth: "50px" }}>Year:</label>
                                <MultiSelectDropdown className="form-control w-auto flex-grow-0" submitButton={true} options={yearGroups} onChange={setYearGroups}></MultiSelectDropdown>
                            </div>
                        </div>
                    </div>
                    <div><small><strong><span className="text-muted">Workshops: </span><span>{data.total_workshops}</span></strong></small></div>
                    <div><small><strong><span className="text-muted">Playing Students: </span><span>{data.playing_students}</span></strong></small></div>
                    <div><small><strong><span className="text-muted">Total Students: </span><span>{data.total_students}</span></strong></small></div>
                    <div><small><strong><span className="text-muted">Students Logged Today: </span><span>{data.students_logged_today}</span></strong></small></div>
                    <div className="row mt-5">
                        <div className="col-12 col-md-6 d-flex align-items-center">
                        </div>
                        {hasMultipleSessions && <div className="col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-end">
                            <button disabled={firstSession} onClick={() => setFirstSession(true)} className={`btn mr-1 ${firstSession ? "btn-secondary" : "btn-primary"}`}>1st Session</button>
                            <button disabled={!firstSession} onClick={() => setFirstSession(false)} className={`btn ${!firstSession ? "btn-secondary" : "btn-primary"}`}>Latest Session</button>
                        </div>}
                    </div>
                    {scoreData && scoreData.map((r, i) =>
                        <div key={i} className={`py-3 row border-bottom border-row-light small flex-column flex-lg-row ${i % 0 ? "background-row-light" : "background-row-lighter"}`}>
                            <div className="col-12 col-lg-2"><strong>{r.title}</strong></div>
                            <div className="col-12 col-lg-10">
                                <RangeBar ranges={r.ranges}></RangeBar>
                            </div>
                        </div>)
                    }

                    <h6 className="my-4">Group Learning Outcomes</h6>
                    <div className="row d-none d-lg-flex">
                        <div className="col-6 text-center"><small className="text-muted">Learning Outcomes</small></div>
                        <div className="col-6 text-center"><small className="text-muted">Student Attainment</small></div>
                    </div>
                    {scoreData && scoreData.map((r, i) =>
                        <LearningOutcomeChapterRow
                            key={i}
                            chapterTitle={r.title}
                            learningOutcomes={r.learningOutcomes}
                        ></LearningOutcomeChapterRow>
                    )}
                </>}
            </PageBox>
        </AdminPage >
    )
}

export default AllSchoolsReport


