import React, { useEffect, useRef, useState } from 'react'
import { FaChevronDown } from 'react-icons/fa';
import styled from 'styled-components';
import Icon from './Icon';

const MultiSelectDropdown = ({ className, options, onChange, submitButton }) => {
    //eslint-disable-next-line
    const [defaultData, _] = useState(JSON.parse(JSON.stringify(options)));
    const [data, setData] = useState([...options]);
    const [expanded, setExpanded] = useState(false);
    const clickElement = useRef();

    const selectItem = (index, selected) => {
        const d = [...data];
        d[index].selected = selected;
        setData(d);
        if (!submitButton) {
            onChange(d);
        }
    }

    const selectAll = () => {
        const d = [...data];
        for (let i = 0; i < d.length; i++) {
            d[i].selected = true;
        }
        setData(d);
        if (!submitButton) {
            onChange(d);
        }
    }

    const deselectAll = () => {
        const d = [...data];
        for (let i = 0; i < d.length; i++) {
            d[i].selected = false;
        }
        setData(d);
        if (!submitButton) {
            onChange(d);
        }
    }

    const isAllSelected = () => {
        return data.every(d => d.selected);
    }

    const selectedText = () => {
        let selected = data.filter(d => d.selected);
        if (selected.length === 0) {
            return "Nothing Selected";
        }
        let s = selected.map(x => x.label).join(", ");
        return s.length > 20 ? (s.substr(0, 20) + "...") : s;
    }

    useEffect(() => {
        setData([...options]);
    }, [options])

    useEffect(() => {
        function handleClick(e) {
            const t = e.target.closest(".dropdown") === clickElement.current.closest(".dropdown");
            setExpanded(t);
            if (!t && submitButton) {
                setData(JSON.parse(JSON.stringify(defaultData)));
            }
        }
        window.addEventListener("mouseup", handleClick);
        return () => {
            window.removeEventListener("mouseup", handleClick);
        }
    }, [defaultData, submitButton]);

    return (
        <CDiv className={`${className} p-0 pl-1 dropdown`} style={{ position: "relative" }}>
            <div ref={clickElement} className="d-flex align-items-center cursor-pointer">
                <div className="flex-grow-1">{selectedText()}</div>
                <button className="flex-grow-0 shadow-none btn btn-transparent"><Icon noMargin fill="--icon-dull-color"><FaChevronDown></FaChevronDown></Icon></button>
            </div>
            {expanded && <Popup className="px-2 py-2 d-flex flex-column">
                <div className="form-check-inline d-flex align-items-center">
                    <input id={`check_all`} type="checkbox" className="form-check-input" checked={isAllSelected()} onChange={isAllSelected() ? deselectAll : selectAll} />
                    <label className="mb-0 flex-grow-1" htmlFor={`check_all`}>Select All</label>
                </div>
                {data.map((o, i) => <div key={i} className="form-check-inline d-flex align-items-center">
                    <input id={`check_${i}`} type="checkbox" className="form-check-input" checked={o.selected} onChange={(e) => selectItem(i, e.target.checked)} />
                    <label className="mb-0 flex-grow-1" htmlFor={`check_${i}`}>{o.label}</label>
                </div>)}
                {submitButton && <button onClick={() => onChange(data)} className="btn btn-light btn-sm">Apply</button>}
            </Popup>}
        </CDiv>
    )
}

export default MultiSelectDropdown

const CDiv = styled.div`
    min-width:200px;
`;

const Popup = styled.div`
    position:absolute;
    top:2.3em;
    left:-1px;
    right:-1px;
    z-index:10;
    background:white;
    border:1px solid #ccc;
`;