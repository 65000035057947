import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components';
import { createActivateUserRequest } from '../../api/api';
import { Loading } from '../common/Loading';
import { Image } from "react-bootstrap";
import { TiDocumentText } from "react-icons/ti";
import { BsShieldLock } from "react-icons/bs";
import bg from "../../assets/images/bg.jpg";
import logo from "../../assets/images/logo.png";
import tenderLogo from "../../assets/images/frame.png";

import Icon from '../common/Icon';
import { FaEnvelope } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { UserContext } from '../providers/UserProvider';
import PasswordStrength from '../common/PasswordStrength';

const UserActivate = () => {
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [success, setSuccess] = useState(false);
    const userData = useContext(UserContext);

    useEffect(() => {
        userData.remove();
        //eslint-disable-next-line
    }, []);

    const send = async () => {
        if (password.trim() === "") {
            setErrorMessage("Password must not be empty.");
            return;
        }
        setErrorMessage(null);
        setIsLoading(true);
        const { f } = createActivateUserRequest();
        const location = window.location.href.replace("#", "/");
        const code = new URL(location).searchParams.get("k");
        const r = await f(code, password);
        setIsLoading(false);
        if (r instanceof Error) {
            setError(r);
            return;
        }
        if (r) {
            if (r.status) {
                setSuccess(true);
            } else {
                setErrorMessage(r.messages?.shift());
            }
        }
    }

    return (
        <Background>
            <div className="d-flex align-items-center justify-content-center vh-100">

                <CContainer>
                    <div className="row justify-content-center">
                        <Logo><Image src={logo} width="100%"></Image></Logo>
                        <div className="p-4 d-flex align-items-center justify-content-center w-100">
                            <div className="card p-4 w-100">
                                {!isLoading && !error && !success && <>
                                    <p>Please type in a new password.</p>
                                    <div className="form-group">
                                        <label name="password" className="small text-muted"><strong>Password</strong></label>
                                        <input type="text" name="password" className="form-control" onChange={e => setPassword(e.target.value)} />
                                        {password.length > 0 && <PasswordStrength password={password} className="d-block mt-0 p-1"></PasswordStrength>}
                                    </div>

                                    <small className="mb-2">Hint: The password should be at least twelve characters long. To make it stronger, use upper and lower case letters, numbers and symbols like ! “ ? £ % ^ & )</small>
                                    <div className="form-group d-flex align-items-center justify-content-end">
                                        <button className="btn btn-has-icon btn-apple" type="submit" onClick={send}><Icon><FaEnvelope /></Icon>Submit</button>
                                    </div>
                                </>}
                                {!isLoading && !error && !errorMessage && success && <>
                                    <div className="p-4 alert-primary text-center">Activation Success. Please continue to login with your email and password.</div>
                                    <div className="mt-4 form-group d-flex align-items-center justify-content-center">
                                        <Link to="/login" className="btn btn-primary">Login</Link>
                                    </div>
                                </>}
                                {!isLoading && error && <>
                                    <div className="alert-danger text-center p-4">Some Error occured.</div>
                                </>}
                                {errorMessage && <>
                                    <div className="alert-danger text-center p-4">{errorMessage}</div>
                                </>}
                                {isLoading && <>
                                    <Loading></Loading>
                                </>}
                            </div>
                        </div>
                    </div>
                </CContainer>

                <Footer className="d-flex flex-column align-items-center justify-content-end mb-5">
                    <TenderLogo></TenderLogo>
                    <div className="d-flex align-items-center justify-content-center">
                        <a className="small text-white btn-has-icon" rel="noreferrer" target="_blank" href="https://relationshipgoals.uk/terms-and-conditions"><Icon className="mr-1" noMargin fill="--icon-white-color"><TiDocumentText></TiDocumentText></Icon>Terms & Conditions</a>
                        <a className="small text-white mx-5 btn-has-icon" rel="noreferrer" target="_blank" href="https://relationshipgoals.uk/privacy"><Icon className="mr-1" noMargin fill="--icon-white-color"><BsShieldLock></BsShieldLock></Icon>Privacy Policy</a>
                        <a className="small text-white btn-has-icon" rel="noreferrer" target="_blank" href="https://relationshipgoals.uk/contact"><Icon className="mr-1" noMargin fill="--icon-white-color"><FaEnvelope></FaEnvelope></Icon>Contact Us</a>
                    </div>
                </Footer>
            </div>
        </Background>
    )
}


export default UserActivate

const Background = styled.div`
    background:url('${bg}');
    background-size:cover;
    width:100vw;
    height:100vh;
`;

const CContainer = styled.div.attrs(props => ({ className: "container" }))`
    @media screen and (min-width:769px){
        max-width:600px;
    }
`;

const Logo = styled.div`
    margin-bottom:15px;
`;

const Footer = styled.div`
    position:absolute;
    bottom:0;
    left:50%;
    transform:translate(-50%,0);
`;

const TenderLogo = styled.div`
    background:url('${tenderLogo}');
    background-position:center;
    background-size:contain;
    background-repeat:no-repeat;
    height:70px;
    width:150px;
    margin:0 0 20px 0;
`;


