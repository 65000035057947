import React from 'react'
import styled from 'styled-components'

const Thumbnail = ({ src, title, width, height }) => {
    return (
        <CDiv>
            <img src={src} alt={title} width={width} height={height} />
            <span className="small">{title.length > 15 ? (title.substr(0, 15) + "...") : title}</span>
        </CDiv>
    )
}

export default Thumbnail

const CDiv = styled.div`
    position:relative;
    img{
        object-fit:cover;
    }

    span{
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 0 0.5em;
        background: rgba(0,5,255,0.6);
        color: white;
        overflow: hidden;
        height:1.5em;
    }
`;
