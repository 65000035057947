import React from 'react'
import { FaTimes } from 'react-icons/fa'
import styled from "styled-components"

const Badge = ({ children, onClose, hideCloseButton, className, ...rest }) => {
    return (
        <CSpan className={className} {...rest}>
            <span className="mr-2">{children}</span>
            {!hideCloseButton && <CloseIcon onClick={onClose}><FaTimes></FaTimes></CloseIcon>}
        </CSpan >
    )
}

export default Badge


const CSpan = styled.div.attrs((props) => ({ className: "badge d-flex flex-inline align-items-center justify-content-center rounded mb-1 mr-1" }))`
    padding:0 10px;
    height:25px;
`;
const CloseIcon = styled.div`
    height:15px;
    width:15px;
    margin:0;
    cursor:pointer;
    text-align:center;
    svg{
        height:100%;
        margin:0 auto;
    }
`