import { createContext, React, useEffect, useState } from "react";
import { getVersion } from "../../api/api";

export const ServerParamsContext = createContext();

export const ServerParamsProvider = ({ children }) => {
    const [data, setData] = useState({
        version: null
    });

    useEffect(() => {
        async function loadVersion() {
            const r = await getVersion();
            if (r) {
                setData({ version: r });
            }
        }
        loadVersion();
    }, [])

    return (
        <ServerParamsContext.Provider value={data}>{children}</ServerParamsContext.Provider>
    )
}

export const ServerParamsConsumer = ServerParamsContext.Consumer;