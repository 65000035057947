import React, { useContext, useEffect, useRef, useState } from 'react'
import AdminPage from '../common/AdminPage'
import Icon from '../common/Icon'
import PageBox from '../common/PageBox'
import { FaPlusCircle, FaSearch } from "react-icons/fa";
import { createGetIsAllSchoolsArchived, createGetUsersRequest } from '../../api/api';
import { useHistory } from 'react-router';
import { Loading } from '../common/Loading';
import { Link } from 'react-router-dom';
import { UserContext } from '../providers/UserProvider';
import { Row } from '../common/Row';
import Pagination from '../common/Pagination';
import UserList from '../sections/user/UserList';
import { USER_ROLE_ADMIN, USER_ROLE_SCHOOL_ADMIN, USER_ROLE_TEACHER, USER_ROLE_TENDER_ADMIN, USER_ROLE_TENDER_FACILITATOR } from '../../constants';

const Users = () => {

    const [search, setSearch] = useState("");
    const [page, setPage] = useState(0);
    const [sort, setSort] = useState(1);
    const [error, setError] = useState(null);
    const [allSchoolsArchived, setAllSchoolsArchived] = useState(true);
    const [roleFilter, setRoleFilter] = useState([USER_ROLE_ADMIN, USER_ROLE_TENDER_ADMIN, USER_ROLE_TENDER_FACILITATOR, USER_ROLE_SCHOOL_ADMIN, USER_ROLE_TEACHER]);
    const [isLoading, setIsLoading] = useState(false);
    const userReq = useRef();
    const [users, setUsers] = useState([]);
    const [tenderStaff, setTenderStaff] = useState([]);
    const [schools, setSchools] = useState([]);
    const [userSchoolIds, setUserSchoolIds] = useState([]);
    const [totalCount, setTotalCount] = useState();
    const [countPerPage, setCountPerPage] = useState();
    const history = useHistory();
    const { role, permissions } = useContext(UserContext);

    const canAdd = () => {
        return (permissions["pm_users_add_tender_admin"] ||
            permissions["pm_users_add_school_admin"] ||
            permissions["pm_users_add_facilitator"] ||
            permissions["pm_users_add_teacher"]) && !allSchoolsArchived;
    }

    const canShowUserDetail = () => {
        return role !== USER_ROLE_SCHOOL_ADMIN;
    }

    const canShowTenderStaff = () => {
        return role === USER_ROLE_SCHOOL_ADMIN;
    }

    const getRoleFilters = () => {

        return roleFilter;

    }

    const canShowArchivedSortOption = () => {
        return role !== USER_ROLE_SCHOOL_ADMIN;
    }

    useEffect(() => {
        let isMounted = true;
        async function loadUsers() {
            setIsLoading(true);
            userReq?.current?.cancel();
            const { request: c, f } = createGetUsersRequest();
            userReq.current = c;
            const rf = roleFilter;
            const r = await f(search, page, sort, rf, false);
            if (!isMounted) {
                return;
            }
            if (r instanceof Error) {
                setError(r);
            } else if (r && r.status) {
                setUsers(r.data.users?.map(u => {
                    const usid = r.data.school_ids?.find(s => s.user_id === u.id);
                    const s = r.data.schools?.find(s => s.id === usid?.school_id);
                    return { ...u, school_archived: s?.archived };
                }));
                setTenderStaff(r.data.tender_staff);
                setSchools(r.data.schools);
                setUserSchoolIds(r.data.school_ids);
                setTotalCount(r.data.total);
                setCountPerPage(r.data.count_per_page);
            }
            setIsLoading(false);
        }

        loadUsers();
        return () => {
            isMounted = false;
            userReq?.current?.cancel();
        }
    }, [search, page, sort, roleFilter, role]);

    useEffect(() => {
        async function getIsAllSchoolsArchived() {
            const { f } = createGetIsAllSchoolsArchived();
            const r = await f();
            if (r instanceof Error) {

            } else if (r && r.status) {
                setAllSchoolsArchived(r.data.all_archived);
            }
        }
        getIsAllSchoolsArchived();
    }, []);

    useEffect(() => {
        setPage(0);
    }, [search, sort])

    const editUser = (userId) => {
        history.push(`/user/${userId}`);
    }

    const roleFilterCheckboxClick = (role, status) => {
        let r = roleFilter.filter(q => q !== role);
        if (status) {
            r.push(role);
        }
        setRoleFilter(r);
    }

    const userSchools = () => {
        const r = userSchoolIds?.map(u => ({ id: u.user_id, schools: schools?.filter(s => parseInt(s.id) === parseInt(u.school_id)) }));
        return r;
    }


    return (
        <AdminPage error={error} breadcrumbs={[
            { title: "Home", path: "/" },
            { title: "Users" }
        ]}>
            <div className="d-flex align-items-md-center justify-content-start mb-2 flex-column flex-sm-row ">
                {canAdd() && <Link to="/user/invite" className="btn btn-apple btn-has-icon mr-sm-2">
                    <Icon><FaPlusCircle /></Icon>Invite User
                </Link>
                }
            </div>
            {canShowTenderStaff() && tenderStaff.length > 0 &&
                <PageBox className="mb-2">
                    <Row className="p-4">
                        <h4><strong>Tender Staff</strong></h4>
                    </Row>
                    {isLoading && <div className="p-3"><Loading></Loading></div>}
                    {!isLoading && <UserList users={tenderStaff} onClick={editUser}></UserList>}
                </PageBox>
            }
            <div className="form-group mb-2">
                <div className="input-group">
                    <div className="input-group-prepend">
                        <div className="input-group-text pr-1"><Icon fill="--icon-dull-color"><FaSearch /></Icon></div>
                    </div>
                    <input type="text" className="form-control" id="inlineFormInputGroup" placeholder="Find User" value={search} onChange={(e) => { setSearch(e.target.value) }} onKeyUp={(e) => { setSearch(e.target.value) }} />
                </div>
            </div>
            <PageBox>
                <Row className="p-4">
                    <h4><strong>{role === USER_ROLE_SCHOOL_ADMIN ? "My School Staff" : "Users"}</strong></h4>
                    <select name="sort" className="small custom-select right" value={sort} onChange={e => setSort(e.target.value)}>
                        <option value="0">Oldest First</option>
                        <option value="1">Most Recent</option>
                        <option value="2">Alphabetical A-Z</option>
                        <option value="3">Alphabetical Z-A</option>
                        {canShowArchivedSortOption() && <><option value="4">Archived</option>
                            <option value="5">Unarchived</option></>}
                        <option value="6">Active</option>
                        <option value="7">Inactive</option>
                    </select>
                </Row>
                <Row className="p-4 pb-5">
                    <div className="d-flex flex-column flex-xl-row small">
                        <span className="mr-3 mb-2 mb-xl-0">Show Roles:</span>
                        <div className="form-check-inline flex-column flex-xl-row align-items-start">
                            <div className="d-flex align-items-center">
                                {(role === USER_ROLE_ADMIN) && <>
                                    <input id="a" type="checkbox" className="form-check-input" checked={getRoleFilters().indexOf(USER_ROLE_ADMIN) >= 0} onChange={e => roleFilterCheckboxClick(USER_ROLE_ADMIN, e.target.checked)} />
                                    <label htmlFor="a" className="form-check-label mr-4">Super Admin</label>
                                </>}
                            </div>
                            {!canShowTenderStaff() && <>
                                <div className="d-flex align-items-center">
                                    <input id="ta" type="checkbox" className="form-check-input" checked={getRoleFilters().indexOf(USER_ROLE_TENDER_ADMIN) >= 0} onChange={e => roleFilterCheckboxClick(USER_ROLE_TENDER_ADMIN, e.target.checked)} />
                                    <label htmlFor="ta" className="form-check-label mr-4">Tender Admin</label>
                                </div>
                                <div className="d-flex align-items-center">
                                    <input id="tf" type="checkbox" className="form-check-input" checked={getRoleFilters().indexOf(USER_ROLE_TENDER_FACILITATOR) >= 0} onChange={e => roleFilterCheckboxClick(USER_ROLE_TENDER_FACILITATOR, e.target.checked)} />
                                    <label htmlFor="tf" className="form-check-label mr-4">Tender Facilitator</label>
                                </div>
                            </>}
                            <div className="d-flex align-items-center">
                                <input id="sa" type="checkbox" className="form-check-input" checked={getRoleFilters().indexOf(USER_ROLE_SCHOOL_ADMIN) >= 0} onChange={e => roleFilterCheckboxClick(USER_ROLE_SCHOOL_ADMIN, e.target.checked)} />
                                <label htmlFor="sa" className="form-check-label mr-4">School Admin</label>
                            </div>
                            <div className="d-flex align-items-center">
                                <input id="te" type="checkbox" className="form-check-input" checked={getRoleFilters().indexOf(USER_ROLE_TEACHER) >= 0} onChange={e => roleFilterCheckboxClick(USER_ROLE_TEACHER, e.target.checked)} />
                                <label htmlFor="te" className="form-check-label mr-4">Teacher</label>
                            </div>
                        </div>
                    </div>
                    <div className="footer text-muted small"><strong>Name</strong></div>
                </Row>
                {isLoading && <div className="p-3"><Loading></Loading></div>}
                {!isLoading && <UserList users={users} userSchools={canShowUserDetail() ? userSchools() : null} onClick={editUser}></UserList>}
                <Row className="p-4">
                    <div className="d-flex align-items-center justify-content-end">
                        <Pagination totalCount={totalCount} page={page} countPerPage={countPerPage} setPage={setPage}></Pagination>
                    </div>
                </Row>
            </PageBox>
        </AdminPage >
    )
}

export default Users
