import React, { useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import Icon from './Icon';
import SimpleProgressBar from './SimpleProgressBar'

const LearningOutcomeChapterRow = ({
    chapterTitle,
    learningOutcomes
}) => {

    const [expanded, setExpanded] = useState(false);

    const chapterProgress = () => {
        let sumCounts = learningOutcomes?.reduce((a, b) => a + b.count, 0);
        let sumTotal = learningOutcomes?.reduce((a, b) => a + b.max, 0);
        return Math.floor(sumCounts * 100 / sumTotal) || 0;
    }

    const getPercent = (value, max) => {
        return Math.round(value * 100 / max);
    }

    return (
        <>
            {learningOutcomes && <div onClick={() => setExpanded(!expanded)} className="small row d-flex flex-column flex-lg-row align-items-center border-top background-row-hover-light justify-content-around background-row-light py-3">
                <div className="col-12 col-lg-7 d-flex flex-row align-items-center mb-2 mb-lg-0">
                    <Icon fill="--icon-primary-color"> {expanded ? <FaChevronUp></FaChevronUp> : <FaChevronDown></FaChevronDown>}</Icon>
                    <strong className="ml-1">{chapterTitle}</strong>
                </div>
                <div className="col-12 col-lg-5 d-flex flex-column">
                    <div className="d-flex align-items-center justify-content-around">
                        <div><strong>{chapterProgress()}% Attained(Average)</strong></div>
                        <div><strong>{100 - chapterProgress()}% Not Attained(Average)</strong></div>
                    </div>
                    <SimpleProgressBar bgColor="#ff6164" fgColor="#8cd36d" percent={chapterProgress()}></SimpleProgressBar>
                </div>
            </div>}

            {!learningOutcomes && <div className="small row d-flex align-items-center justify-content-around  background-row-light py-3">
                <div className="col-1 d-flex align-items-center justify-content-end mr-0">
                    <Icon fill="--icon-dull-color" noMargin> <FaChevronDown></FaChevronDown></Icon>
                </div>
                <div className="col-6 text-muted"><strong>{chapterTitle}</strong></div>
                <div className="col-5 d-flex flex-column">
                    <div className="d-flex align-items-center justify-content-around">
                        <div className="text-muted"><strong>{chapterProgress()}% Attained(Average)</strong></div>
                        <div className="text-muted"><strong>{100 - chapterProgress()}% Not Attained(Average)</strong></div>
                    </div>
                    <SimpleProgressBar bgColor="#afafaf" fgColor="#afafaf" percent={chapterProgress()}></SimpleProgressBar>
                </div>
            </div>}

            {expanded && learningOutcomes && learningOutcomes.map(l => <div key={l.title} className="small row d-flex align-items-center justify-content-around background-row-lighter py-3">
                <div className="col-1 d-flex align-items-center justify-content-end d-none d-lg-flex"></div>
                <div className="col-12 col-lg-6">{l.title}</div>
                <div className="col-12 col-lg-5 d-flex flex-column">
                    <div className="d-flex align-items-center justify-content-around">
                        <div>{getPercent(l.count, l.max)}% Attained({l.count}/{l.max})</div>
                        <div>{100 - getPercent(l.count, l.max)}% Not Attained({(l.max - l.count)}/{l.max})</div>
                    </div>
                    <SimpleProgressBar height="7" bgColor="#ff6164" fgColor="#8cd36d" percent={getPercent(l.count, l.max)}></SimpleProgressBar>
                </div>
            </div>)}
        </>
    )
}

export default LearningOutcomeChapterRow
